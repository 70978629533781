import { useMutation } from "@tanstack/react-query";

import { BriefBuilderApi } from "#src/apis/briefBuilder/api";
import { GenerateBriefRequest, GenerateBriefResponse } from "#src/types/briefBuilder";
import { AppMutationOptions } from "#src/types/reactQuery";

export default function useGenerateBriefMutation(
  options?: AppMutationOptions<GenerateBriefRequest, GenerateBriefResponse>
) {
  return useMutation({
    mutationFn: (params) => BriefBuilderApi.generateBrief(params),
    ...options,
  });
}
