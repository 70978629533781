import { useCallback, useState } from "react";

import { NodeViewWrapperProps } from "@tiptap/react";

import useToast from "#shared/hooks/useToast";
import useGenerateBriefImageMutation from "#src/apis/briefBuilder/hooks/useGenerateBriefImageMutation";
import { useTiptapEditorProvider } from "#src/components/common/Editor/context/TiptapEditorProvider";
import { createImageUrlFromBase64 } from "#src/components/common/Editor/extensions/MagicImage/utils";
import { EditorAIImageStyle } from "#src/components/common/Editor/types";
import { uploadEditorImage } from "#src/components/common/Editor/utils/imageUpload";
import { useBriefBuilder } from "#src/containers/BriefBuilder/BriefBuilderProvider";

export interface DataProps {
  text: string;
  imageStyle?: EditorAIImageStyle;
}

export default function useMagicImage({
  editor,
  node,
  getPos,
  deleteNode,
}: NodeViewWrapperProps) {
  const toast = useToast();
  const { briefRef } = useBriefBuilder();
  const { isAiLoading, setIsAiLoading } = useTiptapEditorProvider();
  const [isUploading, setIsUploading] = useState(false);

  const [data, setData] = useState<DataProps>({
    text: "",
    imageStyle: undefined,
  });

  const [previewImage, setPreviewImage] = useState<string | undefined>(undefined);

  const { mutate: generateBriefImage } = useGenerateBriefImageMutation({
    onMutate: () => {
      setIsAiLoading(true);
    },
    onSuccess: async (data) => {
      try {
        const previewUrl = await createImageUrlFromBase64(data.base64);

        if (!previewUrl) {
          throw new Error("Failed to create image from base64");
        }

        setPreviewImage(previewUrl);
      } catch (error) {
        toast.error();
      }
    },
    onSettled: () => {
      setIsAiLoading(false);
    },
  });

  const generateImage = useCallback(() => {
    const { text: dataText, imageStyle } = data;

    if (!data.text) {
      toast.error({ message: "Please enter a description for the image" });
      return;
    }

    const prompt = `${dataText}\n\nImage style: ${imageStyle}`;

    generateBriefImage({
      prompt,
      briefRef,
    });
  }, [data, briefRef]);

  const handleConfirm = useCallback(async () => {
    if (!previewImage?.length) {
      return;
    }

    setIsUploading(true);

    try {
      const { id, url } = await uploadEditorImage(previewImage, briefRef);

      editor
        .chain()
        .setImageBlock({
          src: url,
          briefBuilderMediaId: id,
        })
        .focus()
        .run();
    } catch (error) {
      console.error(error);
    } finally {
      setIsUploading(false);
      setIsAiLoading(false);
    }
  }, [editor, previewImage, getPos, node.nodeSize]);

  const handleDiscard = useCallback(() => {
    deleteNode();
  }, [deleteNode]);

  const handlePromptChange = useCallback((text: string) => {
    setData((prevData) => ({ ...prevData, text }));
  }, []);

  const handleSelectImageStyle = useCallback(
    (imageStyle: EditorAIImageStyle | undefined) => {
      return () => {
        setData((prevData) => ({ ...prevData, imageStyle }));
      };
    },
    []
  );

  return {
    data,
    isGenerating: isAiLoading,
    isUploading,
    previewImage,
    generateImage,
    handleConfirm,
    handleDiscard,
    handlePromptChange,
    handleSelectImageStyle,
  };
}
