import React from "react";

import { Navigate, useLocation, useParams } from "react-router";

import {
  getBriefBuilderUrl,
  getCurrentStepFromPathname,
  isBriefBuilderStepAvailable,
} from "#src/containers/BriefBuilder/Header/Steps/helper";
import { useFetchBuilderBriefQuery } from "#src/features/briefBuilder/briefBuilderAPI";
import { BriefBuilderStep } from "#src/types/briefBuilder";
import useIsLoggedIn from "#src/utils/useIsLoggedIn";

export default function BriefBuilderGuard() {
  const isLoggedIn = useIsLoggedIn();
  const { pathname } = useLocation();
  const { briefRef } = useParams();

  const activeStep = getCurrentStepFromPathname(pathname.replace(briefRef, ":briefRef"));

  const { currentData, isLoading } = useFetchBuilderBriefQuery(
    { briefRef },
    {
      skip: !briefRef,
    }
  );

  // Redirect to the home page if the user is not logged in
  if (!isLoggedIn) {
    return <Navigate to="/" replace />;
  }

  // Redirect to the 404 page if there is an error
  if (!isLoading && !currentData) {
    return <Navigate to="/404" replace />;
  }

  // Redirect to the first step if the step is not available
  if (
    currentData &&
    !isBriefBuilderStepAvailable(
      activeStep,
      currentData.steps,
      currentData.currentBuildStep
    )
  ) {
    return (
      <Navigate
        to={getBriefBuilderUrl({ briefRef, step: BriefBuilderStep.Brief })}
        replace
      />
    );
  }

  return null;
}
