import { QueryClient, useQuery } from "@tanstack/react-query";

import { InterestsApi } from "#src/apis/interests/api";
import { InterestsQueryKeys } from "#src/apis/interests/queryKeys";
import { GetInterestsResponse } from "#src/types/interests";
import { AppQueryOptions } from "#src/types/reactQuery";

interface Props {
  options?: AppQueryOptions<GetInterestsResponse>;
}

export const useGetInterests = ({ options }: Props = {}) => {
  return useQuery({
    queryKey: InterestsQueryKeys.list,
    queryFn: () => InterestsApi.getInterests(),
    ...options,
  });
};

export const prefetchInterests = (serverQueryClient: QueryClient) => {
  return serverQueryClient.prefetchQuery({
    queryKey: InterestsQueryKeys.list,
    queryFn: () => InterestsApi.getInterests(),
  });
};
