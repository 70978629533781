import { client } from "#src/apis/axios";
import {
  SearchUnsplashPhotosRequest,
  SearchUnsplashPhotosResponse,
  UnsplashPhoto,
} from "#src/types/unsplash";

export namespace UnsplashApi {
  const BASE_PREFIX = "/community/unsplash";

  export const getPhoto = async (id: string) => {
    const { data } = await client.get<UnsplashPhoto>(`${BASE_PREFIX}/${id}`);

    return data;
  };

  export const searchPhotos = async (params: SearchUnsplashPhotosRequest) => {
    const { data } = await client.get<SearchUnsplashPhotosResponse>(
      `${BASE_PREFIX}/search`,
      { params }
    );

    return data;
  };
}
