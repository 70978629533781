import * as React from "react";
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";

const IconBlockQuote = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    className="shared-icon"
    ref={ref}
    {...props}
  >
    <path
      fill="currentColor"
      d="M21 13.313c0 2.424-2.158 4.387-4.821 4.387h-.643c-.535 0-.965-.391-.965-.877 0-.487.43-.878.965-.878h.643c1.599 0 2.892-1.177 2.892-2.633v-.365a3 3 0 0 1-.642.073h-2.572c-1.418 0-2.571-1.05-2.571-2.34V8.34c0-1.29 1.153-2.34 2.571-2.34h2.572C19.847 6 21 7.05 21 8.34v4.973m-1.929-2.633V8.34c0-.322-.289-.585-.642-.585h-2.572c-.353 0-.643.263-.643.585v2.34c0 .322.29.585.643.585h2.572c.353 0 .642-.263.642-.585m-13.5.585h2.572c.353 0 .643-.263.643-.585V8.34c0-.322-.29-.585-.643-.585H5.57c-.353 0-.642.263-.642.585v2.34c0 .322.289.585.642.585m5.143-1.755v3.803c0 2.424-2.157 4.387-4.821 4.387H5.25c-.534 0-.964-.391-.964-.877 0-.487.43-.878.964-.878h.643c1.599 0 2.893-1.177 2.893-2.633v-.365a3 3 0 0 1-.643.073H5.57C4.153 13.02 3 11.97 3 10.68V8.34C3 7.05 4.153 6 5.571 6h2.572c1.418 0 2.571 1.05 2.571 2.34z"
    />
  </svg>
);
const ForwardRef = forwardRef(IconBlockQuote);
export { ForwardRef as IconBlockQuote };
