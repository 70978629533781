import * as React from "react";
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";

const IconPuzzleSolid = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    className="shared-icon"
    ref={ref}
    {...props}
  >
    <path
      fill="currentColor"
      d="M9.5 6.094c0-.36-.227-.676-.516-.89-.453-.34-.734-.81-.734-1.329C8.25 2.84 9.371 2 10.75 2s2.5.84 2.5 1.875c0 .52-.281.988-.734 1.328-.29.215-.516.531-.516.89 0 .5.406.907.906.907h2.219C16.16 7 17 7.84 17 8.875v2.219c0 .5.406.906.906.906.36 0 .676-.227.89-.516.34-.453.81-.734 1.329-.734 1.035 0 1.875 1.121 1.875 2.5s-.84 2.5-1.875 2.5c-.52 0-.988-.281-1.328-.734-.215-.29-.531-.516-.89-.516-.5 0-.907.406-.907.906v4.719C17 21.16 16.16 22 15.125 22h-2.219a.907.907 0 0 1-.906-.906c0-.36.227-.676.516-.89.453-.34.734-.81.734-1.329 0-1.035-1.121-1.875-2.5-1.875s-2.5.84-2.5 1.875c0 .52.281.988.734 1.328.29.215.516.531.516.89 0 .5-.406.907-.906.907H3.875A1.875 1.875 0 0 1 2 20.125v-4.719c0-.5.406-.906.906-.906.36 0 .676.227.89.516.34.453.81.734 1.329.734C6.16 15.75 7 14.629 7 13.25s-.84-2.5-1.875-2.5c-.52 0-.988.281-1.328.734-.215.29-.531.516-.89.516A.907.907 0 0 1 2 11.094V8.875C2 7.84 2.84 7 3.875 7h4.719c.5 0 .906-.406.906-.906"
    />
  </svg>
);
const ForwardRef = forwardRef(IconPuzzleSolid);
export { ForwardRef as IconPuzzleSolid };
