import * as React from "react";
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";

const IconFile = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    className="shared-icon"
    ref={ref}
    {...props}
  >
    <path
      fill="currentColor"
      d="M16.667 19.917c.32 0 .583-.263.583-.584v-10.5h-2.917a1.165 1.165 0 0 1-1.166-1.166V4.75H7.333a.585.585 0 0 0-.583.583v14c0 .321.263.584.583.584zM5 5.333A2.335 2.335 0 0 1 7.333 3h6.034c.62 0 1.214.244 1.652.682l3.3 3.3c.437.437.681 1.031.681 1.65v10.701a2.336 2.336 0 0 1-2.333 2.334H7.333A2.336 2.336 0 0 1 5 19.333z"
    />
  </svg>
);
const ForwardRef = forwardRef(IconFile);
export { ForwardRef as IconFile };
