import * as React from "react";
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";

const IconAiWriting = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    className="shared-icon"
    ref={ref}
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m17.99 9.07-1.027 1.027 1.94 1.94 1.027-1.027a4 4 0 0 0-.128-.36c-.15-.36-.35-.687-.558-.895-.207-.207-.535-.408-.894-.557a4 4 0 0 0-.36-.128m-.501 4.381-1.94-1.94-5.164 5.163c-.627.626-.997 1.645-1.195 2.635a10 10 0 0 0-.1.602c.186-.026.389-.059.6-.101.99-.198 2.008-.569 2.635-1.195zM8 21H7v-.055l.004-.127a12.34 12.34 0 0 1 .225-1.902c.221-1.107.69-2.605 1.741-3.656l7.968-7.967A1 1 0 0 1 17.637 7c.439-.004.994.149 1.481.351.506.21 1.087.537 1.54.99.454.454.78 1.034.99 1.54.203.487.356 1.043.352 1.482a1 1 0 0 1-.293.699l-7.968 7.967c-1.051 1.051-2.549 1.52-3.656 1.742A12.3 12.3 0 0 1 8.054 22h-.053000000000000005zm0 0v1a1 1 0 0 1-1-1zM7.5 2a1 1 0 0 1 .949.684l.967 2.9 2.9.967a1 1 0 0 1 0 1.898l-2.9.967-.967 2.9a1 1 0 0 1-1.898 0l-.967-2.9-2.9-.967a1 1 0 0 1 0-1.898l2.9-.967.967-2.9A1 1 0 0 1 7.5 2m0 4.162-.176.53a1 1 0 0 1-.633.632l-.529.176.53.176a1 1 0 0 1 .632.633l.176.529.176-.53a1 1 0 0 1 .633-.632l.529-.176-.53-.176a1 1 0 0 1-.632-.633z"
      clipRule="evenodd"
    />
  </svg>
);
const ForwardRef = forwardRef(IconAiWriting);
export { ForwardRef as IconAiWriting };
