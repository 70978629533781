import * as React from "react";
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";

const IconFacebook = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    className="shared-icon"
    ref={ref}
    {...props}
  >
    <path
      fill="currentColor"
      d="m17.01 13.25.555-3.62h-3.473V7.282c0-.99.485-1.956 2.04-1.956h1.58V2.245S16.279 2 14.909 2c-2.86 0-4.73 1.734-4.73 4.872V9.63H7v3.62h3.18V22h3.912v-8.75z"
    />
  </svg>
);
const ForwardRef = forwardRef(IconFacebook);
export { ForwardRef as IconFacebook };
