import { styled } from "@mui/material/styles";

import { IconAtomSimple } from "#shared/icons/AtomSimple";
import { IconChevronRight } from "#shared/icons/ChevronRight";
import { IconCircleQuestion } from "#shared/icons/CircleQuestion";
import { IconExit } from "#shared/icons/Exit";
import { IconEye } from "#shared/icons/Eye";
import { IconHouse } from "#shared/icons/House";
import { IconHouseSolid } from "#shared/icons/HouseSolid";
import { IconPuzzleSolid } from "#shared/icons/PuzzleSolid";
import { IconSettings } from "#shared/icons/Settings";
import { IconStar } from "#shared/icons/Star";
import { IconStarSolid } from "#shared/icons/StarSolid";
import { IconStore } from "#shared/icons/Store";
import { IconStoreSolid } from "#shared/icons/StoreSolid";
import { IconUser } from "#shared/icons/User";
import { IconUsers } from "#shared/icons/Users";
import { NavigationAction } from "#src/constants/navigation";
import { NavigationItems } from "../../../types/types";

const activeStyles = `
  path {
    fill: currentColor;
  }
`;

const ActiveHome = styled(IconHouseSolid)(activeStyles) as React.FC<
  React.SVGProps<SVGSVGElement>
>;
const ActiveStar = styled(IconStarSolid)(activeStyles) as React.FC<
  React.SVGProps<SVGSVGElement>
>;
const ActiveStore = styled(IconStoreSolid)(activeStyles) as React.FC<
  React.SVGProps<SVGSVGElement>
>;
const ActiveTeam = styled(IconUsers)(activeStyles) as React.FC<
  React.SVGProps<SVGSVGElement>
>;

export const mainNavigationItems: NavigationItems = [
  {
    id: NavigationAction.foryou,
    LogoActive: ActiveHome,
    LogoInactive: IconHouse,
    name: "navigation.menu.for_you",
  },
  {
    id: NavigationAction.following,
    LogoActive: ActiveStar,
    LogoInactive: IconStar,
    name: "navigation.menu.following",
  },
  {
    id: NavigationAction.businesslab,
    LogoActive: ActiveStore,
    LogoInactive: IconStore,
    name: "navigation.menu.business_lab",
    label: "navigation.labels.apply",
  },
  {
    id: NavigationAction.businesslab_external,
    LogoActive: ActiveStore,
    LogoInactive: IconStore,
    name: "navigation.menu.business_lab",
  },
  {
    id: NavigationAction.knowledgeAi,
    LogoActive: IconAtomSimple,
    LogoInactive: IconAtomSimple,
    name: "navigation.menu.knowledge_ai",
    label: "navigation.labels.beta",
  },
];

export const profileNavigationItems: NavigationItems = [
  {
    id: NavigationAction.profile,
    LogoActive: IconUser,
    LogoInactive: IconUser,
    name: "navigation.menu.my_profile",
  },
  {
    id: NavigationAction.business_settings,
    LogoActive: ActiveStore,
    LogoInactive: IconStore,
    name: "navigation.menu.business_settings",
  },
  {
    id: NavigationAction.business_centre,
    LogoActive: ActiveStore,
    LogoInactive: IconStore,
    name: "navigation.menu.business_centre",
  },
  {
    id: NavigationAction.settings,
    LogoActive: IconSettings,
    LogoInactive: IconSettings,
    name: "navigation.menu.settings",
  },
  {
    id: NavigationAction.god_view,
    LogoActive: IconEye,
    LogoInactive: IconEye,
    ActionIcon: IconChevronRight,
    name: "navigation.menu.view_as",
  },
  {
    id: NavigationAction.god_view_wla,
    LogoActive: IconEye,
    LogoInactive: IconEye,
    name: "navigation.menu.view_as_wla",
  },
  {
    id: NavigationAction.feedback_help,
    LogoActive: IconCircleQuestion,
    LogoInactive: IconCircleQuestion,
    name: "navigation.menu.feedback_help",
  },
  {
    id: NavigationAction.logout,
    LogoActive: IconExit,
    LogoInactive: IconExit,
    name: "navigation.menu.logout",
  },
];

export const businessNavigationItems: NavigationItems = [
  {
    id: NavigationAction.business_account,
    LogoActive: ActiveStore,
    LogoInactive: IconStore,
    name: "navigation.menu.business_account",
  },
  {
    id: NavigationAction.team_management,
    LogoActive: ActiveTeam,
    LogoInactive: ActiveTeam,
    name: "navigation.menu.team_management",
  },
  {
    id: NavigationAction.business_integrations,
    LogoActive: IconPuzzleSolid,
    LogoInactive: IconPuzzleSolid,
    name: "navigation.menu.integrations",
  },
];
