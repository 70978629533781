import * as React from "react";
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";

const IconParagraph = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    className="shared-icon"
    ref={ref}
    {...props}
  >
    <path
      fill="currentColor"
      d="M4 9.429A6.427 6.427 0 0 1 10.429 3h9.321c.534 0 .964.43.964.964 0 .535-.43.965-.964.965h-1.607v15.107c0 .534-.43.964-.964.964a.96.96 0 0 1-.965-.964V4.929h-1.928v15.107c0 .534-.43.964-.965.964a.96.96 0 0 1-.964-.964v-4.179H10.43A6.427 6.427 0 0 1 4 9.43m8.357 4.5v-9H10.43a4.5 4.5 0 0 0-4.5 4.5c0 2.487 2.013 4.5 4.5 4.5z"
    />
  </svg>
);
const ForwardRef = forwardRef(IconParagraph);
export { ForwardRef as IconParagraph };
