import React, { useState } from "react";

import { Box, IconButton, Typography } from "@mui/material";

import { FormProvider } from "#shared/context/FormContext";
import { IconCheck } from "#shared/icons/Check";
import { IconPencil } from "#shared/icons/Pencil";
import AIChatMessageContent from "#src/components/common/AIChat/components/message/AIChatMessageContent";
import BriefTemplateForm from "#src/components/common/AIChat/components/message/TemplateMessage/components/BriefTemplateForm";
import BriefTemplateSubmitButton from "#src/components/common/AIChat/components/message/TemplateMessage/components/BriefTemplateSubmitButton";
import { AI_CHAT_STATIC_MESSAGES } from "#src/components/common/AIChat/consts";
import { useAIChatProvider } from "#src/components/common/AIChat/context/AIChatProvider";
import useAIChatSubmitPrompt from "#src/components/common/AIChat/hooks/useAIChatSubmitPrompt";
import useAIChatTemplateForm from "#src/components/common/AIChat/hooks/useAIChatTemplateForm";
import { AIChatMessageProps } from "#src/components/common/AIChat/types";
import { formatAIChatTemplatePrompt } from "#src/components/common/AIChat/utils";
import { useFetchCountriesQuery } from "#src/features/common/commonSlice";
import useTranslation from "#src/utils/useTranslation";

interface Props extends AIChatMessageProps {
  onClose?: () => void;
}

export default function AIChatTemplateMessageContent({
  role,
  content,
  metadata,
  onClose,
}: Props) {
  const { t } = useTranslation();
  const { loading, addMessages } = useAIChatProvider();
  const { submitTextPrompt } = useAIChatSubmitPrompt();

  const form = useAIChatTemplateForm(metadata?.data);
  const { formValues, hasChanges, hasError, validate, resetForm } = form;

  const isSubmittable = !!metadata?.data;

  const { data: countries = [] } = useFetchCountriesQuery();

  const [isEditing, setIsEditing] = useState(false);

  const handleOpenEdit = () => {
    setIsEditing(true);
  };

  const handleConfirmEdit = async () => {
    if (!hasChanges) {
      setIsEditing(false);
      return;
    }

    const valid = validate();

    if (!valid) {
      return;
    }

    try {
      const prompt = formatAIChatTemplatePrompt(formValues, countries);
      resetForm();
      setIsEditing(false);
      addMessages([AI_CHAT_STATIC_MESSAGES.ModifyTemplate]);
      await submitTextPrompt(prompt, true);
    } catch (error) {
      console.error(error);
    }
  };

  const headerActions = () => {
    if (!isSubmittable) {
      return null;
    }

    if (isEditing) {
      return (
        <IconButton size="small" disabled={loading} onClick={handleConfirmEdit}>
          <IconCheck />
        </IconButton>
      );
    }

    return (
      <IconButton size="small" disabled={loading} onClick={handleOpenEdit}>
        <IconPencil />
      </IconButton>
    );
  };

  return (
    <AIChatMessageContent
      HeaderActions={headerActions()}
      role={role}
      metadata={metadata}
      content={!isEditing ? content : ""}
      className="-full-width"
    >
      <FormProvider value={form}>
        {isEditing && <BriefTemplateForm />}
        {isSubmittable && (
          <Box sx={{ mt: isEditing ? 4 : 2 }}>
            <BriefTemplateSubmitButton
              onCreateBrief={onClose}
              metadata={metadata?.data}
            />
            {hasError && (
              <Typography component="p" variant="caption" color="error" mt={3}>
                {t("ui.ai_chat.form.errors.form_invalid")}
              </Typography>
            )}
          </Box>
        )}
      </FormProvider>
    </AIChatMessageContent>
  );
}
