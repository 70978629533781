import { useIntl } from "react-intl";

import { FormFields } from "#shared/types/form";
import useForm from "#shared/utils/hooks/useForm";
import { CreateChannelFormField } from "#src/components/pages/Feed/ChannelsSidebar/CreateChannel/useCreateChannelForm";

export enum ChannelHeaderFormField {
  banner = "banner",
  logo = "logo",
  name = "name",
  description = "description",
}

export interface ChannelHeaderFormFields extends FormFields {
  banner: string;
  logo: string;
  name: string;
  description: string;
}

export default function useChannelHeaderForm(defaultValues: ChannelHeaderFormFields) {
  const intl = useIntl();

  const validateFn = (fieldName: keyof ChannelHeaderFormFields, value: any) => {
    let error = "";

    if (fieldName === CreateChannelFormField.name && !value) {
      error = intl.formatMessage({
        id: "ui.modals.create_channel.errors.name_required",
      });
    }

    return error;
  };

  return useForm<ChannelHeaderFormFields>(defaultValues, {
    validateOnChange: true,
    validateFn,
  });
}
