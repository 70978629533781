import * as React from "react";
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";

const IconPaperPlane = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    className="shared-icon"
    ref={ref}
    {...props}
  >
    <path
      fill="currentColor"
      d="M1.616 12.164c-.882.504-.8 1.848.14 2.238l5.481 2.286v4.035a1.275 1.275 0 0 0 2.258.816l2.422-2.902 4.84 2.015c.738.309 1.594-.175 1.715-.965l2.5-16.25a1.25 1.25 0 0 0-.528-1.218 1.25 1.25 0 0 0-1.328-.055zm2.035.996 13.34-7.62-8.578 9.585.047.04zm13.09 5.457-6.508-2.715 8.364-9.347z"
    />
  </svg>
);
const ForwardRef = forwardRef(IconPaperPlane);
export { ForwardRef as IconPaperPlane };
