import * as React from "react";
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";

const IconFloppyDisk = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    className="shared-icon"
    ref={ref}
    {...props}
  >
    <path
      fill="currentColor"
      d="M4.143 4.857v14.286c0 .393.321.714.714.714h14.286a.716.716 0 0 0 .714-.714V8.183a.72.72 0 0 0-.21-.504l1.514-1.514c.535.536.834 1.264.834 2.023v10.955A2.86 2.86 0 0 1 19.139 22H4.858A2.86 2.86 0 0 1 2 19.143V4.857A2.86 2.86 0 0 1 4.857 2h10.96c.759 0 1.487.3 2.022.835l3.326 3.326-1.513 1.513-3.33-3.321-.036-.036v4.469c0 .593-.478 1.071-1.072 1.071H6.643A1.07 1.07 0 0 1 5.57 8.786V4.143h-.714a.716.716 0 0 0-.714.714m3.571-.714v3.571h6.429V4.143zm1.429 10.714a2.857 2.857 0 1 1 5.715 0 2.857 2.857 0 0 1-5.715 0"
    />
  </svg>
);
const ForwardRef = forwardRef(IconFloppyDisk);
export { ForwardRef as IconFloppyDisk };
