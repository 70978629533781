import * as React from "react";
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";

const IconSquarePollHorizontal = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    className="shared-icon"
    ref={ref}
    {...props}
  >
    <path
      fill="currentColor"
      d="M19.857 4.857a.716.716 0 0 0-.714-.714H4.857a.716.716 0 0 0-.714.714v14.286c0 .393.321.714.714.714h14.286a.716.716 0 0 0 .714-.714zM19.143 2A2.86 2.86 0 0 1 22 4.857v14.286A2.86 2.86 0 0 1 19.143 22H4.857A2.86 2.86 0 0 1 2 19.143V4.857A2.86 2.86 0 0 1 4.857 2zm-5.714 5.714c0 .594-.478 1.072-1.072 1.072h-5a1.07 1.07 0 0 1-1.071-1.072c0-.593.477-1.071 1.071-1.071h5c.594 0 1.072.478 1.072 1.071m3.214 3.215c.594 0 1.071.477 1.071 1.071s-.477 1.071-1.071 1.071H7.357A1.07 1.07 0 0 1 6.286 12c0-.594.477-1.071 1.071-1.071zm-6.072 5.357c0 .593-.477 1.071-1.071 1.071H7.357a1.07 1.07 0 0 1-1.071-1.071c0-.594.477-1.072 1.071-1.072H9.5c.594 0 1.071.478 1.071 1.072"
    />
  </svg>
);
const ForwardRef = forwardRef(IconSquarePollHorizontal);
export { ForwardRef as IconSquarePollHorizontal };
