import * as React from "react";
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";

const IconUnsplash = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    className="shared-icon"
    ref={ref}
    {...props}
  >
    <path
      fill="currentColor"
      d="M22 10.848V22H2V10.848h6.3v5.576h7.4v-5.576zM15.7 2H8.3v5.576h7.4z"
    />
  </svg>
);
const ForwardRef = forwardRef(IconUnsplash);
export { ForwardRef as IconUnsplash };
