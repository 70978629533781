import * as React from "react";
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";

const IconPhotoFilmDuotone = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    className="shared-icon"
    ref={ref}
    {...props}
  >
    <path
      fill="#126AE8"
      d="M8 5.995A2 2 0 0 1 10 4h10a2 2 0 0 1 2 1.995v6.982a2 2 0 0 1-2 1.995H10a2 2 0 0 1-2-1.995zm8.25.997a.75.75 0 0 0-.625.334l-1.75 2.618-.54-.676a.75.75 0 0 0-1.169 0l-2 2.494a.75.75 0 0 0-.091.791.75.75 0 0 0 .675.424h8.5a.75.75 0 0 0 .663-.396.74.74 0 0 0-.038-.766l-3-4.489a.75.75 0 0 0-.625-.334m-4.75.998a1 1 0 0 0 1-.998.996.996 0 0 0-1-.997 1 1 0 0 0-1 .997.996.996 0 0 0 1 .998"
      opacity={0.25}
    />
    <path
      fill="#126AE8"
      d="m16.875 7.326 3 4.489c.153.23.169.523.038.766a.75.75 0 0 1-.663.396h-8.5a.75.75 0 0 1-.675-.424.75.75 0 0 1 .09-.791l2-2.494a.75.75 0 0 1 1.17 0l.54.673 1.75-2.618a.754.754 0 0 1 1.25.003M4 7.99h4v1.995c-.553 0-1 .446-1 .997v5.985c0 .552.447.998 1 .998h4c.553 0 1-.446 1-.998v-1.995h5v2.993a2 2 0 0 1-2 1.995H4a2 2 0 0 1-2-1.995v-7.98A2 2 0 0 1 4 7.99m.25 1.995a.5.5 0 0 0-.5.499v.498a.5.5 0 0 0 .5.5h.5a.5.5 0 0 0 .5-.5v-.498a.5.5 0 0 0-.5-.5zm0 3.242a.5.5 0 0 0-.5.498v.5a.5.5 0 0 0 .5.498h.5a.5.5 0 0 0 .5-.499v-.499a.5.5 0 0 0-.5-.498zm0 3.241a.5.5 0 0 0-.5.5v.498a.5.5 0 0 0 .5.499h.5a.5.5 0 0 0 .5-.5v-.498a.5.5 0 0 0-.5-.498zm10.5.5v.498a.5.5 0 0 0 .5.499h.5a.5.5 0 0 0 .5-.5v-.498a.5.5 0 0 0-.5-.498h-.5a.5.5 0 0 0-.5.498"
    />
  </svg>
);
const ForwardRef = forwardRef(IconPhotoFilmDuotone);
export { ForwardRef as IconPhotoFilmDuotone };
