import React from "react";

import { Box, styled } from "@mui/material";

import { UnsplashApi } from "#src/apis/unsplash/api";
import { EditorImageProps } from "#src/components/common/Editor/extensions/ImageUpload/EditorImageUploadView";
import BuilderUnsplashSelector from "#src/components/pages/BriefBuilder/Brief/steps/Cover/components/BuilderUnsplashSelector";
import { UnsplashPhoto } from "#src/types/unsplash";

export default function EditorImageUnsplash({ editor, getPos }: EditorImageProps) {
  const handleImageClick = (image: UnsplashPhoto) => {
    // Mark the image as downloaded asynchronously
    UnsplashApi.getPhoto(image.id);

    editor
      .chain()
      .setImageBlock({
        src: image.urls.regular,
        unsplashId: image.id,
      })
      .deleteRange({ from: getPos(), to: getPos() })
      .focus()
      .run();
  };

  return (
    <Wrapper>
      <BuilderUnsplashSelector onImageClick={handleImageClick} />
    </Wrapper>
  );
}

const Wrapper = styled(Box)`
  .unsplash-list-wrapper {
    max-height: 600px;
    overflow: auto;
  }

  .MuiImageList-root {
    padding: 0;
  }
`;
