import React, { useState } from "react";

import { IconButton, styled } from "@mui/material";

import { Dropzone } from "#shared/components/ui";
import Toast from "#shared/components/ui/Toast";
import theme from "#shared/config/theme";
import colors from "#shared/config/theme/colors";
import { IconCameraSolid } from "#shared/icons/CameraSolid";
import { alphaColor, resizeImage } from "#shared/utils";
import { ChannelBannerImage } from "#src/components/pages/ChannelDetails/ChannelHeader/styled";
import useTranslation from "#src/utils/useTranslation";

type ImageType = "banner" | "logo";

interface Props {
  type: ImageType;
  rounding?: "none" | "md" | "lg";
  value: string;
  onChange: (value: string) => void;
}

export default function ChannelUploadImage({
  type,
  rounding = "md",
  value,
  onChange,
}: Props) {
  const { t } = useTranslation();

  const [, setIsImageLoading] = useState(false);
  const [error, setError] = useState("");

  const getBorderRadius = () => {
    switch (rounding) {
      case "lg":
        return `${theme.rounding.default} ${theme.rounding.default} 0 0`;
      case "md":
        return `${theme.rounding.md} ${theme.rounding.md} 0 0`;
      default:
        return "0";
    }
  };

  const handleImageChange = async (files: File[], type: ImageType) => {
    try {
      setIsImageLoading(true);

      const imageUrl = URL.createObjectURL(files[0]);
      const maxWidthHeight = type === "banner" ? 800 : 200;
      const resizedImage = await resizeImage(imageUrl, maxWidthHeight, maxWidthHeight);
      const resizedImageUrl = URL.createObjectURL(resizedImage);

      onChange(resizedImageUrl);
      setError("");
    } catch (error) {
      setError(t("errors.image_upload_error"));
      console.error(error);
    } finally {
      setIsImageLoading(false);
    }
  };

  const handleError = () => {
    setError(t("errors.file_not_supported"));
    onChange("");
  };

  return (
    <Wrapper>
      {type === "banner" && (
        <Dropzone
          onChange={(files) => handleImageChange(files, "banner")}
          onError={handleError}
          mediaType="image"
          showOverlay
          sx={{
            background: "transparent",
            padding: 0,
            borderRadius: getBorderRadius(),
            img: {
              borderRadius: getBorderRadius(),
              objectFit: "cover",
            },
            "& > *": {
              pointerEvents: "none",
            },
          }}
        >
          {value && <ChannelBannerImage src={value} alt={`Channel banner`} />}
          <UploadButton size="small">
            <IconCameraSolid />
          </UploadButton>
        </Dropzone>
      )}
      {type === "logo" && (
        <Dropzone
          onChange={(files) => handleImageChange(files, "logo")}
          onError={handleError}
          mediaType="image"
          showOverlay
          sx={{
            background: "transparent",
            padding: 0,
            borderRadius: theme.rounding.full,
            img: {
              borderRadius: theme.rounding.full,
              objectFit: "cover",
            },
            "& > *": {
              pointerEvents: "none",
            },
          }}
        >
          {value && <ChannelBannerImage src={value} alt={`Channel logo`} />}
          <UploadButton size="small">
            <IconCameraSolid />
          </UploadButton>
        </Dropzone>
      )}
      <Toast open={!!error} type="error" message={error} onClose={() => setError("")} />
    </Wrapper>
  );
}

const Wrapper = styled("div")`
  position: relative;
  width: 100%;
  height: 100%;

  & > div {
    width: 100%;
    height: 100%;
  }
`;

const UploadButton = styled(IconButton)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: ${alphaColor(colors.base.black, 0.3)} !important;
  color: white;
`;
