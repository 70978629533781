import { useCallback, useMemo } from "react";

import { useQueryClient } from "@tanstack/react-query";
import { generateHTML } from "@tiptap/react";
import { useNavigate } from "react-router";

import useToast from "#shared/hooks/useToast";
import { convertImageUrlToFile, convertVideoUrlToFile } from "#shared/utils";
import extensions from "#src/components/common/Editor/extensions";
import { BuilderAudienceFormFields } from "#src/components/pages/BriefBuilder/Audience/hooks/useBuilderAudienceForm";
import { mapBriefBuilderAudienceRequest } from "#src/components/pages/BriefBuilder/Audience/utils";
import { BuilderContentFormFields } from "#src/components/pages/BriefBuilder/Brief/steps/Content/hooks/useBuilderContentForm";
import { BuilderCoverFormFields } from "#src/components/pages/BriefBuilder/Brief/steps/Cover/hooks/useBuilderCoverForm";
import { BuilderLaunchFormFields } from "#src/components/pages/BriefBuilder/Launch/hooks/useBuilderLaunchForm";
import { mapBuilderLaunchRequest } from "#src/components/pages/BriefBuilder/Launch/utils";
import { useBriefBuilder } from "#src/containers/BriefBuilder/BriefBuilderProvider";
import useBriefBuilderPermissions from "#src/containers/BriefBuilder/hooks/useBriefBuilderPermissions";
import useBriefBuilderSteps from "#src/containers/BriefBuilder/hooks/useBriefBuilderSteps";
import {
  useSaveBriefAudienceMutation,
  useSaveBriefContentMutation,
  useSaveBriefCoverMutation,
  useSaveBriefGeneralMutation,
  useSaveBriefLaunchMutation,
  useSaveBriefSurveyMutation,
  useSaveBriefTitleMutation,
} from "#src/features/briefBuilder/briefBuilderAPI";
import {
  BriefBuilderStep,
  SaveBriefGeneralRequest,
  SurveyPreviewStep,
} from "#src/types/briefBuilder";
import { BriefQueryKeys } from "#src/utils/queryKeys";
import useTranslation from "#src/utils/useTranslation";

interface HandleSaveArgs {
  isSurveyPreviewInit?: boolean;
}

export const handleSurveyBuilderSave = () => {
  const iframe = document.getElementById("survey-iframe") as HTMLIFrameElement;
  const message = JSON.stringify({ action: "save" });

  if (!iframe.contentWindow) {
    throw new Error("Error while trying to save survey: iframe not found");
  }

  iframe.contentWindow?.postMessage(message, "*");
};

export default function useBriefBuilderActions() {
  const { t } = useTranslation();
  const toast = useToast();
  const navigate = useNavigate();

  const queryClient = useQueryClient();

  const {
    brief,
    briefRef,
    formValues,
    isSavingMedia,
    isSavingSurvey,
    isStepValid,
    surveyPreviewStep,
    setIsSavingMedia,
    setIsSavingSurvey,
    setSurveyPreviewStep,
  } = useBriefBuilder();
  const { isLaunchedBrief, isScheduledBrief } = useBriefBuilderPermissions();
  const { activeStep, goToNextStep } = useBriefBuilderSteps();

  const [saveBriefCover] = useSaveBriefCoverMutation();
  const [saveBriefTitle, { isLoading: isLoadingTitle }] = useSaveBriefTitleMutation();
  const [saveBriefContent, { isLoading: isLoadingContent }] =
    useSaveBriefContentMutation();
  const [saveBriefGeneral, { isLoading: isLoadingGeneral }] =
    useSaveBriefGeneralMutation();
  const [saveBriefSurvey, { isLoading: isLoadingSurvey }] = useSaveBriefSurveyMutation();
  const [saveBriefAudience, { isLoading: isLoadingAudience }] =
    useSaveBriefAudienceMutation();
  const [saveBriefLaunch, { isLoading: isLoadingLaunch }] = useSaveBriefLaunchMutation();

  const isLoading = [
    isLoadingTitle,
    isLoadingContent,
    isLoadingGeneral,
    isLoadingSurvey,
    isLoadingAudience,
    isLoadingLaunch,
    isSavingSurvey,
  ].some(Boolean);

  const saveCoverMedia = useCallback(async () => {
    if (isSavingMedia) {
      return;
    }

    let coverPhoto = undefined;
    let coverVideo = undefined;
    let wallpaperId = undefined;
    let unsplashImageId = undefined;

    const values = formValues as BuilderCoverFormFields;

    try {
      if (values.coverPhoto) {
        coverPhoto = !values.coverPhoto.startsWith("https://")
          ? await convertImageUrlToFile(values.coverPhoto)
          : undefined;
      }

      if (values.coverVideo) {
        coverVideo = !values.coverVideo.startsWith("https://")
          ? await convertVideoUrlToFile(values.coverVideo)
          : undefined;
      }

      if (values.wallpaperId && values.wallpaperId !== brief?.coverWallpaperId) {
        wallpaperId = values.wallpaperId;
      }

      if (values.unsplashId && values.unsplashId !== brief?.unsplashImageId) {
        unsplashImageId = values.unsplashId;
      }

      // No media to save
      if (!coverPhoto && !coverVideo && !wallpaperId && !unsplashImageId) {
        return;
      }

      // Show saving only when uploading new media
      if (!wallpaperId && !unsplashImageId) {
        setIsSavingMedia(true);
      }

      await saveBriefCover({
        briefRef,
        coverPhoto,
        unsplashImageId,
        coverVideo,
        wallpaperId,
      }).unwrap();
      queryClient.invalidateQueries({ queryKey: BriefQueryKeys.list() });
      queryClient.removeQueries({ queryKey: BriefQueryKeys.details({ briefRef }) });
    } catch (error) {
      toast.error({ message: t("ui.toasts.save_media_error") });
      return;
    } finally {
      setIsSavingMedia(false);
    }
  }, [brief, formValues, isSavingMedia, setIsSavingMedia, briefRef]);

  const mainAction: "view" | "save" | "continue" = useMemo(() => {
    if (activeStep === BriefBuilderStep.Share) {
      return "view";
    }

    if (isLaunchedBrief) {
      return "save";
    }

    if (activeStep === BriefBuilderStep.Launch) {
      const values = formValues as BuilderLaunchFormFields;

      if (isScheduledBrief && values.method === "schedule") {
        return "save";
      }
    }

    return "continue";
  }, [activeStep, isLaunchedBrief, isScheduledBrief, formValues]);

  const saveStep = useCallback(async (promise: Promise<any>) => {
    try {
      const result = await promise;
      queryClient.invalidateQueries({ queryKey: BriefQueryKeys.list() });
      queryClient.removeQueries({ queryKey: BriefQueryKeys.details({ briefRef }) });
      return result;
    } catch (error) {
      toast.error();
      throw error;
    }
  }, []);

  const saveActiveStep = useCallback(
    async (args?: { handleSave?: HandleSaveArgs }) => {
      switch (activeStep) {
        case BriefBuilderStep.Brief: {
          const values = formValues as BuilderCoverFormFields;
          // save brief cover async
          saveCoverMedia();
          return await saveStep(
            saveBriefTitle({ briefRef, title: values.title }).unwrap()
          );
        }
        case BriefBuilderStep.BriefEditor: {
          const values = formValues as BuilderContentFormFields;
          const contentDocumentHtml = values.content
            ? generateHTML(JSON.parse(values.content), extensions)
            : "";

          return await saveStep(
            saveBriefContent({
              briefRef,
              contentDocumentJson: values.content,
              contentDocumentHtml,
            }).unwrap()
          );
        }
        case BriefBuilderStep.General: {
          const { briefRef: _, ...values } = formValues as SaveBriefGeneralRequest;

          return await saveStep(
            saveBriefGeneral({
              briefRef,
              ...values,
            }).unwrap()
          );
        }
        case BriefBuilderStep.Survey: {
          const iframe = document.getElementById("survey-iframe") as HTMLIFrameElement;
          const message = JSON.stringify({ action: "save" });

          if (!iframe.contentWindow) {
            throw new Error("Error while trying to save survey: iframe not found");
          }

          iframe.contentWindow?.postMessage(message, "*");

          if (args?.handleSave?.isSurveyPreviewInit) {
            setSurveyPreviewStep(SurveyPreviewStep.saving);
          } else {
            setIsSavingSurvey(true);
          }
          break;
        }
        case BriefBuilderStep.Audience: {
          const values = formValues as BuilderAudienceFormFields;

          return await saveStep(
            saveBriefAudience(mapBriefBuilderAudienceRequest(briefRef, values)).unwrap()
          );
        }
        case BriefBuilderStep.Launch: {
          const values = formValues as BuilderLaunchFormFields;

          return await saveStep(
            saveBriefLaunch(mapBuilderLaunchRequest(briefRef, values)).unwrap()
          );
        }
        default:
          throw new Error("Error while trying to advance invalid step");
      }
    },
    [briefRef, activeStep, formValues, saveStep, saveBriefTitle, saveCoverMedia]
  );

  const handleViewBrief = useCallback(() => {
    navigate(`/feed/${briefRef}`, { state: { from: "brief-builder" } });
  }, [briefRef]);

  const handleContinue = useCallback(async () => {
    await saveActiveStep();

    // Skip goToNextStep if we are in the survey step - callback will handle it
    if (activeStep === BriefBuilderStep.Survey) {
      return;
    }

    goToNextStep();
  }, [saveActiveStep, goToNextStep, activeStep]);

  const handleSave = useCallback(
    async (args?: HandleSaveArgs) => {
      if (isLoading || !isStepValid) {
        return;
      }

      await saveActiveStep({ handleSave: args });

      // Skip toast if we are in the survey step - callback will handle it
      if (activeStep === BriefBuilderStep.Survey) {
        return;
      }

      toast.success({ message: t("ui.toasts.changes_success") });
    },
    [isLoading, isStepValid, activeStep, saveActiveStep]
  );

  const handleSaveSurveyCallback = useCallback(
    async (success: boolean) => {
      if (surveyPreviewStep > SurveyPreviewStep.idle) {
        setSurveyPreviewStep(SurveyPreviewStep.saved);
      } else {
        if (!success) {
          setIsSavingSurvey(false);
          toast.error();
          return;
        }

        await saveStep(saveBriefSurvey({ briefRef }).unwrap());
        setIsSavingSurvey(false);

        if (mainAction === "continue") {
          goToNextStep();
        } else {
          toast.success({ message: t("ui.toasts.changes_success") });
        }
      }
    },
    [saveStep, saveBriefSurvey, goToNextStep, mainAction, surveyPreviewStep]
  );

  return {
    handleSave,
    handleContinue,
    handleSaveSurveyCallback,
    handleViewBrief,
    mainAction,
    isLoading,
  };
}
