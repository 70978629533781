import { useCallback } from "react";

import useCustomPopper from "#shared/components/ui/Popper/useCustomPopper";
import { EditorMenuItems } from "#src/components/common/Editor/types";
import { MenuItemProps } from "#src/types/menu";

interface Props {
  items: MenuItemProps[] | EditorMenuItems;
  onMenuOpen?: () => void;
  onMenuClose?: () => void;
  onItemClick?: (id: string) => void;
}

export default function useMenuDropdown({
  items,
  onMenuOpen,
  onMenuClose,
  onItemClick,
}: Props) {
  const [anchorRef, open, setOpen] = useCustomPopper<HTMLDivElement>();

  const onClickMenuItem = useCallback(
    async (id: string) => {
      if (id === "submenu") {
        onItemClick?.(id);
        closeMenu();
        return;
      }

      const item = items.find((item) => item.id === id);

      if (!item || ("type" in item && item.type !== "option")) {
        return;
      }

      item.onClick();
      onItemClick?.(id);
      closeMenu();
    },
    [items, onItemClick]
  );

  const openMenu = useCallback(() => {
    setOpen(true);
    onMenuOpen?.();
  }, [onMenuOpen]);

  const closeMenu = useCallback(() => {
    setOpen(false);
    onMenuClose?.();
  }, [onMenuClose]);

  return {
    anchorRef,
    open,
    openMenu,
    closeMenu,
    onClickMenuItem,
  };
}
