import * as React from "react";
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";

const IconCircleInfo = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    className="shared-icon"
    ref={ref}
    {...props}
  >
    <path
      fill="currentColor"
      d="M12 3.875a8.125 8.125 0 1 1 0 16.25 8.125 8.125 0 0 1 0-16.25M12 22a10 10 0 1 0 0-20 10 10 0 0 0 0 20m-1.562-6.875a.935.935 0 0 0-.938.938c0 .519.418.937.938.937h3.124c.52 0 .938-.418.938-.937a.935.935 0 0 0-.937-.938h-.313v-3.437a.935.935 0 0 0-.937-.938h-1.876a.935.935 0 0 0-.937.938c0 .519.418.937.938.937h.937v2.5zM12 9.5A1.25 1.25 0 1 0 12 7a1.25 1.25 0 0 0 0 2.5"
    />
  </svg>
);
const ForwardRef = forwardRef(IconCircleInfo);
export { ForwardRef as IconCircleInfo };
