import { useParams } from "react-router";

import { TransformUrlParam } from "#shared/utils/transform.utils";
import { useFetchChannelByRefQuery } from "#src/features/channel/channelsAPI";

interface Props {
  skip?: boolean;
}

export default function useSelectedChannel({ skip = false }: Props = {}) {
  const params = useParams() as { channelRef?: string };
  const channelRef = TransformUrlParam.channel(params.channelRef || "");

  const query = useFetchChannelByRefQuery(
    { channelRef: channelRef },
    {
      skip: skip || !channelRef,
    }
  );

  const data =
    query.data && query.data.channelRef === channelRef ? query.data : undefined;
  const isFetchingProps = query.isFetching && !query.currentData;

  return { ...query, data, isFetching: isFetchingProps, channelRef };
}
