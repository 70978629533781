import { InfiniteData } from "@tanstack/react-query";

import { PaginatedResponse } from "#src/types/pagination";

export const getInfiniteQueryOptions = () => ({
  initialPageParam: 1,
  getNextPageParam: (lastPage: PaginatedResponse<any>) => {
    const { page, totalPages } = lastPage.pagination;
    return page < totalPages ? page + 1 : undefined;
  },
});

export function mapPaginatedData<T>(data?: InfiniteData<PaginatedResponse<T>>): T[] {
  if (!data?.pages.length) {
    return [];
  }

  return data.pages.flatMap((page) => page.data);
}
