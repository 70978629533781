import React from "react";

import CustomModal from "#shared/components/ui/Modal";
import ModalContent from "#shared/components/ui/Modal/ModalContent";
import ModalHeader from "#shared/components/ui/Modal/ModalHeader";
import Share from "#src/components/common/Share/Share";
import { BaseModalProps, ModalCloseReason } from "#src/types/modals";
import { ShareTarget, ShareConfig } from "#src/types/share";
import useTranslation from "#src/utils/useTranslation";

interface Props extends BaseModalProps {
  config: ShareConfig;
  onSocialClick: (target: ShareTarget) => void;
}

export default function ShareDialog({ config, onSocialClick, isOpen, onClose }: Props) {
  const { t } = useTranslation();

  return (
    <CustomModal
      open={isOpen}
      Header={<ModalHeader title={t("ui.modals.share_dialog.title")} onClose={onClose} />}
      Content={
        <ModalContent>
          <Share config={config} onSocialClick={onSocialClick} />
        </ModalContent>
      }
      onClose={() => onClose(ModalCloseReason.Dismiss)}
    />
  );
}
