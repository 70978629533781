import * as React from "react";
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";

const IconAiImage = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    className="shared-icon"
    ref={ref}
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M16.286 2c.41 0 .774.262.903.651l1.04 3.12 3.12 1.04a.952.952 0 0 1 0 1.807l-3.12 1.04-1.04 3.12a.952.952 0 0 1-1.807 0l-1.04-3.12-3.12-1.04a.952.952 0 0 1 0-1.807l3.12-1.04 1.04-3.12A.95.95 0 0 1 16.286 2m0 3.964-.287.861a.95.95 0 0 1-.603.602l-.86.287.86.287a.95.95 0 0 1 .603.603l.287.86.287-.86A.95.95 0 0 1 17.175 8l.86-.287-.86-.287a.95.95 0 0 1-.602-.602zM7.484 4.857h1.183a.952.952 0 0 1 0 1.905H7.524c-.816 0-1.37 0-1.8.036-.417.034-.63.096-.78.172-.358.182-.649.474-.832.832-.076.149-.137.362-.171.78-.035.429-.036.983-.036 1.799v4.78l1.31-1.047a.95.95 0 0 1 1.19 0l1.715 1.372 2.73-2.73a.952.952 0 1 1 1.347 1.346l-3.333 3.333a.95.95 0 0 1-1.268.07L5.81 16.078l-1.786 1.429a1 1 0 0 1-.113.078c.004.266.013.492.03.691.034.418.095.631.171.78.183.359.474.65.833.833.149.075.362.137.78.171.428.035.983.036 1.799.036h6.095c.816 0 1.37 0 1.8-.035.417-.035.63-.096.78-.172.357-.183.649-.474.831-.833.076-.149.138-.362.172-.78.035-.428.036-.983.036-1.799v-1.143a.952.952 0 0 1 1.905 0v1.182c0 .767 0 1.4-.042 1.915-.044.536-.138 1.028-.373 1.49a3.8 3.8 0 0 1-1.665 1.665c-.462.235-.954.33-1.49.373-.515.042-1.148.042-1.915.042H7.484c-.766 0-1.399 0-1.914-.042-.536-.044-1.028-.138-1.49-.373a3.8 3.8 0 0 1-1.665-1.665c-.235-.462-.33-.954-.373-1.49C2 17.915 2 17.282 2 16.515v-6.173c0-.767 0-1.4.042-1.915.044-.535.138-1.028.373-1.49A3.8 3.8 0 0 1 4.08 5.272c.462-.235.954-.329 1.49-.373.515-.042 1.148-.042 1.914-.042"
      clipRule="evenodd"
    />
  </svg>
);
const ForwardRef = forwardRef(IconAiImage);
export { ForwardRef as IconAiImage };
