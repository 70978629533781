import { useCallback, useMemo } from "react";

import { useIntl } from "react-intl";

import { sortInternalData } from "#shared/utils";
import { useGetInterests } from "#src/apis/interests/hooks/useGetInterests";
import { INTERESTS } from "#src/constants/interests";
import { SelectInterest } from "#src/types/interests";

export default function useInterests() {
  const intl = useIntl();
  const { data } = useGetInterests();

  const interests: SelectInterest[] = useMemo(() => {
    if (!data?.length) {
      return INTERESTS.map((interest) => ({
        value: interest.value,
        label: intl.formatMessage({ id: interest.label }),
        order: 0,
      })).sort((a, b) => a.label.localeCompare(b.label));
    }

    return data
      ?.map((interest) => ({
        value: interest.id,
        label: intl.formatMessage({
          id: INTERESTS.find((i) => i.value === interest.id)?.label || interest.name,
        }),
        order: interest.order,
      }))
      .sort((a, b) => a.label.localeCompare(b.label));
  }, [data, intl]);

  const getInterests = useCallback(
    ({
      orderBy = "alphabetical",
      exclude,
      limit,
    }: {
      orderBy?: "popularity" | "alphabetical";
      exclude?: number[];
      limit?: number;
    } = {}) => {
      let sortedInterests = interests;

      if (orderBy === "popularity") {
        sortedInterests = sortInternalData(sortedInterests, "order", "asc");
      } else {
        sortedInterests = sortInternalData(sortedInterests, "label", "asc");
      }

      if (exclude?.length) {
        sortedInterests = sortedInterests.filter(
          (interest) => !exclude.includes(interest.value)
        );
      }

      if (limit) {
        sortedInterests = sortedInterests.slice(0, limit);
      }

      return sortedInterests;
    },
    [interests]
  );

  return { interests, getInterests };
}
