import {
  AIChatMessageType,
  AIChatRole,
  AIChatTemplate,
} from "#src/components/common/AIChat/types";

// example prompt: I want to know how Gen Z aged 17-24 of all genders from United Kingdom perceive bumper stickers.

export const AI_CHAT_TEMPLATES: AIChatTemplate[] = [
  {
    id: "shopper_journey",
    title: "🛒 Shopper Journey",
    prompt: "I want to see how women aged 25-34 from United States shop for clothes.",
  },
  {
    id: "day_in_life",
    title: "🌞 Day in life of a certain age group",
    prompt: "I would like to know how people aged 18-24 spend their mornings.",
  },
  {
    id: "first_impressions",
    title: "👀 First impressions",
    prompt:
      "I would like to know what people in my channel think about our recent launch.",
  },
  {
    id: "overall_brand_perception",
    title: "🏷️ Overall brand perception",
    prompt:
      "I would like to know how people aged 30-50 across whole world perceive our brand.",
  },
  {
    id: "spending_habits_and_trends",
    title: "💸 Spending habits and trends",
    prompt: "I would like to know how much money men aged 18-24 spend on video games.",
  },
  {
    id: "product_feedback",
    title: "📦 Product feedback",
    prompt: "I would like to know feedback on our recent product from people aged 25-34.",
  },
  {
    id: "social_media_usage",
    title: "📱 Social media usage",
    prompt: "I would like to know how much time teens aged 18-24 spend on social media.",
  },
  {
    id: "hobbies",
    title: "🎨 Free time",
    prompt: "I would like to know how females aged 25-35 spend their free time.",
  },
  {
    id: "instore_shopper",
    title: "🛍️ In-store Shopper",
    prompt: "How are girls aged 13-19 experiencing shopping makeup in our store?",
  },
  {
    id: "submit_ideas",
    title: "💡 Find ideas",
    prompt:
      "I need help finding ideas for a new matcha brand targeted at girls aged 18-26 across UK.",
  },
  {
    id: "price_sensitivity",
    title: "💸 Price Sensitivity",
    prompt:
      "I would like to know how sensitive our customers are to changes in product pricing.",
  },
  {
    id: "tv_show_preferences",
    title: "📺 TV show preferences",
    prompt: "I would like to know TV show preferences of people aged 18-24.",
  },
];
/* Static messages are simulated by Frontend */
export const AI_CHAT_STATIC_MESSAGES = {
  Error: {
    role: AIChatRole.Assistant,
    content: "Something went wrong. Please try again.",
    skip: true,
    metadata: {
      type: AIChatMessageType.Error,
    },
  },
  ConfirmChannel: (channelName: string) => ({
    role: AIChatRole.Assistant,
    content: `It looks like you want to add this into the <b>${channelName}</b>?`,
    skip: true,
    metadata: {
      type: AIChatMessageType.ConfirmChannel,
    },
  }),
  ChannelList: {
    role: AIChatRole.Assistant,
    content: "Got it! In which channel do you want to launch your brief?",
    skip: true,
    metadata: {
      type: AIChatMessageType.ChannelList,
    },
  },
  GenerationAborded: {
    role: AIChatRole.Assistant,
    content: "You cancelled your request.",
    skip: true,
    metadata: {
      type: AIChatMessageType.Error,
    },
  },
  SelectChannel: (channelName: string) => ({
    role: AIChatRole.User,
    content: channelName,
    skip: true,
  }),
  ApproveChannel: {
    role: AIChatRole.User,
    content: "Yes, the channel is correct.",
    skip: true,
  },
  ChangeChannel: {
    role: AIChatRole.User,
    content: "No, I would like to select another channel.",
    skip: true,
  },
  ModifyTemplate: {
    role: AIChatRole.User,
    content: "Modify my study.",
  },
};
