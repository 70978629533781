import * as React from "react";
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";

const IconCompass = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    className="shared-icon"
    ref={ref}
    {...props}
  >
    <path
      fill="currentColor"
      d="M20.125 12a8.125 8.125 0 1 0-16.25 0 8.125 8.125 0 0 0 16.25 0M2 12a10 10 0 1 1 20 0 10 10 0 0 1-20 0m11.98 2.7-5.636 2.167c-.758.293-1.504-.453-1.211-1.21L9.3 10.02c.129-.332.386-.59.719-.72l5.636-2.167c.758-.293 1.504.453 1.211 1.21L14.7 13.98c-.125.332-.386.59-.719.72m-.73-2.7a1.25 1.25 0 1 0-2.5 0 1.25 1.25 0 0 0 2.5 0"
    />
  </svg>
);
const ForwardRef = forwardRef(IconCompass);
export { ForwardRef as IconCompass };
