import { post } from "#shared/components/api/http";
import env from "#src/env";

interface APIBody {
  app_id: string;
}

async function logout(): Promise<void> {
  await post<APIBody, Promise<void>>(
    `${env.VITE_VUE_APP_BS_API_BASE_URL}/logout`,
    {
      app_id: env.VITE_VUE_APP_BS_API_APP_ID,
    },
    { credentials: "include" }
  );
}

export default logout;
