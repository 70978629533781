import * as React from "react";
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";

const IconPencil = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    className="shared-icon"
    ref={ref}
    {...props}
  >
    <path
      fill="currentColor"
      d="m3.423 16.091-.9 3.054-.484 1.65a.939.939 0 0 0 1.161 1.17l1.654-.489 3.054-.9a3.4 3.4 0 0 0 1.307-.719q.08-.072.156-.148L21.267 7.817a2.504 2.504 0 0 0 0-3.54l-1.541-1.544a2.5 2.5 0 0 0-3.54 0L4.292 14.63a3.5 3.5 0 0 0-.868 1.462m1.799.528c.066-.219.176-.422.328-.594.024-.023.043-.047.067-.07l8.935-8.932 2.425 2.425-8.932 8.935a1.6 1.6 0 0 1-.664.395l-.916.27-2.142.63.63-2.144z"
    />
  </svg>
);
const ForwardRef = forwardRef(IconPencil);
export { ForwardRef as IconPencil };
