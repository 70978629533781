import React from "react";

import { Box, styled } from "@mui/material";

import colors from "#shared/config/theme/colors";
import rounding from "#shared/config/theme/rounding";
import { useMediaQueryProvider } from "#shared/context/MediaQueryProvider";
import { IconSparkles } from "#shared/icons/Sparkles";
import { AIChatRole } from "#src/components/common/AIChat/types";
import Avatar from "#src/components/common/Avatar";
import { selectUser } from "#src/features/user/userSlice";
import { IMAGE_TYPES } from "#src/types/types";
import { useSelectorTyped } from "#src/utils/index";

interface Props {
  role: AIChatRole;
}

export default function AIChatMessageAvatar({ role }: Props) {
  const user = useSelectorTyped(selectUser);
  const { isMobile } = useMediaQueryProvider();

  if (role === AIChatRole.User) {
    return (
      <AvatarWrapper>
        <Avatar
          uri={user.profile?.avatar || ""}
          type={IMAGE_TYPES.USER_AVATAR}
          diameter={isMobile ? 2 : 2.25}
        />
      </AvatarWrapper>
    );
  }

  return (
    <AvatarWrapper>
      <AssistantIconWrapper>
        <IconSparkles />
      </AssistantIconWrapper>
    </AvatarWrapper>
  );
}

const AvatarWrapper = styled(Box)`
  display: flex;
  align-items: end;
  opacity: 0;
  animation: avatar 0.5s forwards;

  @keyframes avatar {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

const AssistantIconWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.25rem;
  height: 2.25rem;
  border-radius: ${rounding.full};
  background: ${colors.gradient.ai.value};
  flex-shrink: 0;

  svg {
    color: ${colors.base.white};
    width: 1.5rem;
    height: 1.5rem;
  }

  ${({ theme }) => theme.breakpoints.down("sm")} {
    width: 2rem;
    height: 2rem;

    svg {
      width: 1.25rem;
      height: 1.25rem;
    }
  }
`;
