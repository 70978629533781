import React, {
  useCallback,
  useEffect,
  useState,
  ReactNode,
  useMemo,
  useContext,
  createContext,
} from "react";

import {
  BaseAIChatContextProps,
  BaseAIChatProvider,
  useBaseAIChatProvider,
} from "#shared/context/BaseAIChatProvider";
import {
  AIChatChannel,
  AIChatMessageProps,
  AIChatStep,
} from "#src/components/common/AIChat/types";
import useChannelPermissions from "#src/components/pages/ChannelDetails/useChannelPermissions";
import useSelectedChannel from "#src/components/pages/ChannelDetails/useSelectedChannel";
import useMyOwnedChannels from "#src/components/pages/Feed/ChannelsSidebar/useMyOwnedChannels";

type ContextProps = BaseAIChatContextProps<AIChatStep, AIChatMessageProps> & {
  channel?: AIChatChannel;
  setChannel: (channel: AIChatChannel) => void;
};

const AIChatContext = createContext<ContextProps | null>(null);

type ProviderProps = {
  children: ReactNode;
};

function AIChatProviderInner({ children }: ProviderProps) {
  const baseContext = useBaseAIChatProvider<AIChatStep, AIChatMessageProps>();

  const { messages, setStep, reset } = baseContext;

  const { data: selectedChannel, channelRef } = useSelectedChannel();
  const { canEditChannel } = useChannelPermissions(channelRef);
  const { approvedBusinessChannels } = useMyOwnedChannels();

  const [channel, setChannel] = useState<AIChatChannel | undefined>(undefined);

  useEffect(() => {
    // Skip if chat is already in progress
    if (messages.length > 0) {
      return;
    }

    setDefaultState();
  }, [selectedChannel, approvedBusinessChannels, canEditChannel]);

  const setDefaultState = useCallback(() => {
    reset();
    setChannel(undefined);

    // If user has only one channel, set it as the default channel
    if (approvedBusinessChannels.length === 1) {
      const channel = approvedBusinessChannels[0];
      setChannel({ channelRef: channel.channelRef, channelName: channel.name });
      setStep(AIChatStep.BriefChat);
      return;
    }

    // If user is on a channel page, set it as the default channel
    if (selectedChannel?.isVerified && canEditChannel) {
      setChannel({
        channelRef: selectedChannel.channelRef,
        channelName: selectedChannel.name,
      });
    }
  }, [selectedChannel, approvedBusinessChannels, canEditChannel]);

  const providerValue = useMemo(
    () => ({
      ...baseContext,
      channel,
      setChannel,
      reset: setDefaultState,
    }),
    [baseContext, channel, setDefaultState]
  );

  return (
    <AIChatContext.Provider value={providerValue}>{children}</AIChatContext.Provider>
  );
}

export function AIChatProvider({ children }: ProviderProps) {
  return (
    <BaseAIChatProvider initialStep={AIChatStep.Welcome}>
      <AIChatProviderInner>{children}</AIChatProviderInner>
    </BaseAIChatProvider>
  );
}

export function useAIChatProvider(): ContextProps {
  const context = useContext(AIChatContext);
  if (!context) {
    throw new Error("useBriefActionsProvider must be used within a BriefActionsProvider");
  }
  return context;
}
