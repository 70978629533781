import React from "react";
import { useMemo } from "react";

import { Button, styled } from "@mui/material";
import { NodeViewWrapper, NodeViewWrapperProps } from "@tiptap/react";

import rounding from "#shared/config/theme/rounding";
import { IconImage } from "#shared/icons/Image";
import AIGenerateWidget from "#src/components/common/Editor/components/AIGenerateWidget";
import EditorMenuDropdown from "#src/components/common/Editor/components/EditorMenuDropdown";
import { EDITOR_AI_IMAGE_OPTIONS } from "#src/components/common/Editor/constants";
import useMagicImage from "#src/components/common/Editor/extensions/MagicImage/useMagicImage";
import { EditorMenuOption } from "#src/components/common/Editor/types";
import useTranslation from "#src/utils/useTranslation";

export default function MagicImageView({
  editor,
  node,
  getPos,
  deleteNode,
}: NodeViewWrapperProps) {
  const { t } = useTranslation();
  const {
    data,
    isGenerating,
    isUploading,
    previewImage,
    generateImage,
    handlePromptChange,
    handleConfirm,
    handleDiscard,
    handleSelectImageStyle,
  } = useMagicImage({ editor, node, getPos, deleteNode });

  const currentStyle = EDITOR_AI_IMAGE_OPTIONS.find((t) => t.value === data.imageStyle);

  const imageOptions: EditorMenuOption[] = useMemo(
    () => [
      {
        id: "none",
        label: t("ui.tiptap_editor.menu.selection.ai_menu.change_tone.none"),
        type: "option" as EditorMenuOption["type"],
        selected: !data.imageStyle,
        onClick: handleSelectImageStyle(undefined),
      },
      ...EDITOR_AI_IMAGE_OPTIONS.map((tone) => ({
        id: tone.value,
        label: t(tone.label),
        type: "option" as EditorMenuOption["type"],
        selected: tone.value === data.imageStyle,
        onClick: handleSelectImageStyle(tone.value),
      })),
    ],
    [handleSelectImageStyle, data.imageStyle]
  );

  return (
    <NodeViewWrapper>
      <AIGenerateWidget
        prompt={data.text}
        isGenerating={isGenerating}
        isLoading={isUploading}
        type="image"
        setPrompt={handlePromptChange}
        onConfirm={handleConfirm}
        onDiscard={handleDiscard}
        onGenerate={generateImage}
        PreviewElement={!!previewImage && <PreviewImage src={previewImage} />}
        FooterElement={
          <EditorMenuDropdown
            items={imageOptions}
            AnchorElement={
              <Button
                variant="outlined"
                size="small"
                startIcon={<IconImage />}
                sx={{ minWidth: 0 }}
                disabled={isGenerating}
              >
                {currentStyle?.label
                  ? t(currentStyle.label)
                  : t("ui.tiptap_editor.magic_widget.image.image_style")}
              </Button>
            }
          />
        }
      />
    </NodeViewWrapper>
  );
}

const PreviewImage = styled("img")`
  margin: 0;
  width: 100%;
  height: auto;
  border-radius: ${rounding.default};
  overflow: hidden;
`;
