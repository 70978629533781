import * as React from "react";
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";

const IconCircleStop = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    className="shared-icon"
    ref={ref}
    {...props}
  >
    <path
      fill="currentColor"
      d="M20.125 12a8.125 8.125 0 1 0-16.25 0 8.125 8.125 0 0 0 16.25 0M2 12a10 10 0 1 1 20 0 10 10 0 0 1-20 0m7.5-3.75h5c.691 0 1.25.559 1.25 1.25v5c0 .691-.559 1.25-1.25 1.25h-5c-.691 0-1.25-.559-1.25-1.25v-5c0-.691.559-1.25 1.25-1.25"
    />
  </svg>
);
const ForwardRef = forwardRef(IconCircleStop);
export { ForwardRef as IconCircleStop };
