import { useEffect, useState } from "react";

import queryString from "query-string";
import { useParams } from "react-router";
import { useLocation, useSearchParams } from "react-router-dom";

import { getQueryParams } from "#shared/utils/route.utils";
import { BriefsApi } from "#src/apis/briefs/api";

export default function useTrackingCallback() {
  const [trackingURLCallback, setTrackingURLCallback] = useState<string | undefined>();
  const { briefRef } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const tid = getQueryParams(location, "tid");
  const queries = queryString.parse(location.search) as { fingerprintId: string };

  useEffect(() => {
    (async function fetchTrackingUrlCallback() {
      const ref = briefRef || searchParams.get("successRef");
      if (!ref || !queries?.fingerprintId) return;
      const brief = await BriefsApi.fetchBriefDetails({ briefRef: ref });

      if (brief && brief?.trackingURL) {
        if (location.pathname.includes("lets-be-friends")) {
          // failure
          setTrackingURLCallback(`${brief?.trackingFailureURL}${tid}`);
        } else {
          // success
          setTrackingURLCallback(`${brief?.trackingURL}${tid}`);
        }
      }

      // remove query params from url
      searchParams.delete("successRef");
      searchParams.delete("fingerprintId");
      searchParams.delete("tid");
      setSearchParams(searchParams);
    })();
  }, []);

  return {
    trackingURLCallback,
    briefRef,
    fingerprintId: queries.fingerprintId,
  };
}
