import * as React from "react";
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";

const IconStar = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    className="shared-icon"
    ref={ref}
    {...props}
  >
    <path
      fill="currentColor"
      d="M12 1c.384 0 .734.217.9.563l2.86 5.89 6.387.942a.99.99 0 0 1 .804.68c.117.362.02.754-.246 1.02l-4.631 4.594 1.092 6.487c.063.375-.092.754-.4.98a1 1 0 0 1-1.055.07l-5.71-3.051-5.707 3.047a1 1 0 0 1-1.055-.071 1.01 1.01 0 0 1-.404-.98l1.092-6.486-4.631-4.59a1 1 0 0 1-.246-1.02c.116-.36.429-.622.804-.68l6.386-.942 2.86-5.89c.17-.346.517-.563.9-.563m0 3.293-2.188 4.51a1.01 1.01 0 0 1-.755.555l-4.931.725 3.58 3.548c.23.229.338.554.284.875l-.846 4.99 4.385-2.343a1 1 0 0 1 .942 0l4.386 2.343-.842-4.986a.99.99 0 0 1 .283-.875l3.58-3.548-4.93-.73a1 1 0 0 1-.755-.554z"
    />
  </svg>
);
const ForwardRef = forwardRef(IconStar);
export { ForwardRef as IconStar };
