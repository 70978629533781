import * as React from "react";
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";

const IconWhatsapp = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    className="shared-icon"
    ref={ref}
    {...props}
  >
    <path
      fill="currentColor"
      d="M19.005 4.906A9.84 9.84 0 0 0 11.995 2c-5.464 0-9.91 4.446-9.91 9.91 0 1.746.455 3.452 1.321 4.956L2 22l5.254-1.38a9.9 9.9 0 0 0 4.737 1.206h.005c5.46 0 10.004-4.446 10.004-9.91 0-2.648-1.125-5.135-2.995-7.01m-7.01 15.25A8.2 8.2 0 0 1 7.8 19.01L7.5 18.83l-3.116.817.83-3.04-.196-.312a8.2 8.2 0 0 1-1.26-4.384c0-4.54 3.697-8.237 8.242-8.237 2.2 0 4.268.857 5.821 2.415 1.554 1.558 2.51 3.625 2.505 5.826 0 4.545-3.79 8.241-8.33 8.241m4.518-6.17c-.245-.124-1.464-.723-1.692-.803-.227-.085-.392-.125-.558.125-.165.25-.638.804-.785.973-.143.165-.29.188-.536.063-1.455-.728-2.41-1.3-3.37-2.947-.255-.437.254-.406.727-1.352.08-.166.04-.308-.022-.433-.063-.125-.558-1.344-.764-1.84-.2-.482-.406-.415-.558-.424-.143-.009-.308-.009-.473-.009a.92.92 0 0 0-.66.308c-.228.25-.867.849-.867 2.067s.889 2.398 1.01 2.563c.124.165 1.745 2.665 4.231 3.74 1.572.68 2.188.738 2.974.621.477-.071 1.464-.598 1.67-1.178s.205-1.076.142-1.179c-.058-.111-.223-.174-.469-.294"
    />
  </svg>
);
const ForwardRef = forwardRef(IconWhatsapp);
export { ForwardRef as IconWhatsapp };
