import * as React from "react";
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";

const IconFlag = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    className="shared-icon"
    ref={ref}
    {...props}
  >
    <g clipPath="url(#flag_svg__a)">
      <path
        fill="currentColor"
        d="M4.875 2.938A.935.935 0 0 0 3.938 2 .935.935 0 0 0 3 2.938v18.125c0 .519.418.937.938.937.519 0 .937-.418.937-.937V15.75h14.688a.935.935 0 0 0 .75-1.5L16.75 9.5l3.563-4.75a.935.935 0 0 0-.75-1.5H4.874zm0 2.187h12.813l-2.438 3.25c-.5.668-.5 1.582 0 2.25l2.438 3.25H4.874z"
      />
    </g>
    <defs>
      <clipPath id="flag_svg__a">
        <path fill="currentColor" d="M3 2h17.5v20H3z" />
      </clipPath>
    </defs>
  </svg>
);
const ForwardRef = forwardRef(IconFlag);
export { ForwardRef as IconFlag };
