import * as React from "react";
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";

const IconArrowDropDown = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    className="shared-icon"
    ref={ref}
    {...props}
  >
    <path fill="currentColor" d="m12 15-5-5h10z" />
  </svg>
);
const ForwardRef = forwardRef(IconArrowDropDown);
export { ForwardRef as IconArrowDropDown };
