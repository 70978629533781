import { client } from "#src/apis/axios";
import { WLA_HEADER } from "#src/constants/common";
import env from "#src/env";
import { APIResponse } from "../../types/types";
import getFingerprint from "../../utils/getFingerprint";

type TokenByEmailBody = {
  args: {
    email: string;
    redirect: string;
    component?: string;
    device_type: number;
    device_unique_id: string;
    device_name: string | undefined;
    device_os: string | undefined;
    magicLinkParams: Record<string, any>;
  };
};

export interface MagicEmailLinkParams {
  email: string;
  redirectPath?: string;
  component?: string;
  magicLinkParams: Record<string, any>;
}

async function sendMagicLinkByEmail({
  email,
  redirectPath = "/",
  component,
  magicLinkParams = {},
}: MagicEmailLinkParams) {
  const fingerprintId = magicLinkParams.fingerprintId || (await getFingerprint());

  return await client.post<TokenByEmailBody, APIResponse<any>>(
    `${env.VITE_VUE_APP_BS_API_BASE_URL}/email_connect`,
    {
      args: {
        email,
        redirect: `${env.VITE_BASE_URL_UI}${redirectPath}`,
        component,
        device_type: 3,
        device_unique_id: fingerprintId,
        device_name: navigator?.userAgent,
        device_os: navigator?.platform,
        magicLinkParams: {
          ...magicLinkParams,
          fingerprintId,
          email,
        },
      },
    }
  );
}

export default sendMagicLinkByEmail;
