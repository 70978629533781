import { useMemo } from "react";

import useSelectedChannel from "#src/components/pages/ChannelDetails/useSelectedChannel";
import { getChannelUrl } from "#src/components/routing/utils";
import { getShareUrl } from "#src/utils/share";
import useShare from "#src/utils/useShare";

export default function useShareChannel() {
  const { data: channel } = useSelectedChannel();

  const data = useMemo(
    () => ({
      title: channel?.isVerified
        ? "Hop on board, share your insights, and snag sweet perks for helping shape the future of products and services!"
        : "Come join our fun squad! We’re all about sharing opinions and seeing where our collective thoughts can take us. Can't wait for you to join.",
      url: getShareUrl(`/invite/${channel?.publicInviteToken}`),
      redirectUrl: getShareUrl(getChannelUrl(channel?.channelRef)),
    }),
    [channel]
  );

  return useShare(data);
}
