import React, { ReactElement } from "react";

import { PopperPlacementType } from "@mui/base";
import { styled } from "@mui/material/styles";

import CustomPopper from "#shared/components/ui/Popper";
// constants
import colors from "#shared/config/theme/colors";
import effects from "#shared/config/theme/effects";
import rounding from "#shared/config/theme/rounding";
import useMenuDropdown from "#src/components/common/Dropdown/useMenuDropdown";
import EditorMenuList from "#src/components/common/Editor/components/EditorMenuList";
import { EditorMenuItems } from "#src/components/common/Editor/types";

interface Props {
  items?: EditorMenuItems;
  selectedItemId?: string;
  AnchorElement: ReactElement;
  placement?: PopperPlacementType;
  children?: ReactElement;
  onMenuOpen?: () => void;
  onMenuClose?: () => void;
  onItemClick?: (id: string) => void;
}

export default function EditorMenuDropdown({
  items,
  selectedItemId,
  AnchorElement,
  placement = "bottom-end",
  children,
  onMenuOpen,
  onMenuClose,
  onItemClick,
}: Props) {
  const { anchorRef, open, openMenu, closeMenu, onClickMenuItem } = useMenuDropdown({
    items: items || [],
    onMenuOpen,
    onMenuClose,
    onItemClick,
  });

  if (!AnchorElement) {
    return null;
  }

  return (
    <>
      <div ref={anchorRef} onClick={openMenu}>
        {AnchorElement}
      </div>

      <CustomPopper
        anchorEl={anchorRef.current}
        open={open}
        hideArrow
        onClickAway={closeMenu}
        placement={placement}
        modifiers={[
          {
            name: "offset",
            options: {
              offset: [5, 0],
            },
          },
        ]}
        sx={{
          zIndex: "99999 !important",
          ".popper-wrapper": { boxShadow: "none", border: "none" },
        }}
      >
        {items?.length ? (
          <EditorMenuList
            items={items}
            selectedItemId={selectedItemId}
            onClickMenuItem={onClickMenuItem}
          />
        ) : (
          children
        )}
      </CustomPopper>
    </>
  );
}

export const EditorListWrapper = styled("div")`
  background: ${colors.base.white};
  box-shadow: ${effects.shadow.base};
  border-radius: ${rounding.default};
  gap: 0.125rem;
  padding: 0.25rem;
  display: flex;
  flex-direction: column;
  max-height: 320px;
  overflow: auto;

  ${({ theme }) => theme.breakpoints.down("sm")} {
    max-height: 240px;
  }
`;
