import * as React from "react";
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";

const IconListBullet = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    className="shared-icon"
    ref={ref}
    {...props}
  >
    <path
      fill="currentColor"
      d="M4.742 7.484a1.742 1.742 0 1 0 0-3.484 1.742 1.742 0 0 0 0 3.484M9.387 4.58a1.16 1.16 0 1 0 0 2.323H19.84a1.16 1.16 0 1 0 0-2.323zm0 5.806a1.16 1.16 0 1 0 0 2.323H19.84a1.16 1.16 0 1 0 0-2.323zm0 5.807a1.16 1.16 0 1 0 0 2.322H19.84a1.16 1.16 0 1 0 0-2.323zm-4.645 2.903a1.742 1.742 0 1 0 0-3.484 1.742 1.742 0 0 0 0 3.484m1.742-7.549a1.742 1.742 0 1 0-3.483 0 1.742 1.742 0 0 0 3.483 0"
    />
  </svg>
);
const ForwardRef = forwardRef(IconListBullet);
export { ForwardRef as IconListBullet };
