import { SegmentUserProperty } from "#shared/types/segment";
import { BuilderAudienceFormFields } from "#src/components/pages/BriefBuilder/Audience/hooks/useBuilderAudienceForm";
import { BuilderUserPropertyFormType } from "#src/components/pages/BriefBuilder/Audience/types";
import { BriefAudience, SaveBriefAudienceRequest } from "#src/types/briefBuilder";

export const BRIEF_AUDIENCE_AGE_MIN = 13;
export const BRIEF_AUDIENCE_AGE_MAX = 100;

export const mapBriefBuilderAudienceFormFields = (
  audience?: BriefAudience,
  isSuperAdmin?: boolean
): BuilderAudienceFormFields => {
  return {
    country: audience?.countries || [],
    gender: audience?.genders || [],
    ageMin: audience?.age.min || BRIEF_AUDIENCE_AGE_MIN,
    ageMax: audience?.age.max || BRIEF_AUDIENCE_AGE_MAX,
    conditional: audience?.behavioural.conditional === "or",
    userProperties: mapBuilderUserPropertiesFormType(
      audience?.behavioural.properties || [],
      isSuperAdmin
    ),
    autoTranslateLanguageId: audience?.responses.autoTranslateLanguageId || 0,
    requiredResponses: audience?.responses.requiredResponses || null,
    responsesPerParticipant:
      audience?.responses.responsesPerParticipant !== null
        ? audience?.responses.responsesPerParticipant ?? 1
        : null,
  };
};

export const mapBriefBuilderAudienceRequest = (
  briefRef: string,
  form: BuilderAudienceFormFields
): SaveBriefAudienceRequest => {
  return {
    briefRef,
    genders: form.gender,
    countries: form.country,
    age: {
      min: form.ageMin,
      max: form.ageMax,
    },
    behavioural: {
      conditional: form.conditional ? "or" : "and",
      properties: mapBuilderUserProperties(form.userProperties),
    },
    responses: {
      autoTranslateLanguageId:
        form.autoTranslateLanguageId !== 0 ? form.autoTranslateLanguageId : null,
      requiredResponses: form.requiredResponses !== null ? form.requiredResponses : null,
      responsesPerParticipant:
        form.responsesPerParticipant !== 0 ? form.responsesPerParticipant : null,
    },
  };
};

const mapBuilderUserPropertiesFormType = (
  properties: SegmentUserProperty[],
  isSuperAdmin?: boolean
): BuilderUserPropertyFormType[] => {
  return properties.map((property) => ({
    id: property.typeId,
    name: property.typePropName || "",
    condition: property.condition,
    values: property.values || [],
    disabled: property.typeIsInternal && !isSuperAdmin,
  }));
};

const mapBuilderUserProperties = (
  properties: BuilderUserPropertyFormType[]
): SegmentUserProperty[] => {
  return properties
    .filter((p) => !!p.id)
    .map((property) => ({
      condition: property.condition || "matches",
      typeId: property.id,
      values: property.values,
      typeIsInternal: property.disabled,
      typePropName: property.name,
    }));
};
