import * as React from "react";
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";

const IconCopy = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    className="shared-icon"
    ref={ref}
    {...props}
  >
    <path
      fill="currentColor"
      d="M18.25 15.125h-7.5a.627.627 0 0 1-.625-.625v-10c0-.344.281-.625.625-.625h5.473l2.652 2.652V14.5a.627.627 0 0 1-.625.625M10.75 17h7.5c1.379 0 2.5-1.121 2.5-2.5V6.527c0-.496-.2-.972-.55-1.324l-2.65-2.652A1.88 1.88 0 0 0 16.228 2H10.75a2.5 2.5 0 0 0-2.5 2.5v10c0 1.379 1.121 2.5 2.5 2.5m-5-10a2.5 2.5 0 0 0-2.5 2.5v10c0 1.379 1.121 2.5 2.5 2.5h7.5c1.379 0 2.5-1.121 2.5-2.5v-1.25h-1.875v1.25a.627.627 0 0 1-.625.625h-7.5a.627.627 0 0 1-.625-.625v-10c0-.344.281-.625.625-.625H7V7z"
    />
  </svg>
);
const ForwardRef = forwardRef(IconCopy);
export { ForwardRef as IconCopy };
