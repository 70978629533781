import React from "react";

import { styled } from "@mui/material/styles";

import { IconComment1 } from "#shared/icons/Comment1";
import { IconCompass } from "#shared/icons/Compass";
import ProfileMenu from "#src/components/common/layout/Header/ProfileMenu";
import MobileProfileMenu from "#src/components/common/layout/Header/SideMenu/MobileProfileMenu";

const Wrapper = styled("div")(
  ({ theme }) => `
       & .desktop {
        display: flex;
        align-items: center;   
        ${theme.breakpoints.down("md")} {
          display:none !important;
        }
       }
       
       & .tablet {
        display: flex;
        align-items: center;  
          ${theme.breakpoints.up("md")} {
            display: none;
          }
          
          & .getTheApp {
          margin-right: 1.25rem;
       }
  `
);

type ProfileIconsProps = {
  onClickIcon: () => void;
};

export default function ProfileIcons({ onClickIcon }: ProfileIconsProps) {
  return (
    <Wrapper>
      <div className="desktop">
        <ProfileIconButton onClick={onClickIcon}>
          <IconCompass width={25} height={25} style={{ marginTop: "1px" }} />
        </ProfileIconButton>
        <ProfileIconButton onClick={onClickIcon}>
          <IconComment1
            width={21}
            height={21}
            style={{ marginTop: "3px", marginRight: "3px" }}
          />
        </ProfileIconButton>
        <ProfileMenu />
      </div>
      <div className="tablet">
        <MobileProfileMenu />
      </div>
    </Wrapper>
  );
}

const ProfileIconButton = styled("button")`
  width: 26px;
  height: 26px;
  margin-right: 22px;
`;
