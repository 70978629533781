import FingerprintJS from "@fingerprintjs/fingerprintjs-pro";

import env from "#src/env";

const { VITE_FINGERPRINT_API_KEY } = env;

export default async function getFingerprint() {
  const agent = await FingerprintJS.load({
    apiKey: "BWWN47cyd4YiqjgNkrc5",
    region: "eu",
    endpoint: "https://fp.bulbshare.com",
    scriptUrlPattern: `https://fp.bulbshare.com/web/v3/${VITE_FINGERPRINT_API_KEY}/loader_v3.11.1.js`,
  });

  const { visitorId } = await agent.get();

  return visitorId;
}
