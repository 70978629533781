import * as React from "react";
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";

const IconStore = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    className="shared-icon"
    ref={ref}
    {...props}
  >
    <path
      fill="currentColor"
      d="M20.056 7.761c.213.338.236.754.085 1.107-.143.338-.408.544-.74.588a1.484 1.484 0 0 1-1.305-.47 1.767 1.767 0 0 0-2.613.003c-.269.298-.651.485-1.088.485-.434 0-.82-.187-1.089-.485a1.76 1.76 0 0 0-1.309-.58c-.5 0-.974.209-1.308.58-.269.298-.651.485-1.089.485-.433 0-.82-.187-1.088-.485a1.76 1.76 0 0 0-1.309-.58c-.5 0-.974.209-1.309.58a1.45 1.45 0 0 1-1.301.47c-.327-.044-.588-.25-.732-.588a1.2 1.2 0 0 1 .085-1.106l1.893-3h12.324zm-.867 3.474q.225 0 .44-.03c2.041-.271 3.008-2.665 1.916-4.389L19.44 3.482A1.04 1.04 0 0 0 18.56 3H5.442c-.356 0-.69.184-.878.482l-2.11 3.334c-1.089 1.72-.126 4.114 1.907 4.39q.223.028.445.03A3.2 3.2 0 0 0 7.2 10.172q.22.244.485.437c.533.39 1.191.625 1.912.625a3.23 3.23 0 0 0 2.397-1.066 3.22 3.22 0 0 0 2.397 1.063 3.2 3.2 0 0 0 1.923-.636q.258-.193.474-.43.216.239.478.433a3.22 3.22 0 0 0 1.919.633zM5.53 15.353v-3a4 4 0 0 1-.721.059c-.202 0-.405-.015-.6-.04h-.003a4 4 0 0 1-.441-.085v7.184a2.355 2.355 0 0 0 2.353 2.353h11.765a2.355 2.355 0 0 0 2.352-2.353v-7.184a4 4 0 0 1-.452.084h-.003a5 5 0 0 1-.596.04q-.365.001-.713-.058v3zm12.94 1.765v2.353a.59.59 0 0 1-.587.588H6.119a.59.59 0 0 1-.588-.588v-2.353z"
    />
  </svg>
);
const ForwardRef = forwardRef(IconStore);
export { ForwardRef as IconStore };
