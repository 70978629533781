import React, { useEffect, useMemo } from "react";

import { matchPath, useLocation, useNavigate } from "react-router";

import ChannelDetailsHeader from "#src/components/pages/ChannelDetails/ChannelHeader";
import ChannelHeaderSkeleton from "#src/components/pages/ChannelDetails/ChannelHeader/components/ChannelHeaderSkeleton";
import { getChannelUrl } from "#src/components/routing/utils";
import { ChannelDetails as ChannelDetailsProps } from "#src/types/channel";
import { GaEvents, gaTrackEvent } from "#src/utils/useGA";
import ChannelTabs, { ChannelTabsEnum } from "./ChannelTabs";
import { ChannelDetailsWrapper } from "./styled";

interface Props {
  channel?: ChannelDetailsProps;
  isLoading: boolean;
}

const ChannelDetails = ({ channel, isLoading }: Props) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const selectedTab = useMemo(() => {
    if (matchPath("/channel/:channelRef/settings", pathname)) {
      return ChannelTabsEnum.settings;
    }

    if (matchPath("/channel/:channelRef/community", pathname)) {
      return ChannelTabsEnum.community;
    }

    return ChannelTabsEnum.briefs;
  }, [pathname]);

  const handleTabChange = (newTab: ChannelTabsEnum) => {
    const channelUrl = getChannelUrl(channel?.channelRef);

    switch (newTab) {
      case ChannelTabsEnum.community:
        navigate(`${channelUrl}/community`);
        break;
      case ChannelTabsEnum.settings:
        navigate(`${channelUrl}/settings`);
        break;
      default:
        navigate(channelUrl);
        break;
    }
  };

  useEffect(() => {
    if (channel?.id) {
      gaTrackEvent(GaEvents.openbrandprofile, "selected brand id", channel.id.toString());
    }
  }, [channel]);

  if (!channel || isLoading) {
    return <ChannelHeaderSkeleton />;
  }

  return (
    <ChannelDetailsWrapper>
      <ChannelDetailsHeader />
      <ChannelTabs
        channel={channel}
        selectedTab={selectedTab}
        onTabChange={handleTabChange}
      />
    </ChannelDetailsWrapper>
  );
};

export default ChannelDetails;
