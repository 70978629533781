import React from "react";

import { Divider } from "@mui/material";

import { useMediaQueryProvider } from "#shared/context/MediaQueryProvider";
import { IconExit } from "#shared/icons/Exit";
import { WrapperFilter } from "#src/components/pages/Feed/ChannelsSidebar/commonStyled";
import { NavigationAction } from "#src/constants/navigation";
import { useBriefBuilderNavigate } from "#src/containers/BriefBuilder/BriefBuilderNavigateProvider";
import BuilderDesktopSteps from "#src/containers/BriefBuilder/Header/Steps/components/BuilderDesktopSteps";
import BuilderMobileSteps from "#src/containers/BriefBuilder/Header/Steps/components/BuilderMobileSteps";
import useBriefBuilderSteps from "#src/containers/BriefBuilder/hooks/useBriefBuilderSteps";
import NavigationItem from "#src/containers/layout/Navigation/components/NavigationItem";
import { BriefBuilderStep } from "#src/types/briefBuilder";
import useTranslation from "#src/utils/useTranslation";

interface Props {
  onDiscard: () => void;
}

export default function BriefBuilderSteps({ onDiscard }: Props) {
  const { t } = useTranslation();
  const { isTabletOrMobile } = useMediaQueryProvider();

  const { getStepUrl } = useBriefBuilderSteps();
  const { builderNavigate } = useBriefBuilderNavigate();

  const handleNavigateToStep = (step: BriefBuilderStep) => {
    const url = getStepUrl(step);

    if (!url) {
      throw new Error(`No URL found for step ${step}`);
    }

    builderNavigate(url);
  };

  if (isTabletOrMobile) {
    return (
      <BuilderMobileSteps onStepClick={handleNavigateToStep}>
        <Divider sx={{ my: 4 }} />
        <WrapperFilter>
          <NavigationItem
            item={{
              id: NavigationAction.exit_builder,
              name: t("pages.brief_builder.exit_builder"),
              LogoActive: () => <IconExit />,
              LogoInactive: () => <IconExit />,
            }}
            handleNavigationItemClick={onDiscard}
          />
        </WrapperFilter>
      </BuilderMobileSteps>
    );
  }

  return <BuilderDesktopSteps onStepClick={handleNavigateToStep} />;
}
