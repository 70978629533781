import { useEffect } from "react";

import queryString from "query-string";
import { useSearchParams } from "react-router-dom";

import sendMagicLinkByEmail from "#src/apis/email/sendMagicLinkByEmail";
import useEmailForm from "#src/components/pages/Login/Authentication/EmailLogin/useEmailForm";
import { buildURLFromRedirectQuery } from "#src/components/routing/utils";
import { getUrlSearchParams } from "../../../../../helper";
import { EmailLoginProps } from "../../../../../types/components-props";

export const buildMagicLinkArgsFromSearchParams = (
  searchParams: URLSearchParams,
  briefRef?: string
) => {
  const urlSearchParams = getUrlSearchParams(searchParams);
  const redirectURL = buildURLFromRedirectQuery(searchParams.get("redirect")) || "/";

  const { redirect, autoSend, component, ...restParams } = urlSearchParams;

  const redirectPath = redirectURL.split("?")[0];

  return {
    redirectPath,
    component,
    magicLinkParams: {
      briefRef,
      ...restParams,
      ...queryString.parse(redirectURL.split("?")[1]),
      redirect: redirectURL.split("?redirect=")[1],
    },
  };
};

export default function useSendEmail(): EmailLoginProps {
  const [searchParams] = useSearchParams();
  const urlSearchParams = getUrlSearchParams(searchParams);

  const { autoSend } = urlSearchParams;

  const onEmailSubmit = async (email: string) =>
    sendMagicLinkByEmail({
      email,
      ...buildMagicLinkArgsFromSearchParams(searchParams),
    });

  const emailForm = useEmailForm({
    defaultEmail: searchParams.get("email") || "",
    onSubmit: onEmailSubmit,
  });
  const { onSendingEmail } = emailForm;

  // automatically send email
  useEffect(() => {
    if (autoSend) {
      (async function submit() {
        await onSendingEmail();
      })();
    }
  }, []);

  return {
    useEmailForm: emailForm,
    defaultEmail: searchParams.get("email"),
    autoSend,
  };
}
