import { BuilderNotificationStatus } from "#src/types/briefBuilder";

export enum BuilderNotificationChannel {
  Email = "email",
  Push = "push",
}

export interface BuilderNotificationFormType {
  id: number;
  channels: BuilderNotificationChannel[];
  status: BuilderNotificationStatus;
  added: boolean;
  pushContent: string;
  emailSubject: string;
  emailBody: string;
  launchEnabled: boolean;
  scheduledOn: string;
  sentOn: string;
  skipUsersResponded: boolean;
}

export interface BuilderNotificationsFormErrors {
  hasErrors: boolean;
  [index: number]: BuilderNotificationFormErrors;
}

export type BuilderNotificationFormErrors = {
  scheduledOn?: string;
  channels?: string;
};
