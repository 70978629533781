import * as React from "react";
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";

const IconImage = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    className="shared-icon"
    ref={ref}
    {...props}
  >
    <path
      fill="currentColor"
      d="M18.75 5.688c.31 0 .563.253.563.562v11.243l-.176-.228-4.782-6.188a.846.846 0 0 0-1.336 0l-2.917 3.776-1.073-1.501A.84.84 0 0 0 8.344 13a.83.83 0 0 0-.686.355l-2.812 3.938-.159.218V6.25c0-.31.254-.562.563-.562zM5.25 4A2.25 2.25 0 0 0 3 6.25V17.5a2.25 2.25 0 0 0 2.25 2.25h13.5A2.25 2.25 0 0 0 21 17.5V6.25A2.25 2.25 0 0 0 18.75 4zm2.813 6.75a1.686 1.686 0 0 0 1.559-2.333 1.688 1.688 0 1 0-1.56 2.333"
    />
  </svg>
);
const ForwardRef = forwardRef(IconImage);
export { ForwardRef as IconImage };
