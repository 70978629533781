import React from "react";

import { Box, Button, Chip, Typography, styled } from "@mui/material";
import { FormattedMessage } from "react-intl";

import { InfoButton } from "#shared/components/ui";
import colors from "#shared/config/theme/colors";
import rounding from "#shared/config/theme/rounding";
import { IconBadgeCheck } from "#shared/icons/BadgeCheck";
import Avatar from "#src/components/common/Avatar";
import ChannelBackAction from "#src/components/pages/ChannelDetails/ChannelHeader/components/ChannelBackAction";
import ChannelMoreActions from "#src/components/pages/ChannelDetails/ChannelHeader/components/ChannelMoreActions";
import ChannelUserActions from "#src/components/pages/ChannelDetails/ChannelHeader/components/ChannelUserActions";
import {
  ChannelActions,
  ChannelBannerImage,
  ChannelBannerWrapper,
  ChannelContentWrapper,
  ChannelLogoWrapper,
} from "#src/components/pages/ChannelDetails/ChannelHeader/styled";
import { ChannelDetails, ChannelTokenResponse } from "#src/types/channel";
import { getIsPublicChannel } from "#src/utils/channel";
import { nFormatter } from "#src/utils/index";
import useTranslation from "#src/utils/useTranslation";
import { IMAGE_TYPES } from "../../../../../types/types";
import getResourcesURL from "../../../../../utils/getResourcesURL";

interface Props {
  channel: ChannelDetails | ChannelTokenResponse;
  isPublic?: boolean;
  isInvite?: boolean;
  editable?: boolean;
  setEditMode?: (value: boolean) => void;
}

export default function ChannelHeaderContent({
  channel,
  isPublic,
  isInvite,
  editable,
  setEditMode,
}: Props) {
  const { t } = useTranslation();

  const isPrivateView = !isPublic && "isDiscoverable" in channel;
  const isPublicChannel = isPrivateView ? getIsPublicChannel(channel) : false;

  const logoSrc = getResourcesURL(channel?.logo, IMAGE_TYPES.CHANNEL_LOGO);
  const bannerSrc = getResourcesURL(
    channel?.channelsThemes.webBanner,
    IMAGE_TYPES.CHANNEL_BANNER
  );

  const handleEditProfile = () => {
    setEditMode?.(true);
  };

  return (
    <Box className="channel-header">
      <ChannelBannerWrapper
        key={bannerSrc}
        className="channel-banner"
        placeholder={!bannerSrc}
      >
        {bannerSrc && <ChannelBannerImage src={bannerSrc} alt={`Channel banner`} />}
        <ChannelBackAction />
        {isPrivateView && !editable && <ChannelMoreActions />}
      </ChannelBannerWrapper>
      <ChannelLogoWrapper className="channel-logo" placeholder={!channel.logo}>
        <Avatar uri={logoSrc} diameter={3.8} />
      </ChannelLogoWrapper>
      <ChannelContentWrapper className="channel-content" sx={{ pt: 12 }}>
        {isPrivateView && (
          <ChannelActions>
            <ChannelUserActions channel={channel} />
            {editable && (
              <Button variant="cta" color="primary" onClick={handleEditProfile}>
                <FormattedMessage id="ui.channel_header.actions.edit_profile" />
              </Button>
            )}
          </ChannelActions>
        )}

        {isInvite && (
          <Chip
            variant="filled"
            color="secondary"
            label={t("ui.channel_header.invited_to")}
            sx={{
              width: "fit-content",
              m: "0 auto",
              mb: 4,
              borderRadius: rounding["2xl"],
            }}
          />
        )}

        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          className="channel-name"
          gap={2}
        >
          <Typography variant="h4" fontWeight="520">
            {channel.name}
            {channel.isVerified && (
              <InfoButton
                title={t("ui.channel_header.tooltips.channel_verified")}
                invisible
                sx={{ display: "inline-block", ml: 2, mt: -0.5 }}
              >
                <VerifiedIcon />
              </InfoButton>
            )}
          </Typography>
        </Box>
        <Box display="flex" flexDirection="row" gap={5} className="channel-stats" mt={1}>
          <Typography variant="body4" color={colors.text.secondaryDark}>
            <Typography variant="inherit" component="span" color={colors.base.black}>
              {nFormatter(channel.briefCount) || 0}{" "}
            </Typography>
            <FormattedMessage
              id={`pages.feed.channel.brief${channel.briefCount === 1 ? "" : "s"}`}
            />
          </Typography>
          {!isPublicChannel && (
            <Typography variant="body4" color={colors.text.secondaryDark}>
              <Typography variant="inherit" component="span" color={colors.base.black}>
                {nFormatter(channel.membersCount) || 0}{" "}
              </Typography>
              <FormattedMessage
                id={`pages.feed.channel.member${channel.membersCount === 1 ? "" : "s"}`}
              />
            </Typography>
          )}
        </Box>

        <Typography
          variant="body4"
          color={colors.text.secondaryDark}
          className="channel-description"
          mt={2}
        >
          {channel.description &&
            channel.description.split("\n").map((line, index) => (
              <React.Fragment key={index}>
                {line}
                <br />
              </React.Fragment>
            ))}
        </Typography>
      </ChannelContentWrapper>
    </Box>
  );
}

const VerifiedIcon = styled(IconBadgeCheck)`
  width: 1.125rem;
  height: 1.125rem;
  color: ${({ theme }) => theme.palette.primary.main};
  flex-shrink: 0;
`;
