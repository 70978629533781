import * as React from "react";
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";

const IconPaintbrushPencil = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    className="shared-icon"
    ref={ref}
    {...props}
  >
    <path
      fill="currentColor"
      d="M8.325 3.654a2.23 2.23 0 0 0-3.157 0L3.79 5.03a2.234 2.234 0 0 0 0 3.157l3.098 3.098q.73-.212 1.532-.213h.052l.286-.286-1.14-1.14L9.78 7.48l1.145 1.145 1.182-1.183zm8.126 10.495 1.301 1.301q.032.032.06.063c.136.153.233.335.293.53l.24.817.562 1.912-1.912-.562-.816-.241a1.4 1.4 0 0 1-.593-.352l-1.302-1.302-.282.283v.06a5.6 5.6 0 0 1-.213 1.53l.614.615q.066.068.14.133c.335.296.732.516 1.165.641l2.725.803 1.472.429c.293.087.61.007.827-.213a.85.85 0 0 0 .213-.827l-.433-1.472-.802-2.725a3.1 3.1 0 0 0-.775-1.305l-1.301-1.301zm3.656-9.18c.29.29.29.761 0 1.05l-6.374 6.375-1.05-1.05 6.378-6.375c.29-.29.76-.29 1.05 0zM8.42 14.145c.327 0 .638.063.924.175l1.413 1.413A2.51 2.51 0 0 1 8.42 19.17H5.434l.024-.032a2.94 2.94 0 0 0 .482-2.094 2.51 2.51 0 0 1 2.484-2.9zm9.454-10.362L9.128 12.53a4.185 4.185 0 0 0-4.846 4.77c.094.62-.325 1.311-.956 1.311h-.21a1.115 1.115 0 1 0 0 2.233H8.42a4.186 4.186 0 0 0 4.127-4.895l8.747-8.747a2.417 2.417 0 0 0 0-3.415 2.417 2.417 0 0 0-3.416 0z"
    />
  </svg>
);
const ForwardRef = forwardRef(IconPaintbrushPencil);
export { ForwardRef as IconPaintbrushPencil };
