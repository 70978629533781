import * as React from "react";
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";

const IconMicrophone = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    className="shared-icon"
    ref={ref}
    {...props}
  >
    <path
      fill="currentColor"
      d="M13.75 5.75V12A1.875 1.875 0 0 1 10 12V5.75a1.875 1.875 0 0 1 3.75 0m-5.625 0V12a3.751 3.751 0 0 0 7.5 0V5.75a3.751 3.751 0 0 0-7.5 0m-1.25 4.688a.935.935 0 0 0-.937-.938.935.935 0 0 0-.938.938V12a6.88 6.88 0 0 0 5.938 6.813v1.312H9.062a.935.935 0 0 0-.937.938c0 .519.418.937.938.937h5.625c.519 0 .937-.418.937-.937a.935.935 0 0 0-.937-.938h-1.876v-1.312A6.88 6.88 0 0 0 18.75 12v-1.562a.935.935 0 0 0-.937-.938.935.935 0 0 0-.938.938V12a5 5 0 1 1-10 0z"
    />
  </svg>
);
const ForwardRef = forwardRef(IconMicrophone);
export { ForwardRef as IconMicrophone };
