import * as React from "react";
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";

const IconHeading1 = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    className="shared-icon"
    ref={ref}
    {...props}
  >
    <path
      fill="currentColor"
      d="M4.222 6.136V5H2v13.636h2.222v-5.682h6.667v5.682h2.222V5H10.89v5.682H4.222zm11.84 2.174 1.494-.679v8.733h-2.222v2.272H22v-2.272h-2.222V5h-1.903l-.215.1-2.327 1.054V8.64z"
    />
  </svg>
);
const ForwardRef = forwardRef(IconHeading1);
export { ForwardRef as IconHeading1 };
