import React, { useEffect, useState } from "react";

import { Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import colors from "#shared/config/theme/colors";
import usePrevious from "#shared/utils/hooks/usePrevious";
import useNavigateToChannel from "#src/components/pages/Feed/CardFeed/useNavigateToChannel";
import ChannelsSkeleton from "#src/components/pages/Feed/ChannelsSidebar/DesktopChannels/ChannelsSkeleton";
import FeedChannelList from "#src/components/pages/Feed/ChannelsSidebar/DesktopChannels/FeedChannelList";
import SeeAllChannels from "#src/components/pages/Feed/ChannelsSidebar/DesktopChannels/SeeAllChannels";
import useMyFollowedChannels from "#src/components/pages/Feed/ChannelsSidebar/useMyFollowedChannels";
import useGodView from "#src/containers/GodView/useGodView";
import {
  selectNavigation,
  setFollowedChannelsLimit,
} from "#src/features/navigation/navigationSlice";
import { selectUser } from "#src/features/user/userSlice";
import {
  gaUserChannel,
  gaUserOrg,
  gaUserId,
  gaUserRole,
  gaUserWLA,
  gaUserWlaProperties,
} from "#src/utils/useGA";
import useWlaOrganisation from "#src/utils/useWlaOrganisation";
import { useSelectorTyped } from "../../../../../utils";

interface Props {
  onNavigationItemClick?: () => void;
}

export default function PrivateChannels({ onNavigationItemClick }: Props) {
  const dispatch = useDispatch();
  const navigateToChannel = useNavigateToChannel();
  const user = useSelectorTyped(selectUser);

  const { isEnabled: isGodViewEnabled } = useGodView();
  const { organisation } = useWlaOrganisation();

  const { followedChannelsLimit } = useSelector(selectNavigation);

  const {
    channels,
    totalItems,
    isLoading,
    isFetchingNextPage,
    hasNextPage,
    loadNextPage,
  } = useMyFollowedChannels();

  const [isLoadingNextPageState, setIsLoadingNextPageState] = useState(false);
  const prevChannels = usePrevious(channels.length);

  useEffect(() => {
    if (!isLoadingNextPageState) {
      return;
    }

    if (prevChannels && prevChannels < channels.length) {
      setLimit(channels.length);
      setIsLoadingNextPageState(false);
    }
  }, [channels.length]);

  const handleNavigateToChannel = (channelRef: string) => {
    onNavigationItemClick?.();
    navigateToChannel(channelRef);
  };

  const handleLoadNextPage = async () => {
    setIsLoadingNextPageState(true);
    loadNextPage();
  };

  useEffect(() => {
    if (channels.length) {
      channels.forEach((channel) => {
        gaUserChannel(channel.name, channel.id, channel.organisationId);

        if (channel.organisationName) {
          gaUserOrg(channel.organisationName, channel.organisationId);
        }
      });
      if (typeof user.profile.userRef === "string") {
        gaUserId(user.profile.userRef);
      }
      gaUserRole(user?.profile?.roleName || "User");
    }
  }, [channels]);

  useEffect(() => {
    if (organisation && organisation.isWla) {
      gaUserWLA(organisation.name, organisation.organisationId);
      gaUserWlaProperties(organisation.name, organisation.organisationId);
    } else {
      gaUserWLA("Bulbshare", 0);
      gaUserWlaProperties("Bulbshare", 0);
    }
  }, [organisation]);

  const setLimit = (val: number) => {
    dispatch(setFollowedChannelsLimit(val));
  };

  const title = (
    <Typography
      variant="label3"
      fontFamily="Avenir-heavy"
      color={colors.text.secondaryDark}
      mb={4}
    >
      <FormattedMessage id="pages.feed.filters.channels_you_follow" />
    </Typography>
  );

  if (isLoading) {
    return (
      <>
        {title}
        <ChannelsSkeleton />
      </>
    );
  }

  if (isGodViewEnabled || !channels.length) {
    return null;
  }

  return (
    <>
      {title}

      <div key={`filters_feed_private`} className="private">
        <FeedChannelList
          onClickChannel={handleNavigateToChannel}
          channels={channels.slice(0, followedChannelsLimit)}
        />
      </div>

      <SeeAllChannels
        visible={followedChannelsLimit}
        total={totalItems}
        isLoading={isFetchingNextPage}
        hasNextPage={hasNextPage}
        loadNextPage={handleLoadNextPage}
        setLimit={setLimit}
      />
    </>
  );
}
