import * as React from "react";
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";

const IconStoreSolid = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    className="shared-icon"
    ref={ref}
    {...props}
  >
    <path
      fill="currentColor"
      d="m21.542 6.816-2.107-3.334A1.04 1.04 0 0 0 18.558 3H5.442c-.357 0-.691.184-.879.482l-2.11 3.334c-1.088 1.72-.125 4.113 1.908 4.388q.223.03.445.03c.96 0 1.812-.42 2.396-1.066A3.22 3.22 0 0 0 9.6 11.234c.96 0 1.812-.42 2.397-1.066a3.22 3.22 0 0 0 2.396 1.066c.963 0 1.812-.42 2.397-1.066a3.23 3.23 0 0 0 2.841 1.036c2.04-.272 3.007-2.665 1.915-4.389zM19.78 12.37h-.004a4.442 4.442 0 0 1-1.897-.154v4.9H6.119v-4.904a4.4 4.4 0 0 1-1.908.158h-.003a4 4 0 0 1-.442-.085v7.183a2.355 2.355 0 0 0 2.353 2.352h11.763a2.355 2.355 0 0 0 2.352-2.352v-7.183a4 4 0 0 1-.452.085"
    />
  </svg>
);
const ForwardRef = forwardRef(IconStoreSolid);
export { ForwardRef as IconStoreSolid };
