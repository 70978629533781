// modules
import React, { useState } from "react";

import { FormHelperText } from "@mui/material";
import dayjs from "dayjs";
import { FormattedMessage, useIntl } from "react-intl";

import { FormField, FormFields, Select } from "#shared/components/ui";
import { AutoComplete } from "#shared/components/ui/Autocomplete";
import { Button } from "#shared/components/ui/Button";
import { DatePicker } from "#shared/components/ui/DatePicker";
import { TextField } from "#shared/components/ui/TextField";
import { SelectOption } from "#shared/types";
import { buildCountryItems } from "#src/components/pages/ScreenerSuccess/SubmissionProfileForm/utils";
import { ERROR_CODES, ERROR_MESSAGES } from "#src/constants/common";
import { demographics } from "../../../../locales/en.json";
import { ComposedHookSubmissionProfileForm } from "../../../../types/hooks";
import { validateEmail } from "../../../../utils";
import { InputsGroup, SubmissionForm, WhiteCircularProgress } from "./styles";

enum SubmissionFormFields {
  age = "age",
  email = "email",
  name = "name",
  country = "country",
  gender = "gender",
}
type FormErrorsMessage = {
  [key in SubmissionFormFields]?: string;
};

export default function SubmissionProfileForm({
  useSubmissionProfileForm: {
    countries,
    useCountryId,
    useGender,
    useBirthday,
    useEmail,
    useDisplayName,
    useAPIError,
    onSubmit,
    isUserLoggedIn,
    hasCompletedProfile,
    isSubmitLoading,
  },
}: ComposedHookSubmissionProfileForm) {
  const prefixIntl = "pages.screener_success";
  const intl = useIntl();
  const [displayName, setDisplayName] = useDisplayName;
  const [email, setEmail] = useEmail;
  const [countryId, setCountryId] = useCountryId;
  const [gender, setGender] = useGender;
  const [birthday, setBirthday] = useBirthday;
  const [apiError] = useAPIError;

  const countryItems: SelectOption[] = buildCountryItems(countries, intl);

  const [formErrorsMessages, setFormErrorMessages] = useState<FormErrorsMessage>({});
  const hasFormErrors = () => {
    let errors = {};

    if (!dayjs(birthday, "DD/MM/YYYY").isValid())
      errors = {
        ...errors,
        [SubmissionFormFields.age]: intl.formatMessage({
          id: "errors.age_required",
        }),
      };
    if (!gender.length)
      errors = {
        ...errors,
        [SubmissionFormFields.gender]: intl.formatMessage({
          id: "errors.gender_required",
        }),
      };
    if (!(parseInt(countryId) > 0))
      errors = {
        ...errors,
        [SubmissionFormFields.country]: intl.formatMessage({
          id: "errors.country_required",
        }),
      };
    if (!displayName.length) {
      errors = {
        ...errors,
        [SubmissionFormFields.name]: intl.formatMessage({
          id: "errors.name_required",
        }),
      };
    } else if (displayName.length < 2 || displayName.length > 32) {
      const base = intl.formatMessage({
        id: "errors.invalid_name_length",
      });
      const type = intl.formatMessage({
        id: `errors.${displayName.length < 2 ? "name_too_short" : "name_too_long"}`,
      });

      errors = {
        ...errors,
        [SubmissionFormFields.name]: `${base} ${type}`,
      };
    }
    if (!isUserLoggedIn)
      if (!validateEmail(email)) {
        errors = {
          ...errors,
          [SubmissionFormFields.email]: intl.formatMessage({
            id: "errors.email.required",
          }),
        };
      }
    setFormErrorMessages(errors);
    return !!Object.values(errors).length;
  };

  const hasError = !!Object.values(formErrorsMessages).length;

  return (
    <SubmissionForm>
      <InputsGroup>
        <FormFields>
          {!isUserLoggedIn && (
            <TextField
              id="email"
              name="email"
              className="field"
              label={intl.formatMessage({ id: `${prefixIntl}.email.label` })}
              style={{ borderRadius: 0 }}
              value={email}
              disabled={hasCompletedProfile}
              onChange={(event) => setEmail(event.target.value)}
              error_message={formErrorsMessages.email}
              error={hasError}
              fullWidth
              placeholder={intl.formatMessage({
                id: `${prefixIntl}.email.placeholder`,
              })}
            />
          )}
          <TextField
            id="displayName"
            name="displayName"
            className="field"
            label={intl.formatMessage({ id: `${prefixIntl}.name.label` })}
            value={displayName}
            disabled={hasCompletedProfile}
            onChange={(event) => setDisplayName(event.target.value)}
            error_message={formErrorsMessages.name}
            error={hasError}
            fullWidth
            placeholder={intl.formatMessage({
              id: `${prefixIntl}.name.placeholder`,
            })}
          />
        </FormFields>
        <div className="row">
          <div className="split">
            <DatePicker
              label={intl.formatMessage({
                id: `${prefixIntl}.age.label`,
              })}
              value={birthday ? dayjs(birthday, "DD/MM/YYYY") : null}
              referenceDate={dayjs().subtract(20, "years").startOf("week")}
              className="field"
              error={hasError}
              errorMessage={formErrorsMessages.age}
              name="age"
              disabled={hasCompletedProfile}
              onChange={(date) => {
                if (date) {
                  setBirthday(date.format("DD/MM/YYYY"));
                }
              }}
              minDate={dayjs().subtract(115, "years")}
              maxDate={dayjs().subtract(13, "years")}
              isTextFieldDisabled
              placeholder={intl.formatMessage({
                id: `${prefixIntl}.age.placeholder`,
              })}
            />
          </div>

          {/* div is required due to the flex gap on the parent */}
          <div className="split">
            <Select
              id="gender"
              name="gender"
              errorMessage={formErrorsMessages.gender}
              error={hasError}
              value={gender}
              label={intl.formatMessage({
                id: `${prefixIntl}.gender.label`,
              })}
              placeholder={intl.formatMessage({
                id: `${prefixIntl}.gender.placeholder`,
              })}
              disabled={hasCompletedProfile}
              onChange={(value) => setGender(value as string)}
              items={Object.keys(demographics.genders).map((gender) => ({
                value: gender,
                label: intl.formatMessage({
                  id: `demographics.genders.${gender}`,
                }),
              }))}
            />
          </div>
        </div>
        <FormField
          label={intl.formatMessage({ id: `${prefixIntl}.country.label` })}
          name="countries"
        >
          <AutoComplete
            id="countries"
            className="autocomplete"
            defaultValue={countryItems.find(({ value }) => value == countryId) || ""}
            errorMessage={formErrorsMessages.country}
            error={hasError}
            disabled={hasCompletedProfile}
            value={countryId || ""}
            placeholder={intl.formatMessage({
              id: `${prefixIntl}.country.placeholder`,
            })}
            onChange={(_, selectedOption) =>
              setCountryId((selectedOption as SelectOption)?.value)
            }
            options={countryItems}
          />
        </FormField>
      </InputsGroup>
      {apiError && (
        <FormHelperText className="errorForm" error={true}>
          {apiError.code &&
            intl.formatMessage({
              id: `${prefixIntl}.errors.${ERROR_MESSAGES[apiError.code as ERROR_CODES]}`,
              defaultMessage: "error",
            })}
        </FormHelperText>
      )}
      <Button
        className="submitRegistration"
        variant="contained"
        disabled={isSubmitLoading}
        onClick={() => {
          if (!hasFormErrors()) {
            onSubmit({
              gender,
              birthday,
              countryId: parseInt(countryId),
              email,
              displayName,
              countryName: countries.find(
                (country) => country.countryId === parseInt(countryId)
              )?.name,
            });
          }
        }}
      >
        {isSubmitLoading ? (
          <WhiteCircularProgress size={32} />
        ) : (
          <FormattedMessage
            id={
              isUserLoggedIn
                ? "pages.screener_success.get_started"
                : "pages.email_login.title"
            }
          />
        )}
      </Button>
    </SubmissionForm>
  );
}
