import React, {
  useCallback,
  useState,
  ReactNode,
  useMemo,
  useContext,
  createContext,
  RefObject,
  useRef,
  useEffect,
} from "react";

import { v4 as uuidv4 } from "uuid";

import { AIChatMessageBaseProps, AIChatRole } from "../types/aiChat";
import { delay } from "../utils";

export interface BaseAIChatContextProps<
  StepType,
  MessageType extends AIChatMessageBaseProps
> {
  step: StepType;
  prompt: string;
  loading: boolean;
  messages: MessageType[];
  controller: AbortController;
  inputRef: RefObject<HTMLInputElement>;
  chatSessionId: string;
  setPrompt: (prompt: string) => void;
  setStep: (step: StepType) => void;
  addMessages: (messages: MessageType[], step?: StepType) => void;
  replaceMessage: (id: string, message: MessageType) => void;
  removeMessage: (id: string, options?: { removeAllAfter?: boolean }) => MessageType[];
  setLoading: (loading: boolean) => void;
  stopGenerating: () => void;
  reset: () => void;
}

const BaseAIChatContext = createContext<BaseAIChatContextProps<any, any> | null>(null);

type BaseProviderProps<StepType, _MessageType> = {
  children: ReactNode;
  initialStep: StepType;
};

export function BaseAIChatProvider<StepType, MessageType extends AIChatMessageBaseProps>({
  children,
  initialStep,
}: BaseProviderProps<StepType, MessageType>) {
  const inputRef = useRef<HTMLInputElement>(null);
  const shouldStopGenerating = useRef(false);

  const [chatSessionId, setChatSessionId] = useState<string>(uuidv4());
  const [controller, setController] = useState<AbortController>(new AbortController());
  const [step, setStep] = useState<StepType>(initialStep);
  const [messages, setMessages] = useState<MessageType[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [prompt, setPrompt] = useState<string>("");

  useEffect(() => {
    if (!loading) {
      inputRef.current?.focus();
    }
  }, [loading]);

  const addMessages = useCallback(async (messages: MessageType[], step?: StepType) => {
    const delayMessages =
      messages.length > 1 && messages.some((m) => m.role === AIChatRole.Assistant);

    // Single message being added, add it immediately
    if (!delayMessages) {
      setMessages((prev) => [
        ...prev,
        ...messages.map((m) => ({ ...m, id: m.id || uuidv4() })),
      ]);

      if (step) {
        setStep(step);
      }

      return;
    }

    // Multiple FE static messages being added, delay each message to simulate fetching
    let canceled = false;
    shouldStopGenerating.current = false;

    for (const [index, message] of messages.entries()) {
      if (shouldStopGenerating.current) {
        canceled = true;
        break;
      }

      setMessages((prev) => [...prev, { ...message, id: message.id || uuidv4() }]);

      if (messages[index + 1]) {
        setLoading(true);
        await delay(1000);
        setLoading(false);
      }
    }

    if (step && !canceled) {
      setStep(step);
    }
  }, []);

  const removeMessage = useCallback(
    (id: string, options: { removeAllAfter?: boolean } = {}) => {
      const { removeAllAfter } = options;
      let newMessages = messages;

      const index = messages.findIndex((m) => m.id === id);
      if (index === -1) {
        return newMessages;
      }

      newMessages = removeAllAfter
        ? messages.slice(0, index)
        : messages.filter((m) => m.id !== id);

      setMessages(newMessages);
      return newMessages;
    },
    [messages]
  );

  const replaceMessage = useCallback((id: string, message: MessageType) => {
    setMessages((prev) =>
      prev.map((m) => {
        if (m.id === id) {
          return { ...message, id };
        }
        return m;
      })
    );
  }, []);

  const stopGenerating = useCallback(() => {
    shouldStopGenerating.current = true;
    controller.abort();
    setLoading(false);
    setController(new AbortController());
  }, [loading, controller]);

  const reset = useCallback(() => {
    setPrompt("");
    setStep(initialStep);
    setMessages([]);
    setLoading(false);
    shouldStopGenerating.current = true;
    controller.abort();
    setController(new AbortController());
    setChatSessionId(uuidv4());
  }, [initialStep, controller]);

  const providerValue = useMemo(
    () => ({
      inputRef,
      prompt,
      step,
      loading,
      messages,
      controller,
      chatSessionId,
      setPrompt,
      setStep,
      addMessages,
      removeMessage,
      replaceMessage,
      setLoading,
      stopGenerating,
      reset,
    }),
    [
      prompt,
      step,
      loading,
      messages,
      controller,
      chatSessionId,
      setPrompt,
      setStep,
      addMessages,
      removeMessage,
      replaceMessage,
      setLoading,
      stopGenerating,
      reset,
    ]
  );

  return (
    <BaseAIChatContext.Provider value={providerValue}>
      {children}
    </BaseAIChatContext.Provider>
  );
}

export const BaseAIChatConsumer = BaseAIChatContext.Consumer;

export function useBaseAIChatProvider<
  StepType,
  MessageType extends AIChatMessageBaseProps
>(): BaseAIChatContextProps<StepType, MessageType> {
  const context = useContext(BaseAIChatContext);
  if (!context) {
    throw new Error("useBaseAIChatProvider must be used within a BaseAIChatProvider");
  }
  return context;
}
