import React from "react";

import useTrackingCallback from "#src/containers/TrackingCallbackImage/useTrackingCallback";
import { Wrapper } from "./styled";

export default function TrackingCallbackImage() {
  const { trackingURLCallback } = useTrackingCallback();
  if (trackingURLCallback) {
    return (
      <Wrapper>
        <img src={trackingURLCallback} />
      </Wrapper>
    );
  }
  return <Wrapper />;
}
