import React, { useEffect, useState } from "react";

import { Box, Divider, Drawer, IconButton, Typography, styled } from "@mui/material";

import colors from "#shared/config/theme/colors";
import { useMediaQueryProvider } from "#shared/context/MediaQueryProvider";
import { toRem } from "#shared/utils";
import ProfileImage from "#src/components/common/layout/Header/ProfileMenu/ProfileImage";
import MenuLinks from "#src/components/pages/Feed/MenuLinks";
import Navigation from "#src/containers/layout/Navigation";
import { profileNavigationItems } from "#src/containers/layout/Navigation/items";
import { selectUser } from "#src/features/user/userSlice";
import useCopyToClipboard from "#src/utils/useCopyToClipboard";
import { useIsMobileApp } from "#src/utils/useIsMobileApp";
import { useSelectorTyped } from "../../../../../utils";

export default function MobileProfileMenu() {
  const { isDesktop } = useMediaQueryProvider();
  const copyToClipboard = useCopyToClipboard();
  const user = useSelectorTyped(selectUser);
  const { isMobileApp } = useIsMobileApp();

  const [open, setOpen] = useState(false);

  const closeDrawer = () => setOpen(false);
  const openDrawer = () => setOpen(true);

  useEffect(() => {
    if (isDesktop) {
      closeDrawer();
    }
  }, [isDesktop]);

  return (
    <>
      {!isDesktop && (
        <IconButton
          onClick={openDrawer}
          sx={{
            "&:hover": { background: "transparent" },
            ...(isMobileApp && { visibility: "hidden" }),
          }}
          disableRipple
        >
          <ProfileImage />
        </IconButton>
      )}

      <Drawer anchor="right" open={open} onClose={closeDrawer}>
        <>
          <ProfileWrapper>
            <ProfileImage size={40} />
            <Box display="flex" flexDirection="column" alignItems="center">
              <Typography variant="h6">{user?.profile.displayName}</Typography>
              <UsernameText
                variant="label2"
                onClick={() => copyToClipboard(user?.profile.username ?? "")}
              >
                {user?.profile.username}
              </UsernameText>
            </Box>
          </ProfileWrapper>
          <Wrapper>
            <Navigation
              navItems={profileNavigationItems}
              onNavigationItemClick={closeDrawer}
            />
            <Divider />
            <Box paddingInline={3}>
              <MenuLinks showDivider />
            </Box>
          </Wrapper>
        </>
      </Drawer>
    </>
  );
}

const Wrapper = styled("div")`
  padding: 1rem 1.25rem;
  width: min(${toRem(280)}, calc(70vw));

  .MuiDivider-root {
    margin-block: 1rem;
  }

  .menu-divider {
    margin-inline: -${toRem(12)};
  }

  .copyright a {
    color: ${colors.grey[400]};
  }
`;

const ProfileWrapper = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: ${toRem(12)};
  gap: ${toRem(12)};
  background-color: ${colors.base.surface1};
  border-radius: 0 0 ${toRem(10)} ${toRem(10)};
`;

const UsernameText = styled(Typography)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 240px;
`;
