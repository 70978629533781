import * as React from "react";
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";

const IconHorizontalRule = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    className="shared-icon"
    ref={ref}
    {...props}
  >
    <path
      fill="currentColor"
      d="M2 11.9c0-.498.402-.9.9-.9h16.2a.899.899 0 1 1 0 1.8H2.9a.9.9 0 0 1-.9-.9"
    />
  </svg>
);
const ForwardRef = forwardRef(IconHorizontalRule);
export { ForwardRef as IconHorizontalRule };
