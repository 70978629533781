import React, { useCallback } from "react";

import { Box, Chip, SxProps, Theme } from "@mui/material";

import useInterests from "#src/components/common/Interests/useInterests";
import { SelectInterest } from "#src/types/interests";

interface Props {
  interests?: SelectInterest[];
  value: number[];
  max?: number;
  sx?: SxProps<Theme>;
  onChange: (value: number[]) => void;
}

export default function SelectInterests({
  interests,
  max = 3,
  value = [],
  sx,
  onChange,
}: Props) {
  const { interests: allInterests } = useInterests();

  const handleOnClick = useCallback(
    (interest: number) => {
      if (value.includes(interest)) {
        onChange(value.filter((item) => item !== interest));
        return;
      }

      if (value.length >= max) {
        return;
      }
      onChange([...value, interest]);
    },
    [value]
  );

  const displayedInterests = interests ?? allInterests;

  return (
    <Box display="flex" gap={1} flexWrap="wrap" my={3} sx={sx}>
      {displayedInterests.map((interest) => {
        const isSelected = value.includes(interest.value);

        return (
          <Chip
            color={isSelected ? "primary" : "secondary"}
            variant={isSelected ? "filled" : "outlined"}
            key={interest.value}
            label={interest.label}
            clickable
            disabled={value.length >= max && !isSelected}
            onClick={(e) => {
              e.stopPropagation();
              handleOnClick(interest.value);
            }}
          />
        );
      })}
    </Box>
  );
}
