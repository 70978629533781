import React from "react";

import { Box, IconButton, Typography, styled } from "@mui/material";
import { FormattedMessage } from "react-intl";

import colors from "#shared/config/theme/colors";
import rounding from "#shared/config/theme/rounding";
import { IconSettings } from "#shared/icons/Settings";
import useNavigateToChannel from "#src/components/pages/Feed/CardFeed/useNavigateToChannel";
import ChannelBox from "#src/components/pages/Feed/ChannelsSidebar/SuggestedChannels/components/ChannelBox";
import ChannelsBoxSkeleton from "#src/components/pages/Feed/ChannelsSidebar/SuggestedChannels/components/ChannelBoxSkeleton";
import useMyFollowedChannels from "#src/components/pages/Feed/ChannelsSidebar/useMyFollowedChannels";
import { ChannelListItem } from "#src/types/channel";

interface Props {
  channels: ChannelListItem[];
  isLoadingChannels: boolean;
  onSelectInterests: () => void;
}

export default function SuggestedChannelsDesktop({
  channels,
  isLoadingChannels,
  onSelectInterests,
}: Props) {
  const navigateToChannel = useNavigateToChannel();

  const { isChannelMember } = useMyFollowedChannels();

  const content = () => {
    if (isLoadingChannels) {
      return <ChannelsBoxSkeleton />;
    }

    if (!channels?.length) {
      return (
        <Typography variant="label3" color={colors.text.secondaryDark}>
          <FormattedMessage id="pages.feed.filters.suggested_channels.no_channels" />
        </Typography>
      );
    }

    return (
      <Box display="flex" flexDirection="column" gap={2}>
        {channels.map((channel) => (
          <ChannelBox
            key={channel.id}
            channel={channel}
            joined={isChannelMember(channel.id)}
            onClick={navigateToChannel}
            width="100%"
          />
        ))}
      </Box>
    );
  };

  return (
    <Wrapper>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
        <Typography
          variant="label3"
          fontFamily="Avenir-heavy"
          color={colors.text.secondaryDark}
        >
          <FormattedMessage id="pages.feed.filters.suggested_channels.title" />
        </Typography>

        <IconButton size="small" sx={{ mt: "-0.25rem" }} onClick={onSelectInterests}>
          <IconSettings />
        </IconButton>
      </Box>

      {content()}
    </Wrapper>
  );
}

const Wrapper = styled("div")`
  border-radius: ${rounding.default};
`;
