import React from "react";

import { Box, Chip, styled, Typography } from "@mui/material";

import colors from "#shared/config/theme/colors";
import AIChatCarousel from "#src/components/common/AIChat/components/AIChatCarousel";
import { AI_CHAT_TEMPLATES } from "#src/components/common/AIChat/consts";
import { AIChatTemplate } from "#src/components/common/AIChat/types";
import useTranslation from "#src/utils/useTranslation";

interface Props {
  onSelectTemplate: (template: string) => void;
}

const createTemplateRows = () => {
  const rows: AIChatTemplate[][] = [[], [], []];
  const rowLengths: number[] = [0, 0, 0];

  AI_CHAT_TEMPLATES.forEach((template) => {
    const templateLength = template.title.length;
    const shortestRowIndex = rowLengths.indexOf(Math.min(...rowLengths));
    rows[shortestRowIndex].push(template);
    rowLengths[shortestRowIndex] += templateLength;
  });

  return rows;
};

export default function AIChatWelcomeScreen({ onSelectTemplate }: Props) {
  const { t } = useTranslation();
  const rows = createTemplateRows();

  const createRow = (row: AIChatTemplate[]) => {
    return row.map(({ id, prompt, title }) => (
      <StyledChip key={id} label={title} onClick={() => onSelectTemplate(prompt)} />
    ));
  };

  return (
    <Wrapper>
      <GradientHeading variant="h2" fontFamily="Avenir-heavy" textAlign="center" mb={8}>
        {t("ui.ai_chat.welcome_message")}
      </GradientHeading>

      <CarouselWrapper>
        {rows.map((row, index) => (
          <AIChatCarousel key={index}>{createRow(row)}</AIChatCarousel>
        ))}
      </CarouselWrapper>
    </Wrapper>
  );
}

const Wrapper = styled(Box)`
  width: 100%;
`;

const GradientHeading = styled(Typography)`
  background: ${colors.gradient.ai.value};
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  opacity: 0.4;
`;

const CarouselWrapper = styled(Box)`
  overflow: hidden;
  margin-inline: calc(var(--modal-padding) * -1);
  position: relative;
  padding: 0rem 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  .carousel {
    &:nth-of-type(1) {
      --animation-duration: 35s;
    }

    &:nth-of-type(2) {
      --animation-duration: 80s;
    }

    &:nth-of-type(3) {
      --animation-duration: 50s;
    }
  }

  &:hover {
    .carousel {
      animation-play-state: paused;
    }
  }
`;

const StyledChip = styled(Chip)`
  background: ${colors.base.surface1};

  &:hover {
    background: ${colors.grey[50]};
  }
`;
