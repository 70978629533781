import { useMemo } from "react";

import useSelectedOrganisation from "#src/components/pages/BusinessAccount/useSelectedOrganisation";
import { useGlobalAppProvider } from "#src/containers/Global/GlobalAppProvider";
import useGodView from "#src/containers/GodView/useGodView";
import { OrganisationStatus, OrganisationPositionType } from "../../../types/types";

export default function useOrganisationPermissions() {
  const { organisation: godViewOrganisation } = useGodView();

  const { organisationId } = useSelectedOrganisation();
  const { positions } = useGlobalAppProvider();

  // User can create new organisation if he doesn't own any organisation
  const canCreateNewOrganisation = !positions?.some(
    (c) =>
      c.id === OrganisationPositionType.Owner &&
      c.organisation.status !== OrganisationStatus.PENDING_DELETION
  );

  // User can access business center if he is owner or admin of any organisation
  const canAccessBusinessCenter = useMemo(() => {
    if (godViewOrganisation?.id === organisationId) {
      return true;
    }

    return !!positions?.some(
      (c) =>
        c.organisation.status === OrganisationStatus.VERIFIED &&
        [OrganisationPositionType.Owner, OrganisationPositionType.Admin].includes(c.id)
    );
  }, [positions, godViewOrganisation]);

  // User can access organisation if he is owner or admin of the organisation
  const canAccessOrganisation = useMemo(() => {
    if (!organisationId) return false;

    if (godViewOrganisation?.id === organisationId) {
      return true;
    }

    return !!positions?.some(
      (c) =>
        c.organisation.id === organisationId &&
        c.organisation.status === OrganisationStatus.VERIFIED &&
        [OrganisationPositionType.Owner, OrganisationPositionType.Admin].includes(c.id)
    );
  }, [positions, organisationId, godViewOrganisation]);

  // User can access business center if he is owner or admin of the organisation
  const canEditOrganisation = useMemo(() => {
    if (!organisationId) return false;

    if (godViewOrganisation?.id === organisationId) {
      return true;
    }

    return !!positions?.some(
      (c) =>
        c.organisation.id === organisationId &&
        [OrganisationPositionType.Owner, OrganisationPositionType.Admin].includes(c.id)
    );
  }, [positions, organisationId, godViewOrganisation]);

  // User can remove organisation if he is owner of the organisation
  const canRemoveOrganisation = useMemo(() => {
    if (!organisationId) return false;

    if (godViewOrganisation?.id === organisationId) {
      return true;
    }

    return !!positions?.some(
      (c) =>
        c.organisation.id === organisationId && c.id === OrganisationPositionType.Owner
    );
  }, [positions, organisationId, godViewOrganisation]);

  // User can add, remove and edit admin team members if he is owner of the organisation
  const canEditAdminMembers = useMemo(() => {
    if (!organisationId) return false;

    if (godViewOrganisation?.id === organisationId) {
      return true;
    }

    return !!positions?.some(
      (c) =>
        c.organisation.id === organisationId && c.id === OrganisationPositionType.Owner
    );
  }, [positions, organisationId, godViewOrganisation]);

  const canEditResearcerMembers = useMemo(() => {
    if (!organisationId) return false;

    return !!positions?.some(
      (c) =>
        c.organisation.id === organisationId &&
        [OrganisationPositionType.Owner, OrganisationPositionType.Admin].includes(c.id)
    );
  }, [positions, organisationId]);

  return {
    canCreateNewOrganisation,
    canAccessBusinessCenter,
    canAccessOrganisation,
    canEditOrganisation,
    canEditAdminMembers,
    canEditResearcerMembers,
    canRemoveOrganisation,
  };
}
