import * as React from "react";
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";

const IconSend = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    className="shared-icon"
    ref={ref}
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M5.105 7.59c-1.092-1.712.764-3.773 2.58-2.865l10.973 5.486c1.474.737 1.474 2.84 0 3.578L7.685 19.276c-1.816.907-3.672-1.154-2.58-2.865L7.92 12zM17.764 12 6.79 6.513l2.815 4.411a2 2 0 0 1 0 2.152l-2.815 4.41z"
      clipRule="evenodd"
    />
  </svg>
);
const ForwardRef = forwardRef(IconSend);
export { ForwardRef as IconSend };
