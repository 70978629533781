import { useQuery } from "@tanstack/react-query";

import { ProjectsApi } from "#src/apis/projects/api";
import { ProjectsQueryKeys } from "#src/apis/projects/queryKeys";
import {
  GetOrganisationProjectsRequest,
  GetOrganisationProjectsResponse,
} from "#src/types/projects";
import { AppQueryOptions } from "#src/types/reactQuery";

interface Props {
  params: GetOrganisationProjectsRequest;
  options?: AppQueryOptions<
    GetOrganisationProjectsResponse,
    typeof ProjectsQueryKeys.organisationProjects
  >;
}

export default function useGetOrganisationProjects({ params, options }: Props) {
  return useQuery({
    queryKey: ProjectsQueryKeys.organisationProjects(params),
    queryFn: () => ProjectsApi.organisationProjects(params),
    ...options,
  });
}
