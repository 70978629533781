// modules
import React, { useEffect, useState } from "react";

import { SnackbarContent } from "@mui/material";
import { FormattedMessage } from "react-intl";

// shared
import { Button } from "#shared/components/ui/Button";
import { AvenirTitle, AvenirAndHeavyText, Link } from "#src/components/common/styled";
import { LINK_FALLBACKS } from "#src/constants/common";
import useWlaOrganisation from "#src/utils/useWlaOrganisation";
// styled
import {
  StyledSnackBar,
  WrapperTextContent,
  WrapperButtons,
  WrapperCookieConsent,
} from "./styled";
import "./styles.scss";

type MuiCookieConsentProps = {
  onAcceptOrRejectCookieConsent: (consentChoice: boolean | undefined) => void;
};

export default function MuiCookieConsent({
  onAcceptOrRejectCookieConsent,
}: MuiCookieConsentProps) {
  const [open, setOpen] = useState<boolean>(false);

  const { organisation } = useWlaOrganisation();

  useEffect(() => {
    if (!localStorage.getItem("cookieConsent")) {
      setOpen(true);
    }
  }, []);

  const onClickButton = (consentChoice?: boolean) => {
    onAcceptOrRejectCookieConsent(consentChoice);
    setOpen(false);
  };

  return (
    <StyledSnackBar
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      open={open}
    >
      <SnackbarContent
        message={
          <WrapperCookieConsent>
            <WrapperTextContent>
              <AvenirTitle
                fontFamily="Avenir"
                color="white"
                fontSize="1.625rem"
                textAlign="left"
              >
                <FormattedMessage id="cookies.title" />
              </AvenirTitle>
              <AvenirAndHeavyText color="white" fontSize=".938rem" textAlign="left">
                <FormattedMessage
                  id="cookies.content"
                  values={{
                    url: (chunk) => (
                      <Link
                        href={organisation?.privacyPolicy || LINK_FALLBACKS.privacy}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {chunk}
                      </Link>
                    ),
                  }}
                />
              </AvenirAndHeavyText>
            </WrapperTextContent>

            <WrapperButtons>
              <Button
                className="rejectAll"
                width="9.75rem"
                height="2.5rem"
                sx={{ color: "#fff" }}
                onClick={() => onClickButton(false)}
              >
                <FormattedMessage id="cookies.button.reject" />
              </Button>
              <Button
                className="acceptAll"
                width="9.75rem"
                height="2.5rem"
                onClick={() => onClickButton(true)}
              >
                <FormattedMessage id="cookies.button.accept" />
              </Button>
            </WrapperButtons>
          </WrapperCookieConsent>
        }
      ></SnackbarContent>
    </StyledSnackBar>
  );
}
