import * as React from "react";
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";

const IconSparkles = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    className="shared-icon"
    ref={ref}
    {...props}
  >
    <path
      fill="currentColor"
      d="M14.793 5.328a.45.45 0 0 0-.293.422c0 .188.117.355.293.422L17 7l.828 2.207a.45.45 0 0 0 .422.293.45.45 0 0 0 .422-.293L19.5 7l2.207-.828A.45.45 0 0 0 22 5.75a.45.45 0 0 0-.293-.422L19.5 4.5l-.828-2.207A.45.45 0 0 0 18.25 2a.45.45 0 0 0-.422.293L17 4.5zm-12.43 6.047a.62.62 0 0 0-.363.57c0 .246.14.465.363.567l.664.308.317.145.023.012 3.45 1.593 1.593 3.446.012.023.144.316.309.668a.622.622 0 0 0 1.133 0l.308-.668.145-.316.012-.023 1.593-3.45 3.45-1.59.023-.011.316-.145.668-.308a.622.622 0 0 0 0-1.133l-.668-.309-.316-.144-.023-.012-3.45-1.594-1.59-3.449-.011-.023-.145-.32-.308-.669a.622.622 0 0 0-1.133 0l-.309.668-.144.317-.012.023-1.594 3.45-3.449 1.593-.023.012-.317.144zm3.242.566 2-.921c.407-.188.73-.512.918-.918l.922-2 .922 2c.188.406.512.73.918.918l2 .921-2 .922c-.406.188-.73.512-.918.918l-.922 2-.922-2a1.87 1.87 0 0 0-.918-.918l-2-.918zM17 17l-2.207.828a.45.45 0 0 0-.293.422c0 .188.117.355.293.422L17 19.5l.828 2.207a.45.45 0 0 0 .422.293.45.45 0 0 0 .422-.293L19.5 19.5l2.207-.828A.45.45 0 0 0 22 18.25a.45.45 0 0 0-.293-.422L19.5 17l-.828-2.207a.45.45 0 0 0-.422-.293.45.45 0 0 0-.422.293z"
    />
  </svg>
);
const ForwardRef = forwardRef(IconSparkles);
export { ForwardRef as IconSparkles };
