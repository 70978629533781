import React, { ReactNode, useEffect, useState } from "react";

import { Box } from "@mui/material";

import { LoadingButton } from "#shared/components/ui";
import useIntersectObserver from "#shared/utils/hooks/useIntersectObserver";
import useTranslation from "#src/utils/useTranslation";

interface Props {
  offset?: number;
  autoLoad?: boolean;
  delay?: number;
  hasNextPage: boolean;
  isLoadingNextPage: boolean;
  loadNextPage: () => void;
  Skeleton?: ReactNode;
  children: ReactNode;
}

function InfiniteLoader({
  offset = 100,
  autoLoad = false,
  delay = 500,
  hasNextPage,
  isLoadingNextPage,
  loadNextPage,
  Skeleton,
  children,
}: Props) {
  const { t } = useTranslation();
  const [loadMoreRef, entryBottom] = useIntersectObserver<HTMLDivElement | null>({});

  const [isFirstLoad, setIsFirstLoad] = useState(!autoLoad);
  const [loadingDelayPassed, setLoadingDelayPassed] = useState(true);

  useEffect(() => {
    if (
      entryBottom?.isIntersecting &&
      hasNextPage &&
      !isFirstLoad &&
      loadingDelayPassed
    ) {
      if (isLoadingNextPage) {
        return;
      }

      loadNextPage();
      setLoadingDelayPassed(false);

      setTimeout(() => {
        setLoadingDelayPassed(true);
      }, delay);
    }
  }, [
    entryBottom?.isIntersecting,
    hasNextPage,
    isLoadingNextPage,
    loadNextPage,
    loadingDelayPassed,
    delay,
  ]);

  const handleLoadNextPageClick = () => {
    if (isLoadingNextPage) {
      return;
    }

    loadNextPage();

    // Add slight delay to prevent two pages loading one after the another
    setTimeout(() => {
      setIsFirstLoad(false);
    }, 500);
  };

  const showSkeleton = Boolean(Skeleton && !isFirstLoad && isLoadingNextPage);

  return (
    <Box className="infinite-loader" sx={{ position: "relative" }}>
      {children}
      {showSkeleton && Skeleton}
      <Box
        sx={{ position: "absolute", bottom: 0, left: 0, height: `${offset}px` }}
        ref={loadMoreRef}
      />
      {hasNextPage && (
        <Box display="flex" justifyContent="center">
          <LoadingButton
            variant="cta"
            size="small"
            sx={{ mt: 4 }}
            loading={isLoadingNextPage}
            onClick={handleLoadNextPageClick}
          >
            {t("ui.buttons.load_more")}
          </LoadingButton>
        </Box>
      )}
    </Box>
  );
}

export default InfiniteLoader;
