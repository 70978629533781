import { useInfiniteQuery } from "@tanstack/react-query";

import { ChannelsApi } from "#src/apis/channels/api";
import { ChannelsQueryKeys } from "#src/apis/channels/queryKeys";
import { CHANNELS_PAGE_SIZE } from "#src/constants/channel";
import { GetMyFollowedChannelsResponse } from "#src/types/channel";
import { AppInfiniteQueryOptions } from "#src/types/reactQuery";
import { getInfiniteQueryOptions } from "#src/utils/paginationUtils";

interface Props {
  options?: AppInfiniteQueryOptions<GetMyFollowedChannelsResponse, string[]>;
}

export default function useGetMyFollowedChannels({ options }: Props = {}) {
  return useInfiniteQuery({
    queryKey: ChannelsQueryKeys.followed,
    queryFn: ({ pageParam }) =>
      ChannelsApi.getFollowedChannels({
        page: pageParam,
        pageSize: CHANNELS_PAGE_SIZE,
      }),
    ...getInfiniteQueryOptions(),
    ...options,
  });
}
