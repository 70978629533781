import * as React from "react";
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";

const IconXTwitter = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    className="shared-icon"
    ref={ref}
    {...props}
  >
    <path
      fill="currentColor"
      d="M17.75 3h3.068l-6.702 7.658L22 21.079h-6.171l-4.837-6.319-5.528 6.32H2.39l7.167-8.193L2 3h6.328l4.367 5.776zm-1.078 16.245h1.7L7.401 4.738H5.577z"
    />
  </svg>
);
const ForwardRef = forwardRef(IconXTwitter);
export { ForwardRef as IconXTwitter };
