import * as React from "react";
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";

const IconPieChartDuotone = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    className="shared-icon"
    ref={ref}
    {...props}
  >
    <path
      fill="#126AE8"
      d="M10.75 3.937a.595.595 0 0 0-.664-.601C5.52 3.965 2 7.886 2 12.625 2 17.805 6.2 22 11.375 22c4.742 0 8.66-3.52 9.285-8.086.05-.36-.238-.664-.601-.664H10.75z"
      opacity={0.25}
    />
    <path
      fill="#126AE8"
      d="M12.625 11.375h8.727A.637.637 0 0 0 22 10.75 8.75 8.75 0 0 0 13.25 2a.637.637 0 0 0-.625.648z"
    />
  </svg>
);
const ForwardRef = forwardRef(IconPieChartDuotone);
export { ForwardRef as IconPieChartDuotone };
