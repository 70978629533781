import React, { useMemo, useState } from "react";

import { Box, CircularProgress, MenuItem, Typography, styled } from "@mui/material";

import ModalSelectList from "#shared/components/ui/Modal/ModalSelectList";
import { RadioSelect } from "#shared/components/ui/Radio";
import colors from "#shared/config/theme/colors";
import { IconStore } from "#shared/icons/Store";
import { IconUser } from "#shared/icons/User";
import { RadioOption } from "#shared/types/form";
import useSearchOrganisations from "#src/apis/superAdmin/hooks/useSearchOrganisations";
import SearchField from "#src/components/common/Search/SearchField";
import TextEllipsis from "#src/components/common/Text/TextEllipsis";
import InfiniteLoader from "#src/components/pages/ChannelCommunity/SearchMembers/grid/InfiniteLoader";
import useGodView from "#src/containers/GodView/useGodView";
import { SearchOrganisationsFilter } from "#src/types/admin";
import { mapPaginatedData } from "#src/utils/paginationUtils";
import useTranslation from "#src/utils/useTranslation";

interface Props {
  value?: number;
}

export default function SelectGodViewOrganisation({ value }: Props) {
  const { t } = useTranslation();
  const { enterGodView } = useGodView();

  const [search, setSearch] = useState("");
  const [filter, setFilter] = useState<SearchOrganisationsFilter>(
    SearchOrganisationsFilter.All
  );

  const {
    data,
    isLoading,
    hasNextPage,
    isRefetching,
    isFetchingNextPage,
    fetchNextPage,
  } = useSearchOrganisations({
    params: {
      search,
      type: filter,
    },
  });

  const organisations = useMemo(() => mapPaginatedData(data), [data]);

  const items = organisations.map((item) => ({
    label: item.name,
    value: item.id,
    isPersonal: item.isPersonalOrganisation,
  }));

  const filters: RadioOption[] = useMemo(
    () => [
      {
        value: SearchOrganisationsFilter.All,
        label: t("ui.god_view.select_organisation.filters.all"),
      },
      {
        value: SearchOrganisationsFilter.Business,
        label: t("ui.god_view.select_organisation.filters.organisations"),
        Icon: IconStore,
      },
      {
        value: SearchOrganisationsFilter.Personal,
        label: t("ui.god_view.select_organisation.filters.personal"),
        Icon: IconUser,
      },
    ],
    []
  );

  const handleSelectOrganisation = (id: number) => {
    const organisation = organisations.find((item) => item.id === id);

    if (!organisation) {
      return;
    }

    enterGodView(organisation);
  };

  const content = () => {
    if (isLoading) {
      return (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="100%"
          py={4}
        >
          <CircularProgress size={30} />
        </Box>
      );
    }

    if (!items.length) {
      return (
        <Typography variant="label2" color={colors.text.tertiaryDark}>
          {t("ui.god_view.select_organisation.no_results")}
        </Typography>
      );
    }

    return (
      <ModalSelectList>
        <InfiniteLoader
          autoLoad
          hasNextPage={hasNextPage}
          isLoadingNextPage={isFetchingNextPage}
          loadNextPage={fetchNextPage}
        >
          {items.map((item) => (
            <MenuItem
              key={item.value}
              value={item.value}
              onClick={() => handleSelectOrganisation(item.value)}
              selected={item.value === value}
            >
              <ItemWrapper>
                <TextEllipsis variant="inherit" pr={4}>{`${item.label}`}</TextEllipsis>
                {item.isPersonal ? <IconUser /> : <IconStore />}
              </ItemWrapper>
            </MenuItem>
          ))}
        </InfiniteLoader>
      </ModalSelectList>
    );
  };

  return (
    <>
      <SearchField
        value={search}
        onChange={setSearch}
        loading={isRefetching}
        placeholder={t("ui.god_view.select_organisation.search")}
        sx={{ mb: 4 }}
      />

      <RadioSelect
        value={filter}
        onChange={(val) => setFilter(val as SearchOrganisationsFilter)}
        options={filters}
        name="organisation-filter"
        sx={{ mb: 2 }}
      />

      {content()}
    </>
  );
}

const ItemWrapper = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  svg {
    flex-shrink: 0;
    width: 1rem;
    height: 1rem;
  }
`;
