import queryString from "query-string";
import { useSearchParams } from "react-router-dom";

import { OAUTH_STRATEGIES } from "#src/constants/common";
import { getUrlSearchParams } from "../../../../helper";

const REREQUEST_PARAM = "rerequest=true";

interface Args {
  rerequestFacebookAuth: boolean;
}

const useOauthStrategy = (args?: Args) => {
  const [searchParams] = useSearchParams();
  const redirectURL = searchParams.get("redirect") || "/";
  const urlSearchParams = getUrlSearchParams(searchParams);

  const pathname = redirectURL?.split("?")[0];

  const getNavigatorDetails = () => {
    return typeof window !== "undefined"
      ? `?agent=${navigator.userAgent}&platform=${navigator.platform}`
      : "";
  };

  const errorType = searchParams.get("errorType");

  const onClickOauthStrategy = (
    oauthDetails?:
      | {
          url: string;
          oauthStrategy?: string;
          provider?: boolean | undefined;
        }
      | undefined
  ) => {
    const { url, oauthStrategy, provider } = oauthDetails || {};
    let redirect;
    const queryParams = queryString.stringify(urlSearchParams);
    // if guest we redirect directly
    if (!oauthStrategy && redirectURL.includes("/screener")) {
      const operator = redirectURL.includes("?") ? "&" : "?";
      redirect = `${redirectURL}${operator}strategy=guest`;
    } else if (oauthStrategy === OAUTH_STRATEGIES.email) {
      // email
      redirect = `/signin/email?${queryParams}`;
    } else {
      // we go through authentication workflow
      redirect = `${url}${
        provider
          ? `${getNavigatorDetails()}${
              args?.rerequestFacebookAuth ? `&${REREQUEST_PARAM}` : ""
            }&${queryParams}`
          : `?${REREQUEST_PARAM}`
      }`;
    }
    window.location.href = redirect;
  };

  return {
    shouldAllowGuests: pathname.includes("/screener") && !errorType,
    onClickOauthStrategy,
  };
};

export default useOauthStrategy;
