import { IMAGE_TYPES, VIDEO_TYPES } from "../types/resources";

export const getResourcesURL = (
  baseUrl: string,
  resource: string | undefined,
  imageType: IMAGE_TYPES | VIDEO_TYPES
) => {
  if (!resource) {
    return "";
  }

  if (resource.startsWith("blob:") || resource.startsWith("http")) {
    return resource;
  }

  return `${baseUrl}${imageType}.html?name=${resource}`;
};
