import React from "react";

import ChannelJoinLeaveActions from "#src/components/pages/ChannelDetails/ChannelHeader/components/ChannelJoinLeaveActions";
import ChannelShareButton from "#src/components/pages/ChannelDetails/ChannelHeader/components/ChannelShareButton";
import { ChannelDetails } from "#src/types/channel";

interface Props {
  channel: ChannelDetails;
}

export default function ChannelUserActions({ channel }: Props) {
  return (
    <>
      <ChannelShareButton />
      <ChannelJoinLeaveActions channel={channel} />
    </>
  );
}
