import * as React from "react";
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";

const IconHeading2 = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    className="shared-icon"
    ref={ref}
    {...props}
  >
    <path
      fill="currentColor"
      d="M4 7.042V6H2v12.5h2v-5.208h6V18.5h2V6h-2v5.208H4zm13.36 1.041h.14c1.103 0 2 .935 2 2.084v.188c0 .583-.234 1.143-.65 1.537l-5.025 4.798-.325.31v1.5H22v-2.083h-4.928l3.131-2.992a4.25 4.25 0 0 0 1.297-3.073v-.185C21.5 7.865 19.71 6 17.5 6h-.14a3.9 3.9 0 0 0-2.604 1.003l-.906.81-.76.677 1.3 1.582.76-.677.906-.81a1.96 1.96 0 0 1 1.303-.502"
    />
  </svg>
);
const ForwardRef = forwardRef(IconHeading2);
export { ForwardRef as IconHeading2 };
