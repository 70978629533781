import React from "react";

import { Skeleton, Box } from "@mui/material";

import {
  ChannelBannerWrapper,
  ChannelLogoWrapper,
  ChannelContentWrapper,
  ChannelHeaderWrapper,
} from "#src/components/pages/ChannelDetails/ChannelHeader/styled";

export default function ChannelHeaderSkeleton() {
  return (
    <ChannelHeaderWrapper>
      <ChannelBannerWrapper>
        <Skeleton variant="rectangular" animation="wave" width="100%" height="100%" />
      </ChannelBannerWrapper>
      <ChannelLogoWrapper>
        <Skeleton variant="rounded" animation="wave" width="100%" height="100%" />
      </ChannelLogoWrapper>
      <ChannelContentWrapper>
        <Skeleton variant="text" animation="wave" width="40%" height={40} />
        <Box display="flex" flexDirection="row" gap={4} mt={1}>
          <Skeleton variant="text" animation="wave" width={60} height={20} />
          <Skeleton variant="text" animation="wave" width={60} height={20} />
        </Box>
        <Skeleton variant="text" animation="wave" width="100%" height={50} />
      </ChannelContentWrapper>
    </ChannelHeaderWrapper>
  );
}
