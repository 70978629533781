export enum AIChatRole {
  User = "user",
  Assistant = "assistant",
}

export interface AIChatTemplate {
  id: string;
  title: string;
  prompt: string;
}

export interface AIChatMessageBaseProps<MessageType = string, DataType = any> {
  id?: string;
  role: AIChatRole;
  content: string;
  skip?: boolean; // FE only - skip message from being sent to the backend
  loading?: boolean; // FE only
  metadata?: {
    type: MessageType;
    data?: DataType;
  };
  internalMetadata?: any; // BE only
}
