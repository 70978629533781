import * as React from "react";
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";

const IconThreads = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    className="shared-icon"
    ref={ref}
    {...props}
  >
    <path
      fill="currentColor"
      d="M16.122 11.152c.092.038.175.08.263.117 1.216.587 2.108 1.466 2.574 2.558.654 1.52.717 3.99-1.262 5.965-1.508 1.508-3.345 2.187-5.94 2.208h-.013c-2.924-.02-5.17-1.004-6.682-2.924C3.717 17.368 3.025 14.989 3 12.01v-.02c.02-2.979.712-5.358 2.058-7.066C6.57 3.004 8.82 2.021 11.744 2h.013c2.928.02 5.203 1 6.76 2.912.767.946 1.334 2.083 1.692 3.403l-1.683.45c-.296-1.075-.742-1.991-1.341-2.724-1.217-1.492-3.041-2.258-5.437-2.275-2.374.021-4.17.783-5.34 2.266-1.092 1.388-1.658 3.396-1.68 5.966.022 2.57.588 4.578 1.68 5.97 1.166 1.483 2.966 2.245 5.34 2.266 2.141-.017 3.558-.525 4.737-1.704 1.345-1.341 1.32-2.991.891-3.995-.254-.592-.712-1.083-1.329-1.454-.154 1.12-.491 2.012-1.029 2.7q-1.07 1.363-3.028 1.47c-.983.054-1.929-.183-2.662-.667-.867-.574-1.375-1.45-1.429-2.47-.104-2.012 1.487-3.458 3.966-3.6.879-.05 1.704-.012 2.466.118-.1-.617-.304-1.109-.608-1.467-.417-.487-1.067-.737-1.925-.741h-.029c-.691 0-1.625.191-2.22 1.095l-1.433-.983c.8-1.212 2.095-1.879 3.657-1.879h.034c2.607.017 4.161 1.646 4.32 4.487l-.009.008zm-6.498 2.866c.054 1.046 1.183 1.533 2.274 1.47 1.067-.057 2.275-.474 2.479-3.048a8.5 8.5 0 0 0-1.808-.184q-.3-.001-.6.017c-1.787.1-2.383.966-2.341 1.741z"
    />
  </svg>
);
const ForwardRef = forwardRef(IconThreads);
export { ForwardRef as IconThreads };
