import * as React from "react";
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";

const IconUpload = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    className="shared-icon"
    ref={ref}
    {...props}
  >
    <path
      fill="currentColor"
      d="M13.2 7.083c.474.55 1.254.726 1.921.43a2.473 2.473 0 0 1 3.448 2.664c-.12.743.279 1.475.966 1.777a3.022 3.022 0 0 1-1.031 5.789.4.4 0 0 0-.066.007H5.95a3.3 3.3 0 0 1-1.1-6.41 1.65 1.65 0 0 0 1.1-1.558v-.007a4.124 4.124 0 0 1 7.25-2.692M18.6 19.4v-.007a4.679 4.679 0 0 0 1.598-8.955 4.124 4.124 0 0 0-5.747-4.434A5.774 5.774 0 0 0 4.3 9.774v.008A4.951 4.951 0 0 0 5.95 19.4h12.649m-9.934-7.734A.825.825 0 0 0 9.83 12.83l1.34-1.34V16.1c0 .457.368.825.826.825a.823.823 0 0 0 .825-.825v-4.61l1.34 1.34a.825.825 0 0 0 1.165-1.165l-2.75-2.75a.82.82 0 0 0-1.165 0l-2.75 2.75z"
    />
  </svg>
);
const ForwardRef = forwardRef(IconUpload);
export { ForwardRef as IconUpload };
