import React from "react";

import { Steps } from "#shared/components/ui";
import useBriefBuilderSteps from "#src/containers/BriefBuilder/hooks/useBriefBuilderSteps";
import { BriefBuilderStep } from "#src/types/briefBuilder";

interface Props {
  onStepClick: (step: BriefBuilderStep) => void;
}

export default function BuilderDesktopSteps({ onStepClick }: Props) {
  const { steps } = useBriefBuilderSteps();

  return (
    <Steps steps={steps} onClick={(step) => onStepClick(step.id as BriefBuilderStep)} />
  );
}
