import * as React from "react";
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";

const IconRocketLaunchSolid = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    className="shared-icon"
    ref={ref}
    {...props}
  >
    <path
      fill="currentColor"
      d="M6.604 6.998h3.484c3.121-5.144 7.91-5.332 10.816-4.793.453.086.809.438.89.89.54 2.907.352 7.696-4.792 10.817V17.4c0 .992-.523 1.914-1.379 2.418l-3.457 2.05a.937.937 0 0 1-1.414-.809v-4.48a3.33 3.33 0 0 0-3.332-3.331H2.94a.94.94 0 0 1-.816-.473.93.93 0 0 1 .007-.941l2.051-3.457A2.81 2.81 0 0 1 6.6 6.998zm11.96 0a1.562 1.562 0 1 0-3.125 0 1.562 1.562 0 0 0 3.125 0M8.506 20.357c-1.336 1.34-3.926 1.602-5.398 1.637a1.063 1.063 0 0 1-1.102-1.102c.036-1.473.297-4.062 1.637-5.398a3.437 3.437 0 0 1 4.863 0 3.437 3.437 0 0 1 0 4.863m-1.824-1.422a1.146 1.146 0 0 0 0-1.617 1.146 1.146 0 0 0-1.617 0c-.395.394-.508 1.113-.535 1.613-.02.313.23.559.543.543.5-.027 1.218-.144 1.613-.535z"
    />
  </svg>
);
const ForwardRef = forwardRef(IconRocketLaunchSolid);
export { ForwardRef as IconRocketLaunchSolid };
