import { useEffect, useState } from "react";

import { useGetUserInterests } from "#src/apis/interests/hooks/useGetUserInterests";
import { USER_INTERESTS_DISMISSED_STORAGE_KEY } from "#src/constants/common";

export default function useSuggestedChannelsInterests() {
  const [showSelectInterests, setShowSelectInterests] = useState(false);

  const { data: interestsData, isLoading: isLoadingInterests } = useGetUserInterests();

  const interests = interestsData?.map((interest) => interest.interestsId) || [];

  useEffect(() => {
    if (isLoadingInterests || interests.length) {
      return;
    }

    const interestsDismissed = localStorage.getItem(USER_INTERESTS_DISMISSED_STORAGE_KEY);

    if (!interestsDismissed) {
      setShowSelectInterests(true);
    }
  }, [interests, isLoadingInterests]);

  const handleConfirmInterests = () => {
    handleCloseInterests();
  };

  const handleCloseInterests = () => {
    localStorage.setItem(USER_INTERESTS_DISMISSED_STORAGE_KEY, "true");
    setShowSelectInterests(false);
  };

  return {
    interests,
    isLoadingInterests,
    showSelectInterests,
    setShowSelectInterests,
    handleConfirmInterests,
    handleCloseInterests,
  };
}
