import { useQuery } from "@tanstack/react-query";

import { InterestsApi } from "#src/apis/interests/api";
import { InterestsQueryKeys } from "#src/apis/interests/queryKeys";
import { Interests } from "#src/types/interests";
import { AppQueryOptions } from "#src/types/reactQuery";

interface Props {
  options?: AppQueryOptions<Interests>;
}

export const useGetUserInterests = ({ options }: Props = {}) => {
  return useQuery({
    queryKey: InterestsQueryKeys.user,
    queryFn: () => InterestsApi.getUserInterests(),
    ...options,
  });
};
