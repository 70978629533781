import * as React from "react";
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";

const IconMore = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    className="shared-icon"
    ref={ref}
    {...props}
  >
    <path
      fill="currentColor"
      d="M4.063 14.127a1.99 1.99 0 0 1-1.457-.606A1.99 1.99 0 0 1 2 12.063q0-.85.606-1.457Q3.212 10 4.063 10t1.458.606.606 1.457q0 .852-.606 1.458t-1.458.606m7.937 0a1.99 1.99 0 0 1-1.457-.606 1.99 1.99 0 0 1-.606-1.458q0-.85.606-1.457Q11.149 10 12 10q.85 0 1.457.606.606.606.606 1.457t-.606 1.458-1.457.606m7.937 0a1.99 1.99 0 0 1-1.458-.606 1.99 1.99 0 0 1-.606-1.458q0-.85.606-1.457.606-.606 1.458-.606.85 0 1.457.606.606.606.606 1.457t-.606 1.458-1.457.606"
    />
  </svg>
);
const ForwardRef = forwardRef(IconMore);
export { ForwardRef as IconMore };
