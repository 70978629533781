import * as React from "react";
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";

const IconCircleInfoSolid = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    className="shared-icon"
    ref={ref}
    {...props}
  >
    <path
      fill="currentColor"
      d="M12 22a10 10 0 1 0 0-20 10 10 0 0 0 0 20m-1.562-6.875h.937v-2.5h-.937a.935.935 0 0 1-.938-.937c0-.52.418-.938.938-.938h1.874c.52 0 .938.418.938.938v3.437h.313c.519 0 .937.418.937.938 0 .519-.418.937-.937.937h-3.126a.935.935 0 0 1-.937-.937c0-.52.418-.938.938-.938M12 7a1.25 1.25 0 1 1 0 2.5A1.25 1.25 0 0 1 12 7"
    />
  </svg>
);
const ForwardRef = forwardRef(IconCircleInfoSolid);
export { ForwardRef as IconCircleInfoSolid };
