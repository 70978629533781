import * as React from "react";
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";

const IconShareNodes = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    className="shared-icon"
    ref={ref}
    {...props}
  >
    <path
      fill="currentColor"
      d="M20.5 7a3.751 3.751 0 0 1-6.578 2.46l-3.504 1.755q.082.38.082.785t-.082.785l3.504 1.754a3.751 3.751 0 1 1 2.828 6.21 3.75 3.75 0 0 1-3.668-4.534L9.578 14.46A3.751 3.751 0 1 1 6.75 8.25c1.129-.001 2.14.499 2.828 1.288l3.504-1.754A3.751 3.751 0 1 1 20.5 7M6.75 13.875a1.875 1.875 0 1 0 0-3.75 1.875 1.875 0 0 0 0 3.75M18.625 7a1.875 1.875 0 1 0-3.75 0 1.875 1.875 0 0 0 3.75 0M16.75 18.875a1.875 1.875 0 1 0 0-3.75 1.875 1.875 0 0 0 0 3.75"
    />
  </svg>
);
const ForwardRef = forwardRef(IconShareNodes);
export { ForwardRef as IconShareNodes };
