import {
  Dispatch,
  MutableRefObject,
  ReactElement,
  RefObject,
  SVGProps,
  SetStateAction,
} from "react";

import { RouteObject } from "react-router";

import { DotNestedKeys } from "../../src/utils";

/* for simpler prop transferring of useState return value */
export type StateHook<T> = [T, Dispatch<SetStateAction<T>>];

/**
 * Adds a "page" property for our SSR purposes
 * @param {string} page - has the same name as the file in src/pages for SSR to pick up the initPage method */
export type CustomRoute = {
  page?: string;
  children?: CustomRoute[];
} & Omit<RouteObject, "children">;

export type SelectOption = {
  value: string;
  label: string;
};

export type Cookies = {
  user: string;
};

export type UseVideoOutputProps = {
  useIsPlaying: [boolean, Dispatch<SetStateAction<boolean>>];
  togglePlay: () => void;
  firstPlay: () => void;
  videoRef: MutableRefObject<HTMLVideoElement>;
};

export type VideoProps = {
  videoSrc?: string;
  videoWidth?: string;
  videoHeight?: string;
  play?: boolean;
  manualPlay?: boolean;
  editMode?: boolean;
};

export type Dimensions = {
  width: number;
  height: number;
};

export type Coordinates = {
  x: number;
  y: number;
};

export type Rectangle = {
  a: Coordinates;
  b: Coordinates;
  c: Coordinates;
  d: Coordinates;
};

export type ImageDimensions = Dimensions & {
  image: string;
};

export type BBOX = {
  left: number;
  right: number;
  bottom: number;
  top: number;
};

export interface PollItemConfig {
  isProgressButtonHidden: boolean;
  isPoweredByButtonHidden: boolean;
}

export type ItemComponent<T = any> = {
  id: string;
  ItemComponent: React.ComponentType<T>;
  parentId?: string;
  props?: any;
  config?: PollItemConfig;
};

export type SwitchItemComponent = ItemComponent & {
  ref: RefObject<HTMLDivElement>;
};

export type WindowSize = {
  width: number;
  height: number;
};

export enum ScrollDirections {
  "up" = "up",
  "down" = "down",
}

export type SwitchItemProps = {
  goToNextOrPreviousItem: (direction: ScrollDirections) => void;
  setWrapperItemsRef(el: HTMLDivElement): void;
  items: SwitchItemComponent[];
  isLoadingItem: boolean;
  goToItem: (itemId?: string) => void;
};

export enum locales {
  "ar" = "ar",
  "en" = "en",
  "zh_CN" = "zh_CN",
  "fr" = "fr",
  "de" = "de",
  "id" = "id",
  "it" = "it",
  "ja" = "ja",
  "ms" = "ms",
  "pl" = "pl",
  "pt" = "pt",
  "ro" = "ro",
  "ru" = "ru",
  "es" = "es",
  "tr" = "tr",
}

export type SortDirection = "asc" | "desc";

export type SortTableHeader<T> = {
  id: T;
  label: string;
  invert?: boolean;
};

export type DataTableColumns<DataType> = DotNestedKeys<DataType>;

export type DataTableHeader<T> = {
  id: T;
  label: string;
  sortable?: boolean;
  invert?: boolean;
};

export type DataTableHeaders<DataType> = DataTableHeader<DataTableColumns<DataType>>[];

export type DataTableRow<T> = {
  id: string | number;
  deletable?: boolean;
} & T;

export type ReactIcon = React.FC<React.SVGProps<SVGSVGElement>>;

export enum ImageOrientation {
  Landscape = "landscape",
  Portrait = "portrait",
  Squarish = "squarish",
}
