import * as React from "react";
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";

const IconEnvelope = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    className="shared-icon"
    ref={ref}
    {...props}
  >
    <path
      fill="currentColor"
      d="M4.5 6.875a.627.627 0 0 0-.625.625v.863l6.738 5.532a2.185 2.185 0 0 0 2.778 0l6.734-5.532V7.5a.627.627 0 0 0-.625-.625zm-.625 3.914V17.5c0 .344.281.625.625.625h15a.627.627 0 0 0 .625-.625v-6.71l-5.547 4.554a4.06 4.06 0 0 1-5.156 0zM2 7.5C2 6.121 3.121 5 4.5 5h15C20.879 5 22 6.121 22 7.5v10c0 1.379-1.121 2.5-2.5 2.5h-15A2.5 2.5 0 0 1 2 17.5z"
    />
  </svg>
);
const ForwardRef = forwardRef(IconEnvelope);
export { ForwardRef as IconEnvelope };
