import { Dispatch, SetStateAction, useState } from "react";

import { isAxiosError } from "axios";

import { APIErrorDetails } from "#shared/components/api/errors";
import { APIResponse } from "#src/types/types";
import { validateEmail } from "#src/utils/index";

type UseEmailFormProps = {
  defaultEmail: string;
  onSubmit: (email: string) => Promise<APIResponse<any> | APIErrorDetails | undefined>;
  onSuccess?: () => void;
};

export type UseEmailFormOutputProps = {
  sendingEmail: boolean;
  hasEmailBeenSent: boolean;
  useErrorMessage: [string | undefined, Dispatch<SetStateAction<string | undefined>>];
  useEmail: [string, Dispatch<SetStateAction<string>>];
  onSendingEmail: () => Promise<void>;
};

export default function useEmailForm({
  defaultEmail,
  onSubmit,
  onSuccess,
}: UseEmailFormProps): UseEmailFormOutputProps {
  const [email, setEmail] = useState<string>(defaultEmail);
  const [hasEmailBeenSent, setHasEmailBeenSent] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const [sendingEmail, setSendingEmail] = useState<boolean>(false);

  const handleOnSubmit = async (email: string) => {
    try {
      setSendingEmail(true);

      const response = await onSubmit(email);
      return response;
    } catch (error) {
      if (isAxiosError(error)) {
        setErrorMessage(error.response?.data?.data?.error_details ?? error.message);
      }
    } finally {
      setSendingEmail(false);
    }
  };

  const onSendingEmail = async () => {
    //  validation
    if (!validateEmail(email)) {
      if (!email) {
        setErrorMessage("errors.email.required");
      } else {
        setErrorMessage("errors.email.invalid");
      }
      return;
    }

    // send email
    const response = await handleOnSubmit(email);

    if (response) {
      if (onSuccess) {
        onSuccess();
      }

      setHasEmailBeenSent(true);
      setErrorMessage("");
    }
  };

  return {
    sendingEmail,
    hasEmailBeenSent,
    useErrorMessage: [errorMessage, setErrorMessage],
    useEmail: [email, setEmail],
    onSendingEmail,
  };
}
