import * as React from "react";
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";

const IconCircleQuestion = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    className="shared-icon"
    ref={ref}
    {...props}
  >
    <path
      fill="currentColor"
      d="M20.125 12a8.125 8.125 0 1 0-16.25 0 8.125 8.125 0 0 0 16.25 0M2 12a10 10 0 1 1 20 0 10 10 0 0 1-20 0m6.633-3.543A2.19 2.19 0 0 1 10.695 7h2.278a2.465 2.465 0 0 1 1.226 4.605l-1.261.723a.94.94 0 0 1-.938.922.935.935 0 0 1-.937-.937v-.528c0-.336.18-.644.472-.812l1.73-.993a.59.59 0 0 0-.292-1.101h-2.278a.31.31 0 0 0-.293.207l-.015.047a.937.937 0 0 1-1.196.57.94.94 0 0 1-.57-1.195l.016-.047zm2.117 7.293a1.25 1.25 0 1 1 2.5 0 1.25 1.25 0 0 1-2.5 0"
    />
  </svg>
);
const ForwardRef = forwardRef(IconCircleQuestion);
export { ForwardRef as IconCircleQuestion };
