import { LinkProps, ThemeOptions } from "@mui/material";

import LinkBehavior from "../../components/ui/Link/LinkBehavior";
import { alphaColor, contrastColor, toRem } from "../../utils";
import colors from "../theme/colors";
import effects from "../theme/effects";
import rounding from "../theme/rounding";

const commonTheme: ThemeOptions = {
  palette: {
    primary: {
      main: colors.brand.default,
      light: colors.brand[400],
      dark: colors.brand[800],
      ghost: alphaColor(colors.brand.default, 0.05),
      ghostDark: alphaColor(colors.brand.default, 0.1),
      contrastText: contrastColor(colors.brand.default),
    },
    secondary: {
      main: colors.grey[600],
      light: colors.grey[400],
      dark: colors.grey[800],
      contrastText: contrastColor(colors.brand.default),
    },
    success: {
      main: colors.success.default,
      light: colors.success[400],
      dark: colors.success[600],
    },
    warning: {
      main: colors.warning.default,
      light: colors.warning[400],
      dark: colors.warning[600],
    },
    error: {
      main: colors.error.default,
      light: colors.error[400],
      dark: colors.error[600],
    },
    text: {
      primary: colors.text.primaryDark,
      secondary: colors.text.secondaryDark,
      disabled: colors.text.disabledDark,
    },
    base: colors.base,
    grey: colors.grey,
    typography: colors.text,
    gradient: colors.gradient,
  },
  typography: {
    allVariants: {
      fontFamily: "'Avenir', sans-serif",
      textTransform: "none",
    },
    headline1: {
      fontSize: toRem(56),
      lineHeight: toRem(72),
    },
    h1: {
      fontSize: toRem(48),
      lineHeight: toRem(60),
    },
    h2: {
      fontSize: toRem(32),
      lineHeight: toRem(42),
    },
    h3: {
      fontSize: toRem(28),
      lineHeight: toRem(32),
    },
    h4: {
      fontSize: toRem(24),
      lineHeight: toRem(28),
    },
    h5: {
      fontSize: toRem(18),
      lineHeight: toRem(26),
    },
    h6: {
      fontSize: toRem(16),
      lineHeight: toRem(24),
    },
    body1: {
      fontSize: toRem(20),
      lineHeight: toRem(36),
    },
    body2: {
      fontSize: toRem(18),
      lineHeight: toRem(32),
    },
    body3: {
      fontSize: toRem(16),
      lineHeight: toRem(28),
    },
    body4: {
      fontSize: toRem(14),
      lineHeight: toRem(20),
    },
    label1: {
      fontSize: toRem(16),
      lineHeight: toRem(24),
    },
    label2: {
      fontSize: toRem(14),
      lineHeight: toRem(24),
    },
    label3: {
      fontSize: toRem(12),
      lineHeight: toRem(16),
    },
  },
  spacing: 4,
  rounding: rounding,
  effects: effects,
  components: {
    MuiLink: {
      defaultProps: {
        component: LinkBehavior,
      } as LinkProps,
    },
    MuiButtonBase: {
      defaultProps: {
        LinkComponent: LinkBehavior,
      },
    },
  },
};

export default commonTheme;
