import { mergeAttributes, Range } from "@tiptap/core";
import { ReactNodeViewRenderer } from "@tiptap/react";

import EditorImageBlock from "#src/components/common/Editor/extensions/ImageBlock/components/EditorImageBlock";
import { Image } from "../Image";

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    imageBlock: {
      setImageBlock: (attributes: {
        src: string;
        briefBuilderMediaId?: string;
        unsplashId?: string;
      }) => ReturnType;
      setImageBlockAt: (attributes: {
        src: string;
        briefBuilderMediaId?: string;
        unsplashId?: string;
        pos: number | Range;
      }) => ReturnType;
    };
  }
}

export const ImageBlock = Image.extend({
  name: "imageBlock",
  group: "block",
  defining: true,
  isolating: true,
  addAttributes() {
    return {
      src: {
        default: "",
        parseHTML: (element) => element.getAttribute("src"),
        renderHTML: (attributes) => ({
          src: attributes.src,
        }),
      },
      briefBuilderMediaId: {
        default: undefined,
        parseHTML: (element) => element.getAttribute("image-id"),
        renderHTML: (attributes) => ({
          "image-id": attributes.briefBuilderMediaId,
        }),
      },
      unsplashId: {
        default: undefined,
        parseHTML: (element) => element.getAttribute("unsplash-id"),
        renderHTML: (attributes) => ({
          "unsplash-id": attributes.unsplashId,
        }),
      },
      width: {
        default: "100%",
        parseHTML: (element) => element.getAttribute("data-width"),
        renderHTML: (attributes) => ({
          "data-width": attributes.width,
        }),
      },
      align: {
        default: "center",
        parseHTML: (element) => element.getAttribute("data-align"),
        renderHTML: (attributes) => ({
          "data-align": attributes.align,
        }),
      },
      alt: {
        default: undefined,
        parseHTML: (element) => element.getAttribute("alt"),
        renderHTML: (attributes) => ({
          alt: attributes.alt,
        }),
      },
    };
  },
  parseHTML() {
    return [
      {
        tag: 'img[src*="tiptap.dev"]:not([src^="data:"]), img[src*="windows.net"]:not([src^="data:"])',
      },
    ];
  },
  renderHTML({ HTMLAttributes }) {
    return ["img", mergeAttributes(this.options.HTMLAttributes, HTMLAttributes)];
  },
  addCommands() {
    return {
      setImageBlock:
        (attrs) =>
        ({ commands }) => {
          return commands.insertContent({
            type: "imageBlock",
            attrs: {
              src: attrs.src,
              briefBuilderMediaId: attrs.briefBuilderMediaId,
              unsplashId: attrs.unsplashId,
            },
          });
        },

      setImageBlockAt:
        (attrs) =>
        ({ commands }) => {
          return commands.insertContentAt(attrs.pos, {
            type: "imageBlock",
            attrs: {
              src: attrs.src,
              briefBuilderMediaId: attrs.briefBuilderMediaId,
              unsplashId: attrs.unsplashId,
            },
          });
        },
    };
  },
  addNodeView() {
    return ReactNodeViewRenderer(EditorImageBlock);
  },
});

export default ImageBlock;
