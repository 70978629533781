import React, { ReactNode } from "react";

import { Box } from "@mui/material";
import { useLocation } from "react-router";

import TrackingCallbackImage from "#src/components/TrackingCallbackImage";
import DesktopSideNavigation from "#src/components/pages/Feed/ChannelsSidebar/DesktopChannels";
import SuggestedChannels from "#src/components/pages/Feed/ChannelsSidebar/SuggestedChannels";
import useGodView from "#src/containers/GodView/useGodView";
import {
  WrapperPage,
  WrapperContent,
  WrapperPageContent,
} from "#src/containers/layout/styled";
import { isFeedPage } from "#src/utils/feed";

interface Props {
  fullWidth?: boolean;
  children: ReactNode;
}

export default function ResidentialLayout({ fullWidth, children }: Props) {
  const { pathname } = useLocation();
  const { isEnabled: isGodViewEnabled } = useGodView();

  return (
    <WrapperPage>
      <WrapperContent fullWidth={fullWidth}>
        <Box>
          <DesktopSideNavigation />
        </Box>
        <WrapperPageContent>{children}</WrapperPageContent>
        <Box>
          {isFeedPage(pathname) && !isGodViewEnabled && (
            <SuggestedChannels type="desktop" />
          )}
        </Box>
      </WrapperContent>
      <TrackingCallbackImage />
    </WrapperPage>
  );
}
