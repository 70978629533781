import { useDispatch } from "react-redux";

import {
  selectDownloadModal,
  setDownloadModal,
} from "#src/features/downloadModal/downloadModalSlice";
import { setJoinBriefId } from "../../features/joinBriefId/joinBriefIdSlice";
import { useSelectorTyped } from "../../utils";

export default function useDownloadModal() {
  const dispatch = useDispatch();
  const open = useSelectorTyped(selectDownloadModal);
  const onClose = () => {
    dispatch(setDownloadModal(false));
    dispatch(setJoinBriefId(null));
  };

  return {
    open,
    onClose,
  };
}
