import * as React from "react";
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";

const IconLock = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    className="shared-icon"
    ref={ref}
    {...props}
  >
    <path
      fill="currentColor"
      d="M8.625 7v2.5h6.25V7a3.124 3.124 0 1 0-6.25 0M6.75 9.5V7a5 5 0 1 1 10 0v2.5H18c1.379 0 2.5 1.121 2.5 2.5v7.5c0 1.379-1.121 2.5-2.5 2.5H5.5A2.5 2.5 0 0 1 3 19.5V12c0-1.379 1.121-2.5 2.5-2.5zM4.875 12v7.5c0 .344.281.625.625.625H18a.627.627 0 0 0 .625-.625V12a.627.627 0 0 0-.625-.625H5.5a.627.627 0 0 0-.625.625"
    />
  </svg>
);
const ForwardRef = forwardRef(IconLock);
export { ForwardRef as IconLock };
