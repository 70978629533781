import * as React from "react";
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";

const IconListNumber = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    className="shared-icon"
    ref={ref}
    {...props}
  >
    <path
      fill="currentColor"
      d="M3.574 4.857c0-.475.382-.857.857-.857h1.143c.474 0 .857.382.857.857v4.285H7c.476 0 .858.382.858.857a.855.855 0 0 1-.857.857H4.145A.855.855 0 0 1 3.288 10c0-.475.382-.857.857-.857h.571V5.714h-.285a.855.855 0 0 1-.857-.857m2.239 10.184a.547.547 0 0 0-.857.043l-.4.554a.858.858 0 1 1-1.396-.997l.396-.557a2.264 2.264 0 0 1 3.542-.175c.76.872.743 2.175-.039 3.025l-1.243 1.35h1.186c.475 0 .857.382.857.857a.855.855 0 0 1-.857.857H3.859c-.339 0-.65-.2-.785-.514a.86.86 0 0 1 .153-.925l2.571-2.786a.55.55 0 0 0 .011-.732zm4.903-9.898h9.141a1.141 1.141 0 1 1 0 2.285h-9.141a1.141 1.141 0 1 1 0-2.285m0 5.713h9.141a1.141 1.141 0 1 1 0 2.286h-9.141a1.141 1.141 0 1 1 0-2.286m0 5.714h9.141a1.141 1.141 0 1 1 0 2.285h-9.141a1.142 1.142 0 1 1 0-2.285"
    />
  </svg>
);
const ForwardRef = forwardRef(IconListNumber);
export { ForwardRef as IconListNumber };
