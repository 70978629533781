import React, { useEffect, useRef } from "react";

import { styled } from "@mui/material";

import useIsFirstRender from "#shared/hooks/useIsFirstRender";
import ChannelBox from "#src/components/pages/Feed/ChannelsSidebar/SuggestedChannels/components/ChannelBox";
import useMyFollowedChannels from "#src/components/pages/Feed/ChannelsSidebar/useMyFollowedChannels";
import { ChannelListItem } from "#src/types/channel";

interface Props {
  channels: ChannelListItem[];
  onClickChannel: (channelRef: string) => void;
}

const ELEMENT_WIDTH = 300;

export default function ChannelsScrollable({ channels, onClickChannel }: Props) {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const isFirstRender = useIsFirstRender();

  const { channels: followedChannels, isChannelMember } = useMyFollowedChannels();

  useEffect(() => {
    if (wrapperRef.current) {
      wrapperRef.current.scrollTo(0, 0);
    }
  }, [channels]);

  useEffect(() => {
    if (!wrapperRef.current || isFirstRender) {
      return;
    }

    wrapperRef.current.scrollTo({
      left: wrapperRef.current.scrollLeft + ELEMENT_WIDTH,
      behavior: "smooth",
    });
  }, [followedChannels]);

  return (
    <Wrapper ref={wrapperRef}>
      {channels.map((channel) => (
        <ChannelBox
          key={channel.id}
          width={ELEMENT_WIDTH}
          joined={isChannelMember(channel.id)}
          onClick={onClickChannel}
          channel={channel}
        />
      ))}
    </Wrapper>
  );
}

const Wrapper = styled("div")(
  ({ theme }) => `
	box-sizing: border-box;
	margin: 0 auto;
	display: grid;
	grid-auto-flow: column;
	gap: 1rem;
  width: 100%;
	overflow-y: hidden;
	overscroll-behavior-x: contain;
	scroll-snap-type: x mandatory;
	padding-bottom: 1rem;

	${theme.breakpoints.down("sm")} {
		width: calc(100vw - 2rem);
	}
`
);
