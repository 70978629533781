import * as React from "react";
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";

const IconEye = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    className="shared-icon"
    ref={ref}
    {...props}
  >
    <path
      fill="currentColor"
      d="M11.998 5.663c-2.263 0-4.124 1.026-5.551 2.346-1.337 1.24-2.26 2.713-2.733 3.752.472 1.04 1.396 2.513 2.73 3.753 1.43 1.32 3.29 2.346 5.554 2.346s4.125-1.026 5.552-2.346c1.337-1.24 2.26-2.713 2.732-3.753-.472-1.04-1.395-2.512-2.729-3.752-1.43-1.32-3.291-2.346-5.555-2.346m-6.687 1.13C6.947 5.275 9.193 4 11.998 4s5.052 1.275 6.687 2.793c1.625 1.507 2.712 3.305 3.23 4.542a1.1 1.1 0 0 1 0 .853c-.518 1.237-1.605 3.038-3.23 4.542-1.635 1.518-3.881 2.793-6.687 2.793-2.805 0-5.051-1.275-6.687-2.793-1.625-1.504-2.711-3.305-3.225-4.542a1.1 1.1 0 0 1 0-.853c.514-1.237 1.6-3.039 3.225-4.542m6.687 7.74a2.774 2.774 0 0 0 2.778-2.772 2.774 2.774 0 0 0-2.778-2.772h-.07q.07.267.07.555a2.222 2.222 0 0 1-2.777 2.148v.07a2.774 2.774 0 0 0 2.777 2.771m0-7.207c1.179 0 2.31.468 3.143 1.3a4.43 4.43 0 0 1 0 6.272 4.45 4.45 0 0 1-6.285 0 4.43 4.43 0 0 1 0-6.273 4.45 4.45 0 0 1 3.142-1.299"
    />
  </svg>
);
const ForwardRef = forwardRef(IconEye);
export { ForwardRef as IconEye };
