import * as React from "react";
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";

const IconCommentsDuotone = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    className="shared-icon"
    ref={ref}
    {...props}
  >
    <path
      fill="#126AE8"
      d="M9.234 15.94c3.668-.316 6.765-2.953 6.765-6.454q0-.244-.02-.483c3.366.206 6.019 2.578 6.019 5.47 0 1.204-.46 2.317-1.237 3.224.109.293.271.552.443.77a3.6 3.6 0 0 0 .585.587l.006.006a.501.501 0 0 1-.294.904c-.681 0-1.368-.175-1.94-.39a7 7 0 0 1-.788-.355 7.5 7.5 0 0 1-3.274.742c-2.987 0-5.506-1.702-6.265-4.022"
      opacity={0.25}
    />
    <path
      fill="#126AE8"
      d="M14.998 9.487c0 3.03-2.909 5.486-6.499 5.486a7.4 7.4 0 0 1-3.271-.745c-.235.125-.5.247-.79.356-.57.215-1.257.39-1.938.39a.5.5 0 0 1-.294-.905l.006-.006q.011-.007.035-.025.048-.037.134-.115c.113-.103.266-.253.416-.446.172-.218.334-.48.443-.77C2.46 11.803 2 10.69 2 9.487 2 6.457 4.909 4 8.5 4c3.59 0 6.498 2.457 6.498 5.487"
    />
  </svg>
);
const ForwardRef = forwardRef(IconCommentsDuotone);
export { ForwardRef as IconCommentsDuotone };
