import React from "react";

import { IconButton, Typography, styled } from "@mui/material";

import colors from "../../../config/theme/colors";
import { IconClose } from "../../../icons/Close";

interface Props {
  title: string;
  onClose?: () => void;
}

export default function DrawerHeader({ title, onClose }: Props) {
  return (
    <Header>
      <Typography variant="h6" fontWeight="500" color={colors.text.secondaryDark}>
        {title}
      </Typography>
      <IconButton className="btn-close" onClick={onClose}>
        <IconClose />
      </IconButton>
    </Header>
  );
}

const Header = styled("div")`
  position: relative;
  display: flex;
  justify-content: flex-start;
  padding: 0.75rem 1.75rem;
  border-radius: var(--drawer-border-radius);
  border-bottom: 1px solid ${colors.base.outline1};

  & > .btn-close {
    position: absolute;
    top: 0.25rem;
    right: 0.5rem;
    padding: 0.5rem;
    color: ${colors.grey[600]};
  }
`;
