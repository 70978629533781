import { useEffect } from "react";

import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";

import { selectErrors } from "#src/features/errors/errorsSlice";
import { useSelectorTyped } from "../../../utils";

export default function useErrorHandler() {
  const { pathname } = useLocation();
  const errors = useSelectorTyped(selectErrors);
  const navigate = useNavigate();

  useEffect(() => {
    if (errors && Object.keys(errors).length) {
      if (!pathname.includes("/error")) {
        navigate("/error");
      }
    }
  }, [JSON.stringify(errors)]);
}
