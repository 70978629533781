import * as React from "react";
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";

const IconCameraSolid = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 12 12"
    className="shared-icon"
    ref={ref}
    {...props}
  >
    <g clipPath="url(#camera-solid_svg__a)">
      <path
        fill="currentColor"
        d="m3.495 1.519-.244.731H1.5c-.827 0-1.5.673-1.5 1.5v6c0 .827.673 1.5 1.5 1.5h9c.827 0 1.5-.673 1.5-1.5v-6c0-.827-.673-1.5-1.5-1.5H8.75l-.245-.731A1.12 1.12 0 0 0 7.44.75H4.561c-.485 0-.914.31-1.066.769M6 4.5A2.25 2.25 0 1 1 6 9a2.25 2.25 0 0 1 0-4.5"
      />
    </g>
    <defs>
      <clipPath id="camera-solid_svg__a">
        <path fill="currentColor" d="M0 0h12v12H0z" />
      </clipPath>
    </defs>
  </svg>
);
const ForwardRef = forwardRef(IconCameraSolid);
export { ForwardRef as IconCameraSolid };
