// modules
import React, { useEffect, useState } from "react";

import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import { styled } from "@mui/material/styles";
import { FormattedMessage, IntlProvider } from "react-intl";
import { useWindowSize } from "usehooks-ts";

import CustomPopper from "#shared/components/ui/Popper";
// shared
import useCustomPopper from "#shared/components/ui/Popper/useCustomPopper";
import i18n from "#shared/config/ui/i18n";
import { AvenirTitle, PopperMenuWrapper } from "#src/components/common/styled";

const Wrapper = styled("div")`
  .buttonTitle {
    text-transform: capitalize;
  }
`;

const WrapperMenu = styled("div")<{
  height: number;
}>(
  ({ height }) => `
      max-height: ${height - 100}px;
      overflow: auto;

      .menuTitle {
        text-transform: capitalize;
      }
`
);

type LanguageProps = {
  buttonLabel: string;
  onSelectLocale: (locale: string) => void;
};

interface LocaleInfo {
  locale: string;
  messages: Record<string, string>;
}

export default function Language({ buttonLabel, onSelectLocale }: LanguageProps) {
  const [anchorRef, open, setOpen] = useCustomPopper<HTMLDivElement>();
  const [localeInfo, setLocaleInfo] = useState<LocaleInfo[]>();

  const { height } = useWindowSize();

  useEffect(() => {
    // load all translations so that we can show localised names of each language
    const getMessages = async () => {
      try {
        const locales = i18n.getAvailableLocales();

        const multiLangMessagesArr = await Promise.all(
          locales.map(async (locale) => {
            const messagesJSON = await import(`../../../../locales/${locale}.json`);
            const messages = await i18n.getMessages(locale, messagesJSON);
            if (!messages) {
              return undefined;
            }

            return { locale, messages };
          })
        );

        setLocaleInfo(multiLangMessagesArr.filter(Boolean) as LocaleInfo[]);
      } catch (error) {
        console.error(error);
      }
    };

    getMessages();
  }, []);

  return (
    <Wrapper>
      <AvenirTitle
        className="buttonTitle"
        ref={anchorRef}
        fontFamily="Avenir"
        fontSize=".875rem"
        fontWeight={600}
        style={{
          cursor: "pointer",
        }}
        onClick={() => setOpen(true)}
      >
        {buttonLabel}
      </AvenirTitle>
      <CustomPopper
        anchorEl={anchorRef.current}
        open={open}
        onClickAway={() => setOpen(false)}
        placement="bottom-end"
        modifiers={[
          {
            name: "flip",
            enabled: false,
          },
        ]}
      >
        <PopperMenuWrapper>
          <AvenirTitle
            className="title"
            fontFamily="Avenir-heavy"
            fontSize=".875rem"
            lineHeight={".5rem"}
          >
            <FormattedMessage id="header.language" />
          </AvenirTitle>

          <Divider />
          <WrapperMenu height={height}>
            <MenuList sx={{ border: "none", ".MuiMenuItem-root": { border: "none" } }}>
              {localeInfo?.map(({ locale, messages }) => (
                <MenuItem
                  sx={{
                    padding: "0px 32px 0px 12px",
                  }}
                  key={locale}
                  onClick={() => onSelectLocale(locale)}
                >
                  <ListItemText>
                    <AvenirTitle
                      className="menuTitle"
                      fontFamily="Avenir"
                      fontSize=".875rem"
                      fontWeight={500}
                      lineHeight="2.625rem"
                    >
                      {/* we want to show the localised language name */}
                      <IntlProvider locale={"en"} messages={messages}>
                        <FormattedMessage id={`locales.${locale}`} />
                      </IntlProvider>
                    </AvenirTitle>
                  </ListItemText>
                </MenuItem>
              ))}
            </MenuList>
          </WrapperMenu>
        </PopperMenuWrapper>
      </CustomPopper>
    </Wrapper>
  );
}
