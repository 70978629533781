import { useMemo } from "react";

import { useIntl } from "react-intl";

import { COUNTRY_EMOJIS } from "#shared/constants/countryEmojis";
import { useFetchCountriesQuery } from "#src/features/common/commonSlice";

export default function useBuilderCountries() {
  const { formatMessage } = useIntl();

  const { data: countriesData = [] } = useFetchCountriesQuery();

  return useMemo(
    () =>
      countriesData.map((country) => {
        const countryEmoji = COUNTRY_EMOJIS[country.code.toLowerCase()]?.emoji;
        const countryName = formatMessage({
          id: `demographics.countries.${
            country.code === "SJ" ? country.code + "-" + country.countryId : country.code
          }`,
          defaultMessage: "",
        });
        const emojiPrefix = countryEmoji ? `${countryEmoji} ` : "";

        return {
          label: `${emojiPrefix}${countryName}`,
          value: country.countryId,
        };
      }),
    [countriesData]
  );
}
