import * as React from "react";
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";

const IconRevert = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    className="shared-icon"
    ref={ref}
    {...props}
  >
    <path
      fill="currentColor"
      d="M9.303 8.134a6.069 6.069 0 1 1 .331 9.445.826.826 0 1 0-.993 1.324 7.725 7.725 0 1 0-.51-11.941L7.079 5.907A1.219 1.219 0 0 0 5 6.769v4.024c0 .459.369.828.828.828h4.024a1.217 1.217 0 0 0 .862-2.08L9.307 8.134zm-2.648-.31 2.142 2.142H6.655z"
    />
  </svg>
);
const ForwardRef = forwardRef(IconRevert);
export { ForwardRef as IconRevert };
