import React, { useEffect } from "react";

import { CircularProgress } from "@mui/material";

import { Dropzone } from "#shared/components/ui";
import { EditorImageProps } from "#src/components/common/Editor/extensions/ImageUpload/EditorImageUploadView";
import EditorImagePlaceholder from "#src/components/common/Editor/extensions/ImageUpload/Upload/components/EditorImagePlaceholder";
import useEditorImageUpload from "#src/components/common/Editor/extensions/ImageUpload/Upload/useEditorImageUpload";

export default function EditorImageUpload({ editor, getPos }: EditorImageProps) {
  const { image, error, isLoading, handleImageChange, handleInternalError } =
    useEditorImageUpload();

  useEffect(() => {
    if (!image || isLoading) {
      return;
    }

    editor
      .chain()
      .setImageBlock({
        src: image.url,
        briefBuilderMediaId: image.id,
      })
      .deleteRange({ from: getPos(), to: getPos() })
      .focus()
      .run();
  }, [image, isLoading]);

  const dropzoneContent = () => {
    if (isLoading) {
      return <CircularProgress color="primary" />;
    }

    return <EditorImagePlaceholder />;
  };

  return (
    <Dropzone
      error={error}
      onChange={handleImageChange}
      onError={handleInternalError}
      mediaType="image"
    >
      {dropzoneContent()}
    </Dropzone>
  );
}
