import { useCallback, useMemo } from "react";

import { useGlobalAppProvider } from "#src/containers/Global/GlobalAppProvider";
import { ChannelApprovalStatus, ChannelListItem } from "#src/types/channel";
import { OrganisationPositionType } from "#src/types/types";

export default function useMyOwnedChannels() {
  const { positions, ownedChannels } = useGlobalAppProvider();

  const filterOwnedChannel = useCallback(
    (channel: ChannelListItem) => {
      if (!channel.isVerified) {
        return true;
      }

      return positions.some(
        (position) =>
          position.organisation.id === channel.organisationId &&
          [OrganisationPositionType.Owner, OrganisationPositionType.Admin].includes(
            position.id
          )
      );
    },
    [positions]
  );

  const approvedChannels = useMemo(() => {
    return ownedChannels.filter(
      (channel) =>
        channel.approvedStatus === ChannelApprovalStatus.APPROVED &&
        filterOwnedChannel(channel)
    );
  }, [ownedChannels, filterOwnedChannel]);

  const approvedBusinessChannels = useMemo(() => {
    return ownedChannels.filter(
      (channel) =>
        channel.approvedStatus === ChannelApprovalStatus.APPROVED &&
        channel.isVerified &&
        filterOwnedChannel(channel)
    );
  }, [ownedChannels, filterOwnedChannel]);

  return {
    channels: ownedChannels,
    approvedChannels: approvedChannels ?? [],
    approvedBusinessChannels: approvedBusinessChannels ?? [],
    hasOwnedChannels: !!ownedChannels.length,
  };
}
