import Emoji from "@tiptap-pro/extension-emoji";
import Color from "@tiptap/extension-color";
import Focus from "@tiptap/extension-focus";
import Placeholder from "@tiptap/extension-placeholder";
import { TaskItem } from "@tiptap/extension-task-item";
import { TextAlign } from "@tiptap/extension-text-align";
import { TextStyle } from "@tiptap/extension-text-style";
import { Typography } from "@tiptap/extension-typography";
import { StarterKit } from "@tiptap/starter-kit";

import BlockquoteFigure from "#src/components/common/Editor/extensions/BlockquoteFigure";
import { Document } from "#src/components/common/Editor/extensions/Document";
import EmojiSuggestion from "#src/components/common/Editor/extensions/EmojiSuggestion";
import FontSize from "#src/components/common/Editor/extensions/FontSize";
import Heading from "#src/components/common/Editor/extensions/Heading";
import HorizontalRule from "#src/components/common/Editor/extensions/HorizontalRule";
import ImageBlock from "#src/components/common/Editor/extensions/ImageBlock";
import ImageUpload from "#src/components/common/Editor/extensions/ImageUpload";
import MagicImage from "#src/components/common/Editor/extensions/MagicImage";
import MagicWriter from "#src/components/common/Editor/extensions/MagicWriter";
import Selection from "#src/components/common/Editor/extensions/Selection";
import SlashCommand from "#src/components/common/Editor/extensions/SlashCommand";
import { TableCell } from "#src/components/common/Editor/extensions/Table/Cell";
import TableHeader from "#src/components/common/Editor/extensions/Table/Header";
import TableRow from "#src/components/common/Editor/extensions/Table/Row";
import Table from "#src/components/common/Editor/extensions/Table/Table";
import { getTiptapTranslation } from "#src/components/common/Editor/utils/getTiptapTranslation";

const extensions = [
  Document,
  TaskItem.configure({
    nested: true,
  }),
  Selection,
  HorizontalRule,
  StarterKit.configure({
    horizontalRule: false,
  }),
  MagicWriter,
  MagicImage,
  Color,
  Emoji.configure({
    enableEmoticons: true,
    suggestion: EmojiSuggestion,
  }),
  TextStyle,
  TextAlign.extend({
    addKeyboardShortcuts() {
      return {};
    },
  }).configure({
    types: ["heading", "paragraph"],
  }),
  ImageUpload.configure({
    clientId: "",
  }),
  ImageBlock,
  Focus.configure({
    mode: "shallowest",
  }),
  Placeholder.configure({
    includeChildren: true,
    showOnlyCurrent: false,
    placeholder: ({ editor, node }) => {
      switch (node.type.name) {
        case "paragraph":
          return node.childCount === 0
            ? getTiptapTranslation(
                // This can be triggered before the editor is initialized
                editor.isMobileView?.()
                  ? "placeholders.paragraph_mobile"
                  : "placeholders.paragraph"
              )
            : "";
        case "heading":
          return getTiptapTranslation("placeholders.heading");
        case "quoteCaption":
          return getTiptapTranslation("placeholders.quote_author");
        default:
          return "";
      }
    },
  }),
  SlashCommand,
  FontSize,
  Typography,
  Heading.configure({
    levels: [1, 2, 3, 4, 5, 6],
  }),
  BlockquoteFigure,
  Table,
  TableCell,
  TableHeader,
  TableRow,
];

export default extensions;
