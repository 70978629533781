import { useEffect } from "react";

import TagManager from "react-gtm-module";

import { isBrowser } from "#shared/utils";
import env from "#src/env";

const { VITE_GOOGLE_TAGS_ID } = env;

export default function useGoogleTags(): void {
  useEffect(() => {
    if (!VITE_GOOGLE_TAGS_ID) return;

    if (isBrowser) {
      TagManager.initialize({ gtmId: VITE_GOOGLE_TAGS_ID });
    }
  }, []);
}
