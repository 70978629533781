import { client } from "#src/apis/axios";
import { GetWlaOrganisationResponse } from "#src/types/organisation";
import { DEFAULT_ORGANISATION_DOMAIN } from "#src/utils/organisation";

export namespace OrganisationsApi {
  const BASE_PREFIX = "/community/organisations";

  export const getWLAOrganisation = async (domain: string) => {
    // Skip fetching if the domain is the default organisation domain
    if (domain === DEFAULT_ORGANISATION_DOMAIN) {
      return null;
    }

    const { data } = await client.get<GetWlaOrganisationResponse>(
      `${BASE_PREFIX}/domain/${domain}`
    );

    return data;
  };
}
