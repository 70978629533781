import dayjs from "dayjs";

import { BriefStatus } from "#src/types/types";
import { Brief } from "./types/db";

export const importMessages = (locale: string) => import(`./locales/${locale}.json`);

export const getIsScreenerBrief = ({ isPublic, audition }: Brief) => isPublic || audition;

export const getBriefStatus = (brief?: Brief): BriefStatus => {
  if (!brief) {
    return BriefStatus.closed;
  }

  const closed =
    brief.isRemoved ||
    !brief.isActivated ||
    (brief.maxResponses > 0 && brief.totalResponsesCount >= brief.maxResponses);

  const completed =
    (closed && brief.userResponsesCount > 0) ||
    (brief.responseLimit > 0 && brief.userResponsesCount >= brief.responseLimit);

  if (completed) {
    return BriefStatus.completed;
  }

  if (closed) {
    return BriefStatus.closed;
  }

  const today = dayjs();

  if (today.isAfter(dayjs(brief.endsOn))) {
    return BriefStatus.expired;
  }

  if (today.isBefore(dayjs(brief.startsOn))) {
    return BriefStatus.scheduled;
  }

  return BriefStatus.active;
};

export const getUrlSearchParams = (
  searchParams: URLSearchParams
): Record<string, string> => {
  let dict = {};
  for (const [key, value] of searchParams.entries()) {
    dict = { ...dict, [key]: value };
  }
  return dict;
};
