import React, { useMemo, useState } from "react";

import { Box, IconButton, styled } from "@mui/material";

import { IconFlag } from "#shared/icons/Flag";
import { IconMore } from "#shared/icons/More";
import MenuDropdown from "#src/components/common/Dropdown/MenuDropdown";
import ReportChannelModal from "#src/components/pages/ChannelDetails/ChannelHeader/modals/ReportChannelModal";
import useChannelPermissions from "#src/components/pages/ChannelDetails/useChannelPermissions";
import useSelectedChannel from "#src/components/pages/ChannelDetails/useSelectedChannel";
import { MenuItemProps } from "#src/types/menu";
import useTranslation from "#src/utils/useTranslation";

export default function ChannelMoreActions() {
  const { t } = useTranslation();

  const { channelRef } = useSelectedChannel();
  const { canEditChannel, isChannelResearcher } = useChannelPermissions();

  const [isReportModalOpen, setIsReportModalOpen] = useState(false);

  const items: MenuItemProps[] = useMemo(
    () => [
      {
        id: "report",
        label: t("ui.channel_actions.actions.report_channel"),
        hidden: canEditChannel || isChannelResearcher,
        Icon: IconFlag,
        onClick: () => setIsReportModalOpen(true),
      },
    ],
    [canEditChannel, isChannelResearcher]
  );

  const isVisible = items.some((item) => !item.hidden);

  if (!isVisible) {
    return null;
  }

  return (
    <>
      <Wrapper>
        <MenuDropdown
          AnchorElement={
            <IconButton size="small" color="blured">
              <IconMore />
            </IconButton>
          }
          items={items}
        />
      </Wrapper>

      <ReportChannelModal
        channelRef={channelRef}
        isOpen={isReportModalOpen}
        onClose={() => setIsReportModalOpen(false)}
      />
    </>
  );
}

const Wrapper = styled(Box)`
  position: absolute;
  top: 10px;
  right: 10px;
`;
