import { useNavigate } from "react-router";

import useToast from "#shared/hooks/useToast";
import useJoinChannelMutation from "#src/apis/channels/hooks/useJoinChannelMutation";
import useLeaveChannelMutation from "#src/apis/channels/hooks/useLeaveChannelMutation";
import { ChannelListItem } from "#src/types/channel";
import { validateWebsite } from "#src/utils/index";
import useTranslation from "#src/utils/useTranslation";

interface Props {
  onAfterJoin?: () => void;
}

export default function useChannelActions({ onAfterJoin }: Props = {}) {
  const { t } = useTranslation();
  const toast = useToast();
  const navigate = useNavigate();

  const { mutate: joinChannelMutation, isPending: isLoadingJoin } =
    useJoinChannelMutation({
      onSuccess: (_, params) => {
        toast.success({
          message: t("ui.channel_header.toasts.join_success", {
            channelName: params.channel?.name,
          }),
        });
        onAfterJoin?.();
      },
      onError: () => toast.error(),
    });

  const { mutate: leaveChannelMutation, isPending: isLoadingLeave } =
    useLeaveChannelMutation({
      onSuccess: (_, params) => {
        toast.success({
          message: t("ui.channel_header.toasts.leave_success", {
            channelName: params.channel?.name,
          }),
        });
      },
      onError: () => toast.error(),
    });

  const joinChannel = async (channel: ChannelListItem) => {
    try {
      if (channel.screener) {
        // If the channel has a briefRef, navigate to the brief
        if (channel.screener.briefRef) {
          navigate(`/screener/${channel.screener.briefRef}`);
          return false;
        }

        // If the channel has external screener url, open the screener
        if (!channel.screener.url) {
          throw new Error("No screener url");
        }

        if (!validateWebsite(channel.screener.url)) {
          throw new Error("Invalid screener url");
        }

        let url = channel.screener.url;
        if (!url.startsWith("http")) {
          url = `https://${url}`;
        }

        window.open(url, "_self");
        return false;
      }

      joinChannelMutation({ channelRef: channel.channelRef, channel });
    } catch (e) {
      toast.error();
      throw e;
    }
  };

  const leaveChannel = (channel: ChannelListItem) => {
    leaveChannelMutation({ channelRef: channel.channelRef, channel });
  };

  return { joinChannel, leaveChannel, isLoadingJoin, isLoadingLeave };
}
