import { useCallback } from "react";

import { useNavigate } from "react-router";

import useToast from "#shared/hooks/useToast";
import { useCreateEmptyBriefMutation } from "#src/features/briefBuilder/briefBuilderAPI";

export default function useBuilderCreateBrief() {
  const toast = useToast();
  const navigate = useNavigate();

  const [createBriefMutation, { isLoading: isLoadingCreateBrief }] =
    useCreateEmptyBriefMutation();

  const createBrief = useCallback(async (organisationId: number, channelRef: string) => {
    try {
      const response = await createBriefMutation({
        organisationId,
        channelRef,
      }).unwrap();

      if (!response.briefRef) {
        throw new Error("Failed to create brief");
      }

      navigate(`/brief-builder/${response.briefRef}/brief`);
    } catch (error) {
      console.error(error);
      toast.error();
    }
  }, []);

  return {
    createBrief,
    isLoadingCreateBrief,
  };
}
