import React from "react";

import { Button } from "@mui/material";
import dayjs from "dayjs";

import { LoadingButton } from "#shared/components/ui";
import button from "#shared/config/theme/button";
import { BuilderLaunchFormFields } from "#src/components/pages/BriefBuilder/Launch/hooks/useBuilderLaunchForm";
import { useBriefBuilder } from "#src/containers/BriefBuilder/BriefBuilderProvider";
import useBriefBuilderActions from "#src/containers/BriefBuilder/hooks/useBriefBuilderActions";
import { BriefBuilderStep } from "#src/types/briefBuilder";
import useTranslation from "#src/utils/useTranslation";

interface Props {
  onDiscard: () => void;
}

export default function BuilderDesktopActions({ onDiscard }: Props) {
  const { t } = useTranslation();

  const { isStepValid, formValues, activeStep } = useBriefBuilder();
  const { handleViewBrief, handleSave, handleContinue, mainAction, isLoading } =
    useBriefBuilderActions();

  const continueButtonText = () => {
    if (mainAction === "save") {
      return t("ui.buttons.publish");
    }

    if (activeStep === BriefBuilderStep.Launch) {
      const values = formValues as BuilderLaunchFormFields;

      if (values.startsOn && dayjs(values.startsOn).isAfter(dayjs())) {
        return t("ui.buttons.schedule");
      }

      return t("ui.buttons.launch");
    }

    return t("ui.buttons.continue");
  };

  if (mainAction === "view") {
    return (
      <Button
        size="small"
        variant="contained"
        onClick={handleViewBrief}
        sx={{ minWidth: button.width.s }}
      >
        {t("ui.buttons.view_brief")}
      </Button>
    );
  }

  return (
    <>
      <Button size="small" variant="text" onClick={onDiscard}>
        {t("ui.buttons.exit")}
      </Button>
      {activeStep === BriefBuilderStep.Survey && (
        <Button
          size="small"
          variant="outlined"
          onClick={() => handleSave({ isSurveyPreviewInit: true })}
          sx={{ minWidth: button.width.xs }}
          disabled={isLoading || !isStepValid}
        >
          {t("ui.buttons.survey_preview")}
        </Button>
      )}
      <LoadingButton
        size="small"
        variant="contained"
        disabled={!isStepValid}
        loading={isLoading}
        onClick={() => (mainAction === "continue" ? handleContinue() : handleSave())}
        sx={{ minWidth: button.width.s }}
      >
        {continueButtonText()}
      </LoadingButton>
    </>
  );
}
