import * as React from "react";
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";

const IconUser = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    className="shared-icon"
    ref={ref}
    {...props}
  >
    <path
      fill="currentColor"
      d="M15.75 7.625a3.75 3.75 0 1 0-7.5 0 3.75 3.75 0 0 0 7.5 0m-9.375 0a5.625 5.625 0 1 1 11.25 0 5.625 5.625 0 0 1-11.25 0m-2.45 12.5h16.15a4.425 4.425 0 0 0-4.376-3.75H8.301a4.43 4.43 0 0 0-4.375 3.75M2 20.801A6.3 6.3 0 0 1 8.3 14.5h7.4a6.3 6.3 0 0 1 6.3 6.3 1.2 1.2 0 0 1-1.2 1.2H3.2c-.66 0-1.2-.54-1.2-1.2"
    />
  </svg>
);
const ForwardRef = forwardRef(IconUser);
export { ForwardRef as IconUser };
