import { useEffect, useState } from "react";

import queryString from "query-string";
import { useDispatch, useStore } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { useLocation, useSearchParams } from "react-router-dom";

import fetchUserByFingerprint from "#src/apis/user/fetchUserByFingerprint";
import { commonApi } from "#src/features/common/commonSlice";
import { selectUser } from "#src/features/user/userSlice";
import { RootState } from "../../../store";
import {
  APIResponse,
  OnSubmitProfileFormProps,
  ProfileDetailsForm,
  ScreenerSuccessViewProps,
} from "../../../types/types";
import { useSelectorTyped } from "../../../utils";
import getFingerprint from "../../../utils/getFingerprint";
import hasProfileDetails from "../../../utils/hasProfileDetails";

type UseSubmissionProfileFormProps = {
  onSubmitProfileForm: (props: OnSubmitProfileFormProps) => Promise<void>;
};
export default function useSubmissionProfileForm({
  onSubmitProfileForm,
}: UseSubmissionProfileFormProps): ScreenerSuccessViewProps<any> {
  const location = useLocation();
  const navigate = useNavigate();
  const store = useStore<RootState>();
  const queries = queryString.parse(location.search) as {
    fingerprintId: string;
  };

  const user = useSelectorTyped(selectUser);

  const useBirthday = useState<string>(user.profile?.birthday || "");
  const useGender = useState<string>(user.profile?.gender || "");
  const useCountryId = useState<string>(user.profile.countryId + "" || "");
  const useEmail = useState<string>(user.profile?.email || "");
  const displayName = user.profile?.email ? user.profile?.email.split("@")[0] : "";
  const useDisplayName = useState<string>(user.profile?.displayName || displayName);

  const [isLoading, setIsLoading] = useState(false);
  const [apiError, setAPIError] = useState<APIResponse<any>>();

  const { briefRef } = useParams();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const [hasCompletedProfile, setHasCompletedProfile] = useState<boolean>(false);

  const [, setBirthday] = useBirthday;
  const [, setGender] = useGender;
  const [, setCountryId] = useCountryId;
  const [email, setEmail] = useEmail;
  const [, setDisplayName] = useDisplayName;

  const hasProfile = hasProfileDetails(user.profile);

  const { data: countries = [] } = commonApi.endpoints.fetchCountries.select(undefined)({
    commonSlice: store.getState().commonSlice,
  });

  // fetch user from fingerprint
  // populate user profile details
  useEffect(() => {
    async function fetch() {
      const fingerprint = queries.fingerprintId || (await getFingerprint());
      const profile = await fetchUserByFingerprint(fingerprint);
      if (user.isLoggedIn && hasProfile) {
        navigate("/feed");
        return;
      }

      if (
        location.pathname.includes("/screener/success") &&
        profile &&
        (!email || profile.email === email)
      ) {
        const { birthday, countryId, email, gender, displayName } = profile;
        if (hasProfile) {
          setHasCompletedProfile(true);
        }
        setEmail(email);
        setBirthday(birthday || "");
        setCountryId(countryId + "");
        setGender(gender || "");
        setDisplayName(displayName || email.split("@")[0]);
      }
    }

    fetch();
  }, [queries.fingerprintId]);

  const onSubmit = async (profile: ProfileDetailsForm) => {
    setIsLoading(true);
    await onSubmitProfileForm({
      profile,
      dispatch,
      user,
      navigate,
      briefRef,
      fingerprintId: queries.fingerprintId,
      urlSearchParams: searchParams,
      hasCompletedProfile,
    });
    setIsLoading(false);
  };

  return {
    useDisplayName,
    useGender,
    useBirthday,
    useCountryId,
    useEmail,
    useAPIError: [apiError, setAPIError],
    onSubmit,
    isUserLoggedIn: !!user.isLoggedIn,
    countries,
    hasCompletedProfile,
    isSubmitLoading: isLoading,
  };
}
