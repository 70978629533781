import * as React from "react";
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";

const IconHouseSolid = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    className="shared-icon"
    ref={ref}
    {...props}
  >
    <path
      fill="currentColor"
      d="M22.994 11.87c0 .688-.573 1.227-1.222 1.227H20.55l.026 6.119q0 .154-.019.31v.614c0 .845-.683 1.528-1.528 1.528h-.61q-.064 0-.127-.003-.08.004-.16.003h-2.159a1.527 1.527 0 0 1-1.527-1.528V16.78c0-.676-.547-1.222-1.223-1.222H10.78c-.677 0-1.223.546-1.223 1.222v3.361c0 .845-.683 1.528-1.528 1.528H5.893a3 3 0 0 1-.172-.007 2 2 0 0 1-.137.007h-.611a1.527 1.527 0 0 1-1.528-1.528v-4.278q0-.053.003-.107v-2.658H2.222A1.21 1.21 0 0 1 1 11.87c0-.344.115-.65.382-.917l9.794-8.537c.267-.268.573-.306.84-.306.268 0 .573.076.802.267l9.756 8.576c.306.267.458.573.42.917"
    />
  </svg>
);
const ForwardRef = forwardRef(IconHouseSolid);
export { ForwardRef as IconHouseSolid };
