import React, { useEffect, useState } from "react";

import { Box, IconButton, Typography, styled } from "@mui/material";
import { FormattedMessage } from "react-intl";

import { LoadingButton } from "#shared/components/ui";
import colors from "#shared/config/theme/colors";
import rounding from "#shared/config/theme/rounding";
import useToast from "#shared/hooks/useToast";
import { IconClose } from "#shared/icons/Close";
import { alphaColor } from "#shared/utils";
import useUpdateUserInterests from "#src/apis/interests/hooks/useUpdateUserInterests";
import SelectInterests from "#src/components/common/Interests/SelectInterests";
import useInterests from "#src/components/common/Interests/useInterests";

interface Props {
  interests: number[];
  onClose: () => void;
  onConfirm: () => void;
}

export default function SuggestedChannelsInterests({
  interests,
  onClose,
  onConfirm,
}: Props) {
  const prefixIntl = "pages.feed.filters.suggested_channels.select_interests";

  const toast = useToast();
  const [isExpanded, setIsExpanded] = useState(!!interests.length);
  const [isClosing, setIsClosing] = useState(false);

  const { getInterests } = useInterests();

  const topInterests = getInterests({ orderBy: "popularity", limit: 6 });
  const otherInterests = getInterests({
    orderBy: "alphabetical",
    exclude: topInterests.map((i) => i.value),
  });

  const [selectedInterests, setSelectedInterests] = useState<number[]>(interests);

  const { mutate: updateInterests, isPending: isLoading } = useUpdateUserInterests({
    onError: () => {
      toast.error();
    },
    onSettled: () => {
      handleClose(true);
    },
  });

  useEffect(() => {
    setSelectedInterests(interests);
  }, [interests]);

  const handleClose = (success: boolean) => {
    setIsClosing(true);

    setTimeout(() => {
      success ? onConfirm() : onClose();
    }, 300);
  };

  const handleConfirmClick = async () => {
    updateInterests({
      interests: selectedInterests,
    });
  };

  const handleInterestChange = (value: number[]) => {
    setSelectedInterests(value);
    setIsExpanded(true);
  };

  const handleShowMore = () => {
    setIsExpanded(true);
  };

  return (
    <Wrapper closing={isClosing}>
      <Box display="flex" justifyContent="space-between">
        <Typography variant="label2" fontFamily="Avenir-heavy" color="white" mb={1}>
          <FormattedMessage id={`${prefixIntl}.title`} />
        </Typography>
        <CloseButton size="small" onClick={() => handleClose(false)}>
          <IconClose />
        </CloseButton>
      </Box>

      <Typography variant="label3" color="white" mb={4}>
        <FormattedMessage id={`${prefixIntl}.description`} />
      </Typography>

      <Typography variant="label3" color="white" fontWeight="500" mb={2}>
        <FormattedMessage id={`${prefixIntl}.most_popular`} />
      </Typography>

      <SelectInterests
        interests={topInterests}
        value={selectedInterests}
        max={100}
        onChange={handleInterestChange}
        sx={{ mb: 2 }}
      />

      <ExpandedListWrapper expanded={isExpanded}>
        <SelectInterests
          interests={otherInterests}
          value={selectedInterests}
          max={100}
          onChange={handleInterestChange}
          sx={{ mt: 0 }}
        />
      </ExpandedListWrapper>

      <LoadingButton
        variant={isExpanded ? "contained" : "text"}
        size="small"
        loading={!!isLoading}
        fullWidth
        onClick={isExpanded ? handleConfirmClick : handleShowMore}
      >
        <FormattedMessage id={isExpanded ? "ui.buttons.save" : "ui.buttons.show_more"} />
      </LoadingButton>
    </Wrapper>
  );
}

const Wrapper = styled("div")<{ closing: boolean }>(
  ({ theme, closing }) => `
    padding: 1rem;
    background-color: ${theme.palette.primary.main};
    border-radius: ${rounding.default};
    animation: fadeIn 0.5s forwards;

    & .MuiChip-root {
      border-color: ${theme.palette.common.white};
      color: ${theme.palette.common.white};

      &.MuiChip-outlined.MuiChip-clickable:hover {
        background: ${theme.palette.primary.light};
      }

      &.MuiChip-filled {
        background-color: ${theme.palette.common.white};
        color: ${theme.palette.primary.main};
        border: 1px solid ${theme.palette.common.white};
      }
    }

    & .MuiButton-containedPrimary {
      background-color: ${theme.palette.common.white};
      color: ${theme.palette.primary.main};

      &:hover {
        background-color: ${theme.palette.common.white};
      }
    }

    & .MuiButton-text {
      color: ${theme.palette.common.white};
    }

    ${closing && `animation: fadeOut 0.5s forwards;`}

  `
);

const ExpandedListWrapper = styled("div")<{ expanded: boolean }>(
  ({ expanded }) => `
  margin-top: 0.75rem;
  overflow: hidden;
  max-height: ${expanded ? "500px" : "0"};
  transition: max-height 0.5s ease-in-out;
`
);

const CloseButton = styled(IconButton)`
  width: 1.5rem;
  height: 1.5rem;
  margin-top: 0rem;
  color: ${colors.base.white};
  padding: 0;

  &:hover {
    background-color: ${alphaColor(colors.base.white, 0.2)};
  }

  svg {
    width: 1rem;
    height: 1rem;
  }
`;
