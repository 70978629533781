import React, { useMemo } from "react";

import { MenuItem, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router";

import { Select } from "#shared/components/ui";
import { useMediaQueryProvider } from "#shared/context/MediaQueryProvider";
import { IconBriefcase } from "#shared/icons/Briefcase";
import { IconPlus } from "#shared/icons/Plus";
import useOrganisationPermissions from "#src/components/pages/BusinessAccount/useOrganisationPermissions";
import useSelectedOrganisation from "#src/components/pages/BusinessAccount/useSelectedOrganisation";
import { useGlobalAppProvider } from "#src/containers/Global/GlobalAppProvider";
import { setSelectedOrganisationId } from "#src/features/businessSettings/businessSettingsSlice";
import useTranslation from "#src/utils/useTranslation";
import { OrganisationStatus, OrganisationPositionType } from "../../../../types/types";

interface Props {
  value?: number;
  includePersonal?: boolean;
  outlined?: boolean;
}

export default function SelectOrganisation({ value, includePersonal, outlined }: Props) {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { isMobile } = useMediaQueryProvider();

  const { organisationId } = useSelectedOrganisation();
  const { positions } = useGlobalAppProvider();
  const { canCreateNewOrganisation } = useOrganisationPermissions();

  const personalLabel = t("pages.feed.filters.personal");

  const filteredOrganisations = useMemo(() => {
    const filtered =
      positions?.filter(
        (c) =>
          [OrganisationPositionType.Owner, OrganisationPositionType.Admin].includes(
            c.id
          ) && c.organisation.status === OrganisationStatus.VERIFIED
      ) || [];

    if (includePersonal) {
      return [
        ...filtered,
        {
          organisation: {
            id: 0,
            name: personalLabel,
          },
        },
      ];
    }

    return filtered;
  }, [positions, includePersonal]);

  const handleSelectOrganisation = (id: number) => {
    if (!id) return;

    if (isMobile) {
      dispatch(setSelectedOrganisationId(id));
    }
    navigate(pathname.replace(String(organisationId), String(id)));
  };

  const handleAddNewOrganisation = () => {
    navigate("/business-lab");
  };

  if (!filteredOrganisations.length) {
    return null;
  }

  return (
    <Select
      id="select_organisation"
      title={t("ui.select.organisation")}
      items={filteredOrganisations.map((item) => ({
        label: item.organisation.name,
        value: item.organisation.id,
      }))}
      variant={outlined ? "outlined" : "standard"}
      startAdornment={outlined ? <IconBriefcase width={22} height={22} /> : undefined}
      renderValue={(selected) => {
        const selectedLabel =
          filteredOrganisations.find((x) => x.organisation.id === selected)?.organisation
            .name || personalLabel;

        return (
          <Typography
            variant={outlined ? "label1" : "label2"}
            fontWeight={outlined ? "500" : "600"}
            fontFamily={outlined ? "Avenir" : "Avenir-Heavy"}
            mr={1}
          >
            {selectedLabel}
          </Typography>
        );
      }}
      color={outlined ? "primary" : "secondary"}
      value={value ?? organisationId}
      onChange={(value) => handleSelectOrganisation(Number(value))}
    >
      {!includePersonal && canCreateNewOrganisation && (
        <MenuItem onClick={handleAddNewOrganisation}>
          <Typography
            variant="label2"
            color="primary.main"
            display="flex"
            alignItems="center"
            gap={2}
          >
            <IconPlus width={20} height={20} />
            <FormattedMessage id="pages.business_settings.add_new_organisation" />
          </Typography>
        </MenuItem>
      )}
    </Select>
  );
}
