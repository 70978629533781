import * as React from "react";
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";

const IconRotateLeft = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    className="shared-icon"
    ref={ref}
    {...props}
  >
    <path
      fill="currentColor"
      d="M7.38 5.918a7.586 7.586 0 1 1 .414 11.806 1.033 1.033 0 0 0-1.45.207 1.033 1.033 0 0 0 .208 1.448 9.6 9.6 0 0 0 5.793 1.931c5.332 0 9.655-4.323 9.655-9.655S17.677 2 12.345 2c-2.47 0-4.724.927-6.431 2.453l-1.315-1.32A1.523 1.523 0 0 0 2 4.212v5.03c0 .574.461 1.035 1.034 1.035h5.03c.841 0 1.522-.681 1.522-1.522 0-.405-.16-.788-.444-1.077L7.384 5.918zM4.068 5.53l2.677 2.677H4.069z"
    />
  </svg>
);
const ForwardRef = forwardRef(IconRotateLeft);
export { ForwardRef as IconRotateLeft };
