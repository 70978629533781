import { useCallback } from "react";

import { AxiosError, AxiosResponse } from "axios";

import { client } from "#src/apis/axios";
import { AI_CHAT_STATIC_MESSAGES } from "#src/components/common/AIChat/consts";
import { useAIChatProvider } from "#src/components/common/AIChat/context/AIChatProvider";
import {
  AIChatMessageProps,
  AIChatRole,
  AIChatStep,
} from "#src/components/common/AIChat/types";
import {
  BriefBuilderChatRequest,
  BriefBuilderChatResponse,
} from "#src/types/briefBuilder";

const TIMEOUT = 3 * 60 * 1000; // 3 minutes

export default function useAIChatSubmitPrompt() {
  const {
    channel,
    messages: history,
    controller,
    setStep,
    addMessages,
    setLoading,
  } = useAIChatProvider();

  const submitBasePrompt = useCallback(
    async (prompt: string, channelRef?: string) => {
      const currChannelRef = channel?.channelRef || channelRef;

      if (!prompt || !currChannelRef) {
        return;
      }

      setLoading(true);

      const messages = history.filter((message) => !message.skip);

      try {
        const response = await client.post<
          BriefBuilderChatRequest,
          AxiosResponse<BriefBuilderChatResponse>
        >(
          "/community/brief-builder/chat/completion",
          {
            messages,
            prompt,
            channelRef: currChannelRef,
          },
          {
            signal: controller.signal,
            timeout: TIMEOUT, // 2 minutes
          }
        );

        if (!response.data.messages.length) {
          throw new Error("No messages returned");
        }

        const lastMessage = response.data.messages.at(-1) as AIChatMessageProps;
        addMessages([lastMessage]);
      } catch (error: any) {
        console.error(error);

        if (error.code === AxiosError.ERR_CANCELED) {
          addMessages([AI_CHAT_STATIC_MESSAGES.GenerationAborded]);
          return;
        }

        addMessages([AI_CHAT_STATIC_MESSAGES.Error]);
      } finally {
        setLoading(false);
      }
    },
    [addMessages, setLoading, channel, history, controller]
  );

  const submitChannelPrompt = useCallback(
    async (prompt: string, channelRef: string) => {
      if (!prompt || !channelRef) {
        return;
      }

      await submitBasePrompt(prompt, channelRef);
      setStep(AIChatStep.BriefChat);
    },
    [submitBasePrompt]
  );

  const submitTextPrompt = useCallback(
    async (prompt: string, skipMessage = false) => {
      if (!skipMessage) {
        addMessages([
          {
            role: AIChatRole.User,
            content: prompt,
          },
        ]);
      }

      await submitBasePrompt(prompt);
    },
    [addMessages, submitBasePrompt]
  );

  return { submitChannelPrompt, submitTextPrompt };
}
