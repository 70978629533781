import React from "react";

import ShareDialog from "#src/components/common/Dialog/ShareDialog";
import useShareChannel from "#src/components/pages/ChannelCommunity/ShareChannel/hooks/useShareChannel";

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

export default function ShareChannelModal({ isOpen, onClose }: Props) {
  const { config, share } = useShareChannel();

  return (
    <ShareDialog
      isOpen={isOpen}
      config={config}
      onSocialClick={share}
      onClose={onClose}
    />
  );
}
