import * as React from "react";
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";

const IconComment1 = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    cursor="pointer"
    viewBox="0 0 21 21"
    className="shared-icon"
    ref={ref}
    {...props}
  >
    <path
      fill="currentColor"
      d="M18.375 0H2.625C1.179 0 0 1.187 0 2.606v11.858a2.61 2.61 0 0 0 2.625 2.606h3.938v3.432c0 .406.451.642.784.4l5.122-3.758h5.906c1.446 0 2.625-1.187 2.625-2.606V2.606C21 1.187 19.823 0 18.375 0m.656 14.538c0 .361-.297.66-.656.66h-6.562L8.53 17.678v-2.478H2.625a.663.663 0 0 1-.656-.66V2.642c0-.361.297-.66.656-.66h15.75c.359 0 .656.299.656.66zM13.781 7.6H7.22a.985.985 0 0 0-.985.992c0 .547.44.954.948.954h6.562c.583 0 1.022-.405 1.022-.954a.985.985 0 0 0-.985-.992"
    />
  </svg>
);
const ForwardRef = forwardRef(IconComment1);
export { ForwardRef as IconComment1 };
