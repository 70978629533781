import * as React from "react";
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";

const IconStarSolid = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    className="shared-icon"
    ref={ref}
    {...props}
  >
    <path
      fill="currentColor"
      d="M13.198 1.75a1.335 1.335 0 0 0-2.4 0L8.12 7.263l-5.983.883a1.33 1.33 0 0 0-1.071.904 1.34 1.34 0 0 0 .329 1.362l4.342 4.296L4.71 20.78a1.337 1.337 0 0 0 1.946 1.4l5.345-2.854 5.346 2.854c.45.238.996.204 1.409-.096.412-.3.62-.804.537-1.304l-1.03-6.07 4.343-4.296a1.332 1.332 0 0 0-.742-2.267l-5.988-.884z"
    />
  </svg>
);
const ForwardRef = forwardRef(IconStarSolid);
export { ForwardRef as IconStarSolid };
