import { Components, Theme } from "@mui/material";
import deepmerge from "@mui/utils/deepmerge";

import accordionOverrides from "./accordion/accordion";
import accordionDetailsOverrides from "./accordion/accordionDetails";
import accordionSummaryOverrides from "./accordion/accordionSummary";
import alertOverrides from "./alert";
import autocompleteOverrides from "./autocomplete";
import buttonOverrides from "./button/button";
import iconButtonOverrides from "./button/iconButton";
import checkboxOverrides from "./checkbox";
import chipOverrides from "./chip";
import dialogOverrides from "./dialog";
import formControlOverrides from "./form/formControl";
import formControlLabelOverrides from "./form/formControlLabel";
import formHelperTextOverrides from "./form/formHelperText";
import formLabelOverrides from "./form/formLabel";
import inputOverrides from "./input/input";
import inputBaseOverrides from "./input/inputBase";
import inputLabelOverrides from "./input/inputLabel";
import outlinedInputOverrides from "./input/outlinedInput";
import linkOverrides from "./link";
import listOverrides from "./list";
import menuOverrides from "./menu/menu";
import menuItemOverrides from "./menu/menuItem";
import paginationOverrides from "./pagination/pagination";
import paginationItemOverrides from "./pagination/paginationItem";
import popperOverrides from "./popper";
import radioOverrides from "./radio";
import selectOverrides from "./select";
import sliderOverrides from "./slider";
import snackbarOverrides from "./snackbar";
import switchOverrides from "./switch";
import tabOverrides from "./tab/tab";
import tabsOverrides from "./tab/tabs";
import tableCellOverrides from "./table/tableCell";
import tableContainerOverrides from "./table/tableContainer";
import tableHeadOverrides from "./table/tableHead";
import textFieldOverrides from "./textField";
import tooltipOverrides from "./tooltip";
import typographyOverrides from "./typography";

export const componentsOverrides = (
  theme: Theme,
  customOverrides?: Components<Omit<Theme, "components">>
): Components<Omit<Theme, "components">> => {
  const baseOverrides: Components<Omit<Theme, "components">> = {
    MuiAlert: alertOverrides(theme),
    MuiAccordion: accordionOverrides(),
    MuiAccordionSummary: accordionSummaryOverrides(theme),
    MuiAccordionDetails: accordionDetailsOverrides(),
    MuiAutocomplete: autocompleteOverrides(theme),
    MuiButton: buttonOverrides(theme),
    MuiIconButton: iconButtonOverrides(theme),
    MuiCheckbox: checkboxOverrides(),
    MuiChip: chipOverrides(theme),
    MuiDialog: dialogOverrides(),
    MuiFormControl: formControlOverrides(theme),
    MuiFormControlLabel: formControlLabelOverrides(theme),
    MuiFormHelperText: formHelperTextOverrides(theme),
    MuiFormLabel: formLabelOverrides(theme),
    MuiInput: inputOverrides(),
    MuiInputBase: inputBaseOverrides(theme),
    MuiInputLabel: inputLabelOverrides(theme),
    MuiOutlinedInput: outlinedInputOverrides(theme),
    MuiList: listOverrides(),
    MuiMenu: menuOverrides(theme),
    MuiMenuItem: menuItemOverrides(theme),
    MuiPagination: paginationOverrides(),
    MuiPaginationItem: paginationItemOverrides(theme),
    MuiPopper: popperOverrides(theme),
    MuiRadio: radioOverrides(),
    MuiSelect: selectOverrides(theme),
    MuiSlider: sliderOverrides(theme),
    MuiSnackbar: snackbarOverrides(theme),
    MuiSwitch: switchOverrides(theme),
    MuiTab: tabOverrides(theme),
    MuiTabs: tabsOverrides(theme),
    MuiTableCell: tableCellOverrides(theme),
    MuiTableContainer: tableContainerOverrides(),
    MuiTableHead: tableHeadOverrides(),
    MuiTextField: textFieldOverrides(theme),
    MuiTooltip: tooltipOverrides(theme),
    MuiTypography: typographyOverrides(),
    MuiLink: linkOverrides(),
  };

  return customOverrides ? deepmerge(baseOverrides, customOverrides) : baseOverrides;
};
