import * as React from "react";
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";

const IconAtomSimple = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    className="shared-icon"
    ref={ref}
    {...props}
  >
    <path
      fill="currentColor"
      d="M4.571 19.429c.353.353 1.402.754 3.59.027.589-.197 1.214-.465 1.857-.8a25 25 0 0 1-2.491-2.183 25 25 0 0 1-2.184-2.49 13 13 0 0 0-.799 1.856c-.727 2.188-.326 3.237.027 3.59M4 12c-2.197-3.665-2.692-7.192-.942-8.942S8.335 1.803 12 4c3.665-2.197 7.192-2.692 8.942-.942S22.197 8.335 20 12c2.197 3.665 2.692 7.192.942 8.942S15.665 22.197 12 20c-3.665 2.197-7.192 2.692-8.942.942S1.803 15.665 4 12m1.348-1.982A25 25 0 0 1 7.53 7.527a25 25 0 0 1 2.491-2.184 13 13 0 0 0-1.857-.799c-2.188-.727-3.237-.326-3.59.027s-.754 1.402-.026 3.59c.196.589.464 1.214.799 1.857M12 6.553a22.3 22.3 0 0 0-2.955 2.492A22.3 22.3 0 0 0 6.553 12a22.3 22.3 0 0 0 2.492 2.956A22.3 22.3 0 0 0 12 17.446a22.3 22.3 0 0 0 2.956-2.49A22.3 22.3 0 0 0 17.446 12a22.3 22.3 0 0 0-2.49-2.955A22.3 22.3 0 0 0 12 6.553m6.652 3.47c.335-.644.603-1.269.8-1.858.727-2.188.325-3.237-.028-3.59-.352-.352-1.401-.754-3.589-.026-.59.196-1.214.464-1.857.799a25 25 0 0 1 2.49 2.183c.809.808 1.541 1.647 2.184 2.491m0 3.96a25 25 0 0 1-2.183 2.49 25 25 0 0 1-2.491 2.184c.643.334 1.268.602 1.857.799 2.188.727 3.237.326 3.59-.027s.754-1.402.026-3.59a13 13 0 0 0-.799-1.857M10.572 12a1.429 1.429 0 1 1 2.857 0 1.429 1.429 0 0 1-2.858 0"
    />
  </svg>
);
const ForwardRef = forwardRef(IconAtomSimple);
export { ForwardRef as IconAtomSimple };
