import { QueryClient, useQuery } from "@tanstack/react-query";

import { OrganisationsApi } from "#src/apis/organisations/api";
import { OrganisationsQueryKeys } from "#src/apis/organisations/queryKeys";
import {
  GetWlaOrganisationRequest,
  GetWlaOrganisationResponse,
} from "#src/types/organisation";
import { AppQueryOptions } from "#src/types/reactQuery";
import {
  DEFAULT_ORGANISATION_DOMAIN,
  getOrganisationDomain,
} from "#src/utils/organisation";

interface Props {
  params: GetWlaOrganisationRequest;
  options?: AppQueryOptions<GetWlaOrganisationResponse, any>;
}

const getWlaDomain = (url: string | undefined) => {
  return getOrganisationDomain(url) || DEFAULT_ORGANISATION_DOMAIN;
};

export const useGetWlaOrganisation = ({ params, options }: Props) => {
  const domain = getWlaDomain(params.url);

  return useQuery({
    queryKey: OrganisationsQueryKeys.wla,
    queryFn: () => OrganisationsApi.getWLAOrganisation(domain),
    ...options,
    staleTime: Infinity,
  });
};

export const prefetchWlaOrganisation = async (
  serverQueryClient: QueryClient,
  params: GetWlaOrganisationRequest
) => {
  const domain = getWlaDomain(params.url);

  await serverQueryClient.prefetchQuery({
    queryKey: OrganisationsQueryKeys.wla,
    queryFn: () => OrganisationsApi.getWLAOrganisation(domain),
    staleTime: Infinity,
  });

  return serverQueryClient.getQueryData<GetWlaOrganisationResponse>(
    OrganisationsQueryKeys.wla
  );
};
