import { matchPath } from "react-router";

import { IconCircleInfo } from "#shared/icons/CircleInfo";
import { IconCircleInfoSolid } from "#shared/icons/CircleInfoSolid";
import { IconPaintbrushPencil } from "#shared/icons/PaintbrushPencil";
import { IconPaintbrushPencilSolid } from "#shared/icons/PaintbrushPencilSolid";
import { IconRocketLaunch } from "#shared/icons/RocketLaunch";
import { IconRocketLaunchSolid } from "#shared/icons/RocketLaunchSolid";
import { IconSquarePollHorizontal } from "#shared/icons/SquarePollHorizontal";
import { IconSquarePollHorizontalSolid } from "#shared/icons/SquarePollHorizontalSolid";
import { IconUsers } from "#shared/icons/Users";
import { IconUsersSolid } from "#shared/icons/UsersSolid";
import { Step } from "#shared/types/steps";
import { BriefBuilderRoutes, BriefBuilderStep } from "#src/types/briefBuilder";
import { TranslationKey } from "#src/types/translation";

export const BRIEF_BUILDER_ROUTES: BriefBuilderRoutes = {
  [BriefBuilderStep.Brief]: "/brief-builder/:briefRef/brief",
  [BriefBuilderStep.BriefEditor]: "/brief-builder/:briefRef/brief/content",
  [BriefBuilderStep.General]: "/brief-builder/:briefRef/general",
  [BriefBuilderStep.Audience]: "/brief-builder/:briefRef/audience",
  [BriefBuilderStep.Survey]: "/brief-builder/:briefRef/survey",
  [BriefBuilderStep.Launch]: "/brief-builder/:briefRef/launch",
  [BriefBuilderStep.Share]: "/brief-builder/:briefRef/share",
};

export const BRIEF_BUILDER_STEPS: Step<BriefBuilderStep, TranslationKey>[] = [
  {
    id: BriefBuilderStep.Brief,
    title: "pages.brief_builder.steps.brief",
    Icon: IconPaintbrushPencil,
    IconCompleted: IconPaintbrushPencilSolid,
  },
  {
    id: BriefBuilderStep.BriefEditor,
    title: "pages.brief_builder.steps.brief",
    hidden: true,
  },
  {
    id: BriefBuilderStep.General,
    title: "pages.brief_builder.steps.general.title",
    Icon: IconCircleInfo,
    IconCompleted: IconCircleInfoSolid,
  },
  {
    id: BriefBuilderStep.Audience,
    title: "pages.brief_builder.steps.audience.title",
    Icon: IconUsers,
    IconCompleted: IconUsersSolid,
  },
  {
    id: BriefBuilderStep.Survey,
    title: "pages.brief_builder.steps.survey.title",
    Icon: IconSquarePollHorizontal,
    IconCompleted: IconSquarePollHorizontalSolid,
  },
  {
    id: BriefBuilderStep.Launch,
    title: "pages.brief_builder.steps.launch.short_title",
    Icon: IconRocketLaunch,
    IconCompleted: IconRocketLaunchSolid,
  },
  {
    id: BriefBuilderStep.Share,
    title: "pages.brief_builder.steps.share.title",
    hidden: true,
  },
];

export const isBriefBuilderStepActive = (
  step: BriefBuilderStep,
  currentStep: BriefBuilderStep = BriefBuilderStep.Brief
): boolean => {
  if (step === BriefBuilderStep.Brief && currentStep === BriefBuilderStep.BriefEditor) {
    return true;
  }

  return step === currentStep;
};

export const isBriefBuilderStepCompleted = (
  step: BriefBuilderStep,
  currentStep: BriefBuilderStep = BriefBuilderStep.Brief
): boolean => {
  if (step === BriefBuilderStep.Brief && currentStep === BriefBuilderStep.BriefEditor) {
    return false;
  }

  const stepIndex = BRIEF_BUILDER_STEPS.findIndex((s) => s.id === step);
  const currentStepIndex = BRIEF_BUILDER_STEPS.findIndex((s) => s.id === currentStep);

  return stepIndex < currentStepIndex;
};

export const isBriefBuilderStepAvailable = (
  step: BriefBuilderStep,
  steps: BriefBuilderStep[] = [],
  currentStep: BriefBuilderStep = BriefBuilderStep.Brief
): boolean => {
  if (!steps.includes(step)) {
    return false;
  }

  const stepIndex = BRIEF_BUILDER_STEPS.findIndex((s) => s.id === step);
  const currentStepIndex = BRIEF_BUILDER_STEPS.findIndex((s) => s.id === currentStep);

  return stepIndex <= currentStepIndex;
};

export const getCurrentStepFromPathname = (pathname: string): BriefBuilderStep => {
  const matchingStep = Object.keys(BRIEF_BUILDER_ROUTES).find((step) =>
    matchPath(BRIEF_BUILDER_ROUTES[step as BriefBuilderStep], pathname)
  );

  return matchingStep ? (matchingStep as BriefBuilderStep) : BriefBuilderStep.Brief;
};

export const getBriefBuilderUrl = ({
  step = BriefBuilderStep.Brief,
  briefRef,
}: {
  step?: BriefBuilderStep;
  briefRef: string;
}) => {
  const path = BRIEF_BUILDER_ROUTES[step];
  return path ? path.replace(":briefRef", briefRef) : `/brief-builder/${briefRef}`;
};
