import { NavigationAction } from "#src/constants/navigation";
import {
  businessNavigationItems,
  mainNavigationItems,
} from "#src/containers/layout/Navigation/items";

export function getDefaultNavigationConfiguration(pathname: string) {
  if (pathname.startsWith("/business-centre")) {
    return {
      items: businessNavigationItems,
      defaultSelected: NavigationAction.business_account,
    };
  }

  return {
    items: mainNavigationItems,
    defaultSelected: NavigationAction.foryou,
  };
}
