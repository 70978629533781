import { useCallback } from "react";

import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";

import logout from "#src/apis/user/logout";
import { LINK_FALLBACKS } from "#src/constants/common";
import { NavigationAction } from "#src/constants/navigation";
import useGodView from "#src/containers/GodView/useGodView";
import { setDownloadModal } from "#src/features/downloadModal/downloadModalSlice";
import { removeGodView } from "#src/features/superAdmin/godViewSlice";
import { GaEvents, gaTrackEvent } from "#src/utils/useGA";
import useWlaOrganisation from "#src/utils/useWlaOrganisation";

export default function useNavigationAction() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { organisation } = useWlaOrganisation();
  const { openModal: openGodViewModal, enterGodView } = useGodView();

  const checkActionForGA = (action: NavigationAction) => {
    switch (action) {
      case NavigationAction.profile:
        gaTrackEvent(GaEvents.openeditprofilebuttonpress, "", "");
        break;
      case NavigationAction.settings:
        gaTrackEvent(GaEvents.opensettingsbuttonpress, "", "");
        break;
      case NavigationAction.businesslab:
        gaTrackEvent(GaEvents.business_lab_access, "", "");
        break;
      case NavigationAction.knowledgeAi:
        gaTrackEvent(GaEvents.knowledge_ai_access, "", "");
        break;
    }
  };

  const navigateAction = useCallback(
    async (action: NavigationAction) => {
      checkActionForGA(action);
      if (action === NavigationAction.logout) {
        await logout();
        navigate("/signin");
        dispatch(removeGodView());
        return;
      }

      if (action === NavigationAction.feedback_help) {
        window.open(organisation?.supportURL || LINK_FALLBACKS.faq, "_blank");
        return;
      }

      if (action.startsWith("#getApp")) {
        dispatch(setDownloadModal(true));
        return;
      }

      if (action === NavigationAction.god_view) {
        openGodViewModal();
        return;
      }

      if (action === NavigationAction.god_view_wla) {
        if (!organisation) return;

        enterGodView({
          id: organisation.organisationId,
          name: organisation.name,
          logo: organisation.logo || "",
          isPersonalOrganisation: false,
        });
        return;
      }

      if (action.startsWith("https://")) {
        window.open(action, "_blank");
        return;
      }

      navigate(`/${action}`, { state: { navigated: true } });
      window.scroll({ top: 0 });
    },
    [organisation]
  );

  return { navigateAction };
}
