import React, { useState } from "react";

import { styled } from "@mui/material";

import { LoadingButton } from "#shared/components/ui";
import ConfirmDialog from "#shared/components/ui/Dialog/ConfirmDialog";
import useChannelActions from "#src/components/pages/ChannelDetails/ChannelHeader/useChannelActions";
import useChannelPermissions from "#src/components/pages/ChannelDetails/useChannelPermissions";
import { ChannelDetails } from "#src/types/channel";
import useTranslation from "#src/utils/useTranslation";

interface Props {
  channel: ChannelDetails;
}

export default function ChannelJoinLeaveActions({ channel }: Props) {
  const { t, p } = useTranslation();
  const { canJoinChannel, isChannelResearcher } = useChannelPermissions();

  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);

  const { joinChannel, leaveChannel, isLoadingJoin, isLoadingLeave } =
    useChannelActions();

  const handleJoinChannel = async () => {
    joinChannel(channel);
  };

  const handleLeaveChannel = () => {
    setIsConfirmModalOpen(true);
  };

  const handleConfirmLeaveChannel = async () => {
    setIsConfirmModalOpen(false);
    leaveChannel(channel);
  };

  const content = () => {
    if (isChannelResearcher) {
      return (
        <StyledButton
          variant="cta"
          size="small"
          color="primary"
          sx={{ minWidth: 40 }}
          loading={false}
        >
          {t("ui.channel_header.actions.joined")}
        </StyledButton>
      );
    }

    if (!canJoinChannel) {
      return null;
    }

    if (!channel.isMember) {
      return (
        <StyledButton
          variant="contained"
          size="small"
          color="primary"
          loading={!!isLoadingJoin}
          onClick={handleJoinChannel}
        >
          {t("ui.channel_header.actions.join")}
        </StyledButton>
      );
    }

    return (
      <StyledButton
        variant="outlined"
        size="small"
        color="primary"
        loading={!!isLoadingLeave}
        onClick={handleLeaveChannel}
      >
        {t("ui.channel_header.actions.leave")}
      </StyledButton>
    );
  };

  return (
    <>
      {content()}
      <ConfirmDialog
        isOpen={isConfirmModalOpen}
        title={t("ui.modals.leave_channel.title")}
        content={p(
          channel.isDiscoverable
            ? "ui.modals.leave_channel.content_public"
            : "ui.modals.leave_channel.content"
        )}
        onConfirm={handleConfirmLeaveChannel}
        onClose={() => setIsConfirmModalOpen(false)}
        confirmButtonText={t("ui.channel_header.actions.leave")}
      />
    </>
  );
}

const StyledButton = styled(LoadingButton)`
  min-width: 80px;
  height: 26px;
`;
