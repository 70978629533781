import React from "react";

import { Box, Divider } from "@mui/material";
import { useIntl } from "react-intl";

import { FormField } from "#shared/components/ui";
import { useMediaQueryProvider } from "#shared/context/MediaQueryProvider";
import SelectOrganisation from "#src/components/pages/BusinessAccount/components/SelectOrganisation";
import MyChannels from "#src/components/pages/Feed/ChannelsSidebar/DesktopChannels/MyChannels";
import PrivateChannels from "#src/components/pages/Feed/ChannelsSidebar/DesktopChannels/PrivateChannels";
import useMyFollowedChannels from "#src/components/pages/Feed/ChannelsSidebar/useMyFollowedChannels";
import useMyOwnedChannels from "#src/components/pages/Feed/ChannelsSidebar/useMyOwnedChannels";
import MenuLinks from "#src/components/pages/Feed/MenuLinks";
import useGodView from "#src/containers/GodView/useGodView";
import Navigation from "#src/containers/layout/Navigation";
import { WrapperDesktop } from "./styled";

interface Props {
  isBusinessCentre?: boolean;
}

export default function DesktopSideNavigation({ isBusinessCentre }: Props) {
  const intl = useIntl();
  const { isMobile } = useMediaQueryProvider();
  const { isEnabled: isGodViewEnabled } = useGodView();

  const { channels: ownedChannels } = useMyOwnedChannels();
  const { channels: followedChannels, isLoading: isLoadingFollowedChannels } =
    useMyFollowedChannels();

  const hasChannels =
    !!ownedChannels?.length ||
    !!followedChannels.length ||
    isGodViewEnabled ||
    isLoadingFollowedChannels;

  const showChannels = !isMobile && !isBusinessCentre && hasChannels;

  if (isMobile) {
    return null;
  }

  return (
    <WrapperDesktop businessCenter={isBusinessCentre} godView={isGodViewEnabled}>
      {isBusinessCentre && !isGodViewEnabled && (
        <>
          <FormField
            name="select_organisation"
            label={intl.formatMessage({
              id: "pages.business_settings.sections.your_organisation",
            })}
          >
            <SelectOrganisation outlined />
          </FormField>
          <Divider sx={{ mt: 6, mb: "1.5rem !important" }} />
        </>
      )}
      {/* default channels */}
      <div key={`filters_feed_default`} className="default">
        <Navigation />
      </div>
      <Divider />
      {/* private channels */}
      {showChannels && (
        <>
          <Box display="flex" flexDirection="column" gap={3} mb={3}>
            <div>
              <MyChannels />
            </div>
            {!isGodViewEnabled && (
              <div>
                <PrivateChannels />
              </div>
            )}
          </Box>
          <Divider />
        </>
      )}
      <MenuLinks />
    </WrapperDesktop>
  );
}
