import React, { useCallback, useMemo } from "react";

import colors from "#shared/config/theme/colors";
import { useMediaQueryProvider } from "#shared/context/MediaQueryProvider";
import { IconEnvelope } from "#shared/icons/Envelope";
import { IconFacebook } from "#shared/icons/Facebook";
import { IconFacebookMessengerSolid } from "#shared/icons/FacebookMessengerSolid";
import { IconLinkedinIn } from "#shared/icons/LinkedinIn";
import { IconPaperPlane } from "#shared/icons/PaperPlane";
import { IconThreads } from "#shared/icons/Threads";
import { IconWhatsapp } from "#shared/icons/Whatsapp";
import { IconXTwitter } from "#shared/icons/XTwitter";
import { objectToGetParams } from "#shared/utils/url.utils";
import { ShareTargetData, ShareTarget, ShareConfig } from "#src/types/share";

type Props = Omit<ShareConfig, "targets">;

type ReturnType = {
  config: ShareConfig;
  share: (target: ShareTarget) => void;
};

export default function useShare(data: Props): ReturnType {
  const { isTabletOrMobile } = useMediaQueryProvider();

  const targets = useMemo(
    () =>
      [
        {
          id: ShareTarget.Native,
          label: "Share",
          icon: <IconPaperPlane />,
          color: colors.brand.default,
          url: "",
          params: {},
          hidden: typeof navigator !== "undefined" && navigator.share === undefined,
        },
        {
          id: ShareTarget.Whatsapp,
          label: "Whatsapp",
          icon: <IconWhatsapp />,
          color: "#24D366",
          url: `https://${isTabletOrMobile ? "api" : "web"}.whatsapp.com/send`,
          params: {
            text: `${data.title} ${data.url}`,
          },
        },
        {
          id: ShareTarget.Facebook,
          label: "Facebook",
          icon: <IconFacebook />,
          color: "#0275FA",
          url: "https://www.facebook.com/sharer/sharer.php",
          params: {
            u: data.url,
            t: data.title,
          },
        },
        {
          id: ShareTarget.FacebookMessenger,
          label: "Messenger",
          icon: <IconFacebookMessengerSolid />,
          color: "#05ABF9",
          url: "https://www.facebook.com/dialog/send",
          params: {
            link: data.url,
            display: "popup",
            redirect_uri: data.redirectUrl,
            app_id:
              typeof document !== "undefined"
                ? document.head
                    .querySelector('meta[property="fb:app_id"]')
                    ?.getAttribute("content")
                : "",
          },
        },
        {
          id: ShareTarget.Twitter,
          label: "X (Twitter)",
          icon: <IconXTwitter />,
          color: "#000000",
          url: "https://twitter.com/intent/tweet",
          params: {
            text: data.title,
            url: data.url,
          },
        },
        {
          id: ShareTarget.Linkedin,
          label: "Linkedin",
          icon: <IconLinkedinIn />,
          color: "#0A66C2",
          url: "https://www.linkedin.com/sharing/share-offsite",
          params: {
            url: data.url,
          },
        },
        {
          id: ShareTarget.Threads,
          label: "Threads",
          icon: <IconThreads />,
          color: "#000000",
          url: "https://threads.net/intent/post",
          params: {
            text: `${data.title} ${data.url}`,
          },
        },
        {
          id: ShareTarget.Email,
          label: "Email",
          icon: <IconEnvelope />,
          color: "#0FBEF3",
          url: "mailto:",
          params: {
            subject: "Join us on Bulbshare",
            body: `${data.title} ${data.url}`,
          },
        },
      ] as ShareTargetData[],
    [data, isTabletOrMobile]
  );

  const filteredTargets = useMemo(() => targets.filter((c) => !c.hidden), [targets]);

  const share = useCallback(
    (target: ShareTarget) => {
      const item = targets.find((c) => c.id === target);

      if (!item) {
        return;
      }

      if (item.id === ShareTarget.Native) {
        navigator.share({
          title: data.title,
          text: data.title,
          url: data.url,
        });
        return;
      }

      const targetUrl = `${item.url}${objectToGetParams(item.params)}`;
      const openBlank = !item.url.startsWith("mailto:");
      // center the popup
      const width = 600;
      const height = 500;

      const left = window.screen.width / 2 - width / 2;
      const top = window.screen.height / 2 - height / 2;
      window.open(
        targetUrl,
        openBlank ? "_blank" : "_self",
        `popup=true,width=${width},height=${height},top=${top},left=${left}`
      );
    },
    [targets, data]
  );

  return { config: { ...data, targets: filteredTargets }, share };
}
