import React, { ReactNode } from "react";

import { styled } from "@mui/material/styles";

import { AvenirAndHeavyText } from "#src/components/common/styled";
import { TitleH1 } from "../Titles";

const Wrapper = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2.5rem;
`;

type HeaderTextProps = {
  title: string | ReactNode;
  subtitle: string | ReactNode;
};

export default function LoginHeader({ title, subtitle }: HeaderTextProps) {
  return (
    <Wrapper>
      <TitleH1 style={{ textAlign: "center" }}>{title}</TitleH1>
      <AvenirAndHeavyText>{subtitle}</AvenirAndHeavyText>
    </Wrapper>
  );
}
