import { mergeAttributes, Node } from "@tiptap/core";
import { EditorView } from "@tiptap/pm/view";
import { ReactNodeViewRenderer } from "@tiptap/react";
import { Plugin } from "prosemirror-state";
import { v4 as uuid } from "uuid";

import MagicImageView from "#src/components/common/Editor/extensions/MagicImage/MagicImageView";

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    magicImage: {
      setMagicImage: () => ReturnType;
    };
  }
}

export const MagicImage = Node.create({
  name: "magicImage",
  group: "block",
  draggable: false,

  addOptions() {
    return {
      HTMLAttributes: {
        class: `node-${this.name}`,
      },
    };
  },

  addAttributes() {
    return {
      id: {
        default: undefined,
        parseHTML: (element) => element.getAttribute("data-id"),
        renderHTML: (attributes) => ({
          "data-id": attributes.id,
        }),
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: `div.node-${this.name}`,
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ["div", mergeAttributes(this.options.HTMLAttributes, HTMLAttributes)];
  },

  addCommands() {
    return {
      setMagicImage:
        () =>
        ({ chain }) =>
          chain()
            .focus()
            .insertContent({
              type: this.name,
              attrs: {
                id: uuid(),
              },
            })
            .blur()
            .run(),
    };
  },

  addProseMirrorPlugins() {
    return [
      new Plugin({
        props: {
          handleKeyDown(view: EditorView, event: KeyboardEvent) {
            const { state } = view;
            const { selection } = state;
            const { $from, $to } = selection;

            if ($from.sameParent($to) && $from.parent.type.name === "doc") {
              if (event.key !== "Backspace") {
                event.preventDefault();
                return true;
              }
            }

            return false;
          },
        },
      }),
    ];
  },

  addNodeView() {
    return ReactNodeViewRenderer(MagicImageView);
  },
});

export default MagicImage;
