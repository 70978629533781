import React from "react";

import { Box, IconButton, styled } from "@mui/material";

import { IconChevronLeft } from "#shared/icons/ChevronLeft";
import { ButtonIDs } from "#src/constants/buttonIDs";
import { useIsMobileApp } from "#src/utils/useIsMobileApp";

export default function ChannelBackAction() {
  const { isMobileApp } = useIsMobileApp();

  if (!isMobileApp) {
    return null;
  }

  return (
    <Wrapper>
      <IconButton size="small" color="blured" id={ButtonIDs.channel.back}>
        <IconChevronLeft />
      </IconButton>
    </Wrapper>
  );
}

const Wrapper = styled(Box)`
  position: absolute;
  top: 10px;
  left: 10px;
`;
