import { Cookies } from "#shared/types";
import { Interests } from "#src/types/interests";
import { PaginatedResponse, PaginationRequest } from "#src/types/pagination";

export enum ChannelApprovalStatus {
  APPROVED = "APPROVED",
  PENDING_APPROVAL = "PENDING_APPROVAL",
  REJECTED = "REJECTED",
}

export interface GetMyOwnedChannelsRequest {
  cookie?: Cookies;
}

export interface JoinLeaveChannelRequest {
  channelRef: string;
}

export type GetMyFollowedChannelsRequest = PaginationRequest;

export type GetMyFollowedChannelsResponse = PaginatedResponse<ChannelListItem>;

export interface GetMySuggestedChannelsResponse {
  data: ChannelListItem[];
}

export interface UpdateChannelHeader {
  channelRef: string;
  name: string;
  description: string;
  logo?: File;
  banner?: File;
}

export interface UpdateChannelSettings {
  channelRef: string;
  primaryColor: string;
  secondaryColor: string;
  isDiscoverable: boolean;
  interests: number[];
  screenerBriefId?: number | null;
}

export interface ChannelTheme {
  id: number;
  primaryColor: string;
  secondaryColor: string;
  webBanner: string;
}

export interface ChannelScreener {
  id?: number;
  url?: string;
  briefRef?: string;
}

export interface ChannelListItem {
  id: number;
  channelRef: string;
  name: string;
  description: string;
  logo: string;
  organisationId: number;
  organisationName?: string;
  screener?: ChannelScreener;
  activeBriefCount?: number;
  membersCount?: number;
  isVerified: boolean;
  approvedStatus: ChannelApprovalStatus;
  isDiscoverable: boolean;
  isPrivate: boolean;
  isRemoved: boolean;
  channelsThemes: ChannelTheme;
}

export interface ChannelDetails extends ChannelListItem {
  membersCount: number;
  briefCount: number;
  totalBriefCount?: number;
  isMember: boolean;
  publicInviteToken: string;
  channelsInterests: Interests;
  isPublicRequested?: boolean;
}

export interface GetChannelByRefRequest {
  channelRef: string;
  cookies?: Cookies;
}

export interface DeleteChannelRequest {
  channelRef: string;
}

export interface CreateChannelRequest {
  name: string;
  description: string;
  interests: number[];
  organisationId?: number;
  logo?: File;
  banner?: File;
}

export type CreateChannelResponse = {
  channelRef: string;
};

export type ChannelTokenResponse = Pick<
  ChannelDetails,
  | "id"
  | "channelRef"
  | "name"
  | "description"
  | "logo"
  | "screener"
  | "isVerified"
  | "isMember"
  | "channelsThemes"
  | "briefCount"
  | "membersCount"
>;

export interface ChannelMember {
  id: number;
  username: string;
  displayName: string;
  avatar: string;
  isTeamMember: boolean;
}

export interface GetChannelMembersRequest extends PaginationRequest {
  channelRef: string;
  search?: string;
}

export type GetChannelMembersResponse = PaginatedResponse<ChannelMember>;

export interface RemoveChannelMemberRequest {
  channelRef: string;
  userId: number;
}

export interface ChannelInviteRequest {
  channelRef: string;
  emails: string[];
  title: string;
  body: string;
}

export interface ReportChannelRequest {
  channelRef: string;
  description: string;
}

export interface ChannelSearchListItem {
  id: number;
  ref: string;
  name: string;
  logo: string;
  organisation: {
    id: number;
    name: string;
    logo: string;
    isPersonalOrganisation: boolean;
  };
}

export enum SearchChannelsFilter {
  All = "all",
  Business = "business",
  Personal = "personal",
}

export enum SearchChannelsStatus {
  All = "all",
  OnlyApproved = "only_approved",
}

export interface SearchChannelsRequest extends PaginationRequest {
  type: SearchChannelsFilter;
  status: SearchChannelsStatus;
  search: string;
  topOrganisationId?: number;
}

export type SearchChannelsResponse = PaginatedResponse<ChannelSearchListItem>;
