import * as React from "react";
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";

const IconHouse = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    className="shared-icon"
    ref={ref}
    {...props}
  >
    <path
      fill="currentColor"
      d="M12.594 2.314a.9.9 0 0 0-1.188 0l-10.083 8.75a.954.954 0 0 0-.107 1.32.904.904 0 0 0 1.29.11l.94-.813v7.285c0 1.726 1.368 3.125 3.056 3.125h11c1.688 0 3.055-1.399 3.055-3.125V11.68l.936.812a.9.9 0 0 0 1.291-.11.954.954 0 0 0-.107-1.32zM5.28 18.966V10.09l6.722-5.832 6.722 5.832v8.875c0 .691-.546 1.25-1.222 1.25h-1.833v-5.938c0-.863-.684-1.562-1.528-1.562H9.863c-.844 0-1.528.7-1.528 1.562v5.938H6.502c-.676 0-1.222-.559-1.222-1.25m4.889 1.25V14.59h3.666v5.625z"
    />
  </svg>
);
const ForwardRef = forwardRef(IconHouse);
export { ForwardRef as IconHouse };
