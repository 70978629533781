import React, { useMemo } from "react";

import MenuDropdown from "#src/components/common/Dropdown/MenuDropdown";
import { NavigationAction } from "#src/constants/navigation";
// constants
import useNavigation from "#src/containers/layout/Navigation/hooks/useNavigation";
import useNavigationAction from "#src/containers/layout/Navigation/hooks/useNavigationAction";
import { profileNavigationItems } from "#src/containers/layout/Navigation/items";
import { MenuItemProps } from "#src/types/menu";
import { GaEvents, gaTrackEvent } from "#src/utils/useGA";
// components
import ProfileImage from "./ProfileImage";

export default function ProfileMenu() {
  const { items } = useNavigation(profileNavigationItems);
  const { navigateAction } = useNavigationAction();

  const menuItems: MenuItemProps[] = useMemo(
    () =>
      items.map((item) => ({
        id: item.id,
        label: item.name,
        Icon: item.LogoInactive,
        EndIcon: item.ActionIcon,
        divided: item.id === NavigationAction.logout,
        color: item.id === NavigationAction.logout ? "error" : "default",
        onClick: () => navigateAction(item.id),
      })),
    [items, navigateAction]
  );

  const trackOpenMenu = () => {
    gaTrackEvent(GaEvents.openmyprofile, "", "");
  };

  return (
    <MenuDropdown
      items={menuItems}
      AnchorElement={<ProfileImage />}
      onMenuOpen={trackOpenMenu}
    />
  );
}
