import { useCallback, useMemo } from "react";

import { useNavigate } from "react-router";

import { Step } from "#shared/types/steps";
import { useBriefBuilder } from "#src/containers/BriefBuilder/BriefBuilderProvider";
import {
  BRIEF_BUILDER_STEPS,
  getBriefBuilderUrl,
  isBriefBuilderStepActive,
  isBriefBuilderStepAvailable,
  isBriefBuilderStepCompleted,
} from "#src/containers/BriefBuilder/Header/Steps/helper";
import { BriefBuilderStep } from "#src/types/briefBuilder";
import useTranslation from "#src/utils/useTranslation";

export default function useBriefBuilderSteps() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { brief, steps, briefRef, activeStep, isStepValid } = useBriefBuilder();

  const filteredLocalSteps = useMemo(
    () => BRIEF_BUILDER_STEPS.filter((s) => steps.includes(s.id)),
    [steps]
  );

  const mappedSteps: Step[] = useMemo(
    () =>
      filteredLocalSteps
        .filter((c) => !c.hidden)
        .map((step) => {
          const completed = isBriefBuilderStepCompleted(step.id, brief?.currentBuildStep);
          const available = isBriefBuilderStepAvailable(
            step.id,
            brief?.steps,
            brief?.currentBuildStep
          );
          const active = isBriefBuilderStepActive(step.id, activeStep);

          return {
            ...step,
            title: t(step.title),
            active,
            completed,
            disabled: !available,
          };
        }),
    [brief?.currentBuildStep, filteredLocalSteps, activeStep]
  );

  const currentStep = useMemo(
    () => mappedSteps.find((step) => step.id === activeStep) || mappedSteps[0],
    [mappedSteps, activeStep]
  );

  const getStepUrl = useCallback(
    (step: BriefBuilderStep) => {
      if (step === activeStep) {
        return;
      }

      const available = isBriefBuilderStepAvailable(
        step,
        brief?.steps,
        brief?.currentBuildStep
      );

      if (!available) {
        throw new Error("Step is not available");
      }

      return getBriefBuilderUrl({ step, briefRef });
    },
    [brief?.currentBuildStep, activeStep, briefRef]
  );

  const goToNextStep = useCallback(() => {
    const currentStepIndex = filteredLocalSteps.findIndex(
      (step) => step.id === activeStep
    );

    if (currentStepIndex === -1 || currentStepIndex === filteredLocalSteps.length - 1) {
      return;
    }

    const nextStep = filteredLocalSteps[currentStepIndex + 1]?.id;
    const nextStepUrl = getBriefBuilderUrl({ step: nextStep, briefRef });
    navigate(nextStepUrl);
  }, [filteredLocalSteps, activeStep, briefRef]);

  return {
    steps: mappedSteps,
    currentStep,
    isStepValid,
    activeStep,
    getStepUrl,
    goToNextStep,
  };
}
