import * as React from "react";
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";

const IconCrystalBall = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    className="shared-icon"
    ref={ref}
    {...props}
  >
    <path
      fill="currentColor"
      d="M18.625 10.75A6.87 6.87 0 0 1 14.617 17h3.254a8.72 8.72 0 0 0 2.625-6.25C20.5 5.918 16.582 2 11.75 2A8.75 8.75 0 0 0 3 10.75C3 13.2 4.004 15.41 5.625 17h3.254a6.88 6.88 0 0 1-4.004-6.25 6.875 6.875 0 0 1 13.75 0M9.543 5.918a.312.312 0 0 0-.586 0l-.383 1.031-1.031.383a.312.312 0 0 0 0 .586l1.031.383.383 1.031a.312.312 0 0 0 .586 0l.383-1.031 1.031-.383a.312.312 0 0 0 0-.586l-1.031-.383zm4.414 1.875-.719 1.945-1.945.719a.312.312 0 0 0 0 .586l1.945.719.719 1.945a.312.312 0 0 0 .586 0l.719-1.945 1.945-.719a.312.312 0 0 0 0-.586l-1.945-.719-.719-1.945a.312.312 0 0 0-.586 0M3.793 20.523a.93.93 0 0 0-.063.97.95.95 0 0 0 .833.507h14.375a.94.94 0 0 0 .832-.504.94.94 0 0 0-.063-.969l-1.594-2.277H5.387z"
    />
  </svg>
);
const ForwardRef = forwardRef(IconCrystalBall);
export { ForwardRef as IconCrystalBall };
