import { styled } from "@mui/material";

import { toRem } from "../../../utils/style.utils";

export const FormGroupHeaderWrapper = styled("div")`
  margin-bottom: ${toRem(28)};
`;

export const FormFields = styled("div")`
  display: flex;
  flex-direction: column;
  gap: 1.375rem;

  ${({ theme }) => theme.breakpoints.down("md")} {
    gap: 1rem;
  }
`;

export const FormWrapper = styled("div")`
  padding-bottom: 2rem;

  ${({ theme }) => theme.breakpoints.down("md")} {
    padding-bottom: 1rem;
  }

  .MuiDivider-root {
    margin-block: ${toRem(28)};
  }
`;
