import { useQuery } from "@tanstack/react-query";

import { ChannelsApi } from "#src/apis/channels/api";
import { ChannelsQueryKeys } from "#src/apis/channels/queryKeys";
import { GetMySuggestedChannelsResponse } from "#src/types/channel";
import { AppQueryOptions } from "#src/types/reactQuery";

interface Props {
  options?: AppQueryOptions<GetMySuggestedChannelsResponse>;
}

const CACHE_TIME = 5 * 60 * 1000; // 5 minutes

export const useGetSuggestedChannels = ({ options }: Props = {}) => {
  return useQuery({
    queryKey: ChannelsQueryKeys.suggested,
    queryFn: () => ChannelsApi.getSuggestedChannels(),
    ...options,
    staleTime: CACHE_TIME,
  });
};
