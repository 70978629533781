import React, { ReactNode, createContext, useContext, useEffect, useMemo } from "react";

import { configureAxiosWlaHeaders } from "#src/apis/axios";
import useGetMyOwnedChannels from "#src/apis/channels/hooks/useGetMyOwnedChannels";
import AppLoader from "#src/containers/Global/AppLoader";
import useQueryLoading from "#src/containers/Global/useQueryLoading";
import { useFetchMyOrganisationPositionsQuery } from "#src/features/organisationPositions/organisationPositionsAPI";
import { ChannelListItem } from "#src/types/channel";
import { Organisation } from "#src/types/db";
import { MyOrganisationPosition } from "#src/types/types";
import useWlaOrganisation from "#src/utils/useWlaOrganisation";

interface ContextProps {
  positions: MyOrganisationPosition[];
  ownedChannels: ChannelListItem[];
  wlaOrganisation?: Organisation;
  isAppLoading: boolean;
}

const GlobalContext = createContext<ContextProps>({
  positions: [],
  ownedChannels: [],
  isAppLoading: true,
});

export function useGlobalAppProvider(): ContextProps {
  const context = useContext(GlobalContext);
  if (!context) {
    throw new Error("useGlobalAppProvider must be used within a GlobalAppProvider");
  }
  return context;
}

interface ProviderProps {
  children: ReactNode;
}

export default function GlobalAppProvider({ children }: ProviderProps) {
  // Fetch and setup the WLA organisation
  const wlaQuery = useWlaOrganisation();

  const { organisation, isLoading: isLoadingWla } = wlaQuery;

  // Fetch must-have app data
  const organisationPositionsQuery = useFetchMyOrganisationPositionsQuery({});
  const ownedChannelsQuery = useGetMyOwnedChannels({
    options: { enabled: !isLoadingWla },
  });

  const isAppLoading = useQueryLoading([
    organisationPositionsQuery,
    ownedChannelsQuery,
    wlaQuery,
  ]);

  useEffect(() => {
    if (organisation) {
      configureAxiosWlaHeaders(organisation.organisationId, organisation.domain);
    }
  }, [organisation]);

  const content = () => {
    if (isAppLoading) {
      return <AppLoader />;
    }

    return children;
  };

  const providerValue = useMemo(
    () => ({
      positions: organisationPositionsQuery.data ?? [],
      ownedChannels: ownedChannelsQuery.data ?? [],
      wlaOrganisation: organisation,
      isAppLoading,
    }),
    [organisationPositionsQuery.data, ownedChannelsQuery.data, organisation, isAppLoading]
  );

  return (
    <GlobalContext.Provider value={providerValue}>{content()}</GlobalContext.Provider>
  );
}
