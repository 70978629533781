import * as React from "react";
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";

const IconBriefcase = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    className="shared-icon"
    ref={ref}
    {...props}
  >
    <path
      fill="currentColor"
      d="M8.875 4.188V5.75h6.25V4.188a.313.313 0 0 0-.312-.313H9.186a.313.313 0 0 0-.312.313M7 5.75V4.188C7 2.98 7.98 2 9.188 2h5.624C16.02 2 17 2.98 17 4.188V5.75h2.5c1.379 0 2.5 1.121 2.5 2.5v10c0 1.379-1.121 2.5-2.5 2.5h-15a2.5 2.5 0 0 1-2.5-2.5v-10c0-1.379 1.121-2.5 2.5-2.5zm-3.125 8.125v4.375c0 .344.281.625.625.625h15a.627.627 0 0 0 .625-.625v-4.375H14.5v.625c0 .691-.559 1.25-1.25 1.25h-2.5c-.691 0-1.25-.559-1.25-1.25v-.625zM9.5 12h10.625V8.25a.627.627 0 0 0-.625-.625h-15a.627.627 0 0 0-.625.625V12z"
    />
  </svg>
);
const ForwardRef = forwardRef(IconBriefcase);
export { ForwardRef as IconBriefcase };
