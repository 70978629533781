// modules
import React from "react";

import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

// components
import Header from "#src/components/common/layout/Header";
import GodViewBanner from "#src/containers/GodView/GodViewBanner/GodViewBanner";
import { setLocale } from "#src/features/locale/localeSlice";
import { AppDispatch } from "../../../store";

type HeaderProps = {
  isProtectedPage?: boolean;
  isLoginPage?: boolean;
};

export default function HeaderContainer({
  isProtectedPage = false,
  isLoginPage,
}: HeaderProps) {
  const dispatch = useDispatch<AppDispatch>();
  const { pathname } = useLocation();
  const isSigningPage = pathname.includes("signin");

  return (
    <>
      <Header
        isProtectedPage={isProtectedPage}
        isSigningPage={isLoginPage !== undefined ? isLoginPage : isSigningPage}
        onSelectLocale={(locale) => dispatch(setLocale(locale))}
      />
      <GodViewBanner />
    </>
  );
}
