import React from "react";

import { FormattedMessage } from "react-intl";

import { LoadingButton } from "#shared/components/ui";
import { CHANNELS_INITIAL_COUNT } from "#src/constants/channel";

type Props = {
  visible: number;
  total: number;
  isLoading?: boolean;
  hasNextPage?: boolean;
  setLimit: (newLimit: number) => void;
  loadNextPage?: () => void;
};
export default function SeeAllChannels({
  visible,
  total,
  isLoading,
  hasNextPage,
  setLimit,
  loadNextPage,
}: Props) {
  const buttonLabel = () => {
    if (hasNextPage) {
      return <FormattedMessage id="pages.feed.filters.buttons.see_more" />;
    }

    if (visible > CHANNELS_INITIAL_COUNT) {
      return <FormattedMessage id="pages.feed.filters.buttons.see_less" />;
    }

    return <FormattedMessage id="pages.feed.filters.buttons.see_all" />;
  };

  const handleClick = () => {
    if (loadNextPage && hasNextPage) {
      loadNextPage();
      return;
    }

    setLimit(visible <= CHANNELS_INITIAL_COUNT ? total : CHANNELS_INITIAL_COUNT);
  };

  return (
    <>
      {total > CHANNELS_INITIAL_COUNT && (
        <LoadingButton
          variant="cta"
          color="primary"
          size="small"
          loading={!!isLoading}
          sx={{ width: "fit-content" }}
          onClick={handleClick}
        >
          {buttonLabel()}
        </LoadingButton>
      )}
    </>
  );
}
