import React from "react";

import { Box } from "@mui/material";

import useChannelPermissions from "#src/components/pages/ChannelDetails/useChannelPermissions";
import { ChannelDetails } from "#src/types/channel";
import { getIsPublicChannel } from "#src/utils/channel";
import StyledTabs, { TabsLabel } from "./StyledTabs";
import { Wrapper } from "./styled";

export enum ChannelTabsEnum {
  briefs = "briefs",
  community = "community",
  settings = "settings",
}

const commonStyle = { minHeight: "34px", borderBottomWidth: "1px" };
const tabs: TabsLabel[] = [
  { id: "briefs", label: "Briefs", style: commonStyle },
  {
    id: "community",
    label: "Community",
    style: commonStyle,
  },
  { id: "settings", label: "Settings", style: commonStyle },
];

interface Props {
  channel: ChannelDetails;
  selectedTab: ChannelTabsEnum;
  onTabChange?(newTab: ChannelTabsEnum): void;
}

export default function ChannelTabs({ channel, selectedTab, onTabChange }: Props) {
  const { canEditChannel } = useChannelPermissions();

  const visible: Record<string, boolean> = {
    settings: canEditChannel,
    community: !getIsPublicChannel(channel),
  };

  const visibleTabs = tabs.filter((item) => visible[item.id] ?? true);

  const selectedIndex = visibleTabs.findIndex((tab) => tab.id === selectedTab) || 0;

  if (visibleTabs.length === 1) {
    return <Box my={4} />;
  }

  return (
    <Wrapper>
      <StyledTabs
        selectedTab={selectedIndex}
        tabLabels={visibleTabs}
        onChange={(selectedTabId) => onTabChange?.(selectedTabId as ChannelTabsEnum)}
      />
    </Wrapper>
  );
}
