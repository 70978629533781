import { client } from "#src/apis/axios";
import { BriefCardInfo } from "#src/types/brief";
import {
  CreateProjectRequest,
  CreateProjectResponse,
  GetOrganisationProjectsRequest,
} from "#src/types/projects";

export namespace ProjectsApi {
  const BASE_PREFIX = "/projects";

  export const organisationProjects = async ({
    organisationId,
  }: GetOrganisationProjectsRequest) => {
    const { data } = await client.get<BriefCardInfo>(
      `${BASE_PREFIX}/organisations/${organisationId}`
    );
    return data;
  };

  export const create = async (body: CreateProjectRequest) => {
    const { data } = await client.post<CreateProjectResponse>(BASE_PREFIX, body);
    return data;
  };
}
