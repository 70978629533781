import React, { useEffect, useState } from "react";

import { useQueryClient } from "@tanstack/react-query";

import useToast from "#shared/hooks/useToast";
import { convertImageUrlToFile } from "#shared/utils";
import { ChannelsQueryKeys } from "#src/apis/channels/queryKeys";
import ChannelHeaderContent from "#src/components/pages/ChannelDetails/ChannelHeader/components/ChannelHeaderContent";
import ChannelHeaderEdit from "#src/components/pages/ChannelDetails/ChannelHeader/components/ChannelHeaderEdit";
import ChannelHeaderSkeleton from "#src/components/pages/ChannelDetails/ChannelHeader/components/ChannelHeaderSkeleton";
import { ChannelHeaderWrapper } from "#src/components/pages/ChannelDetails/ChannelHeader/styled";
import useChannelHeaderForm from "#src/components/pages/ChannelDetails/ChannelHeader/useChannelHeaderForm";
import useChannelPermissions from "#src/components/pages/ChannelDetails/useChannelPermissions";
import useSelectedChannel from "#src/components/pages/ChannelDetails/useSelectedChannel";
import { useUpdateChannelHeaderMutation } from "#src/features/channel/channelsAPI";
import { IMAGE_TYPES } from "#src/types/types";
import getResourcesURL from "#src/utils/getResourcesURL";
import useTranslation from "#src/utils/useTranslation";

export default function ChannelDetailsHeader() {
  const { t } = useTranslation();
  const { data: channel, isLoading } = useSelectedChannel();
  const { canEditChannel } = useChannelPermissions();
  const toast = useToast();
  const queryClient = useQueryClient();

  const [updateChannel, { isLoading: isUpdating }] = useUpdateChannelHeaderMutation();

  const bannerSrc = getResourcesURL(
    channel?.channelsThemes.webBanner,
    IMAGE_TYPES.CHANNEL_BANNER
  );

  const {
    formValues,
    formErrors,
    hasChanges,
    setValue,
    setDefaultValues,
    validate,
    updateDefaultValues,
  } = useChannelHeaderForm({
    banner: bannerSrc,
    logo: channel?.logo || "",
    name: channel?.name || "",
    description: channel?.description || "",
  });

  const [editMode, setEditMode] = useState(false);

  const handleConfirmEdit = async () => {
    if (!hasChanges) {
      setEditMode(false);
      return;
    }

    if (!channel || !validate()) return;

    const { name, description } = formValues;

    const logo = !formValues.logo.startsWith("https://")
      ? await convertImageUrlToFile(formValues.logo)
      : undefined;

    const banner = !formValues.banner.startsWith("https://")
      ? await convertImageUrlToFile(formValues.banner)
      : undefined;

    try {
      await updateChannel({
        channelRef: channel.channelRef,
        name: name.trim(),
        description: description.trim(),
        logo,
        banner,
      }).unwrap();
      queryClient.invalidateQueries({ queryKey: ChannelsQueryKeys.owned });
      updateDefaultValues();
      toast.success({ message: t("pages.channel_settings.toasts.edit_success") });
      setEditMode(false);
    } catch {
      toast.error();
    }
  };

  useEffect(() => {
    setEditMode(false);
    setDefaultValues({
      banner: bannerSrc,
      logo: channel?.logo || "",
      name: channel?.name || "",
      description: channel?.description || "",
    });
  }, [channel]);

  if (isLoading || !channel) {
    return <ChannelHeaderSkeleton />;
  }

  return (
    <ChannelHeaderWrapper>
      {editMode && (
        <ChannelHeaderEdit
          data={formValues}
          errors={formErrors}
          isLoading={isUpdating}
          onChange={setValue}
          onConfirm={handleConfirmEdit}
        />
      )}
      {!editMode && (
        <ChannelHeaderContent
          channel={channel}
          editable={canEditChannel}
          setEditMode={setEditMode}
        />
      )}
    </ChannelHeaderWrapper>
  );
}
