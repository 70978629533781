import * as React from "react";
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";

const IconUsers = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    className="shared-icon"
    ref={ref}
    {...props}
  >
    <path
      fill="currentColor"
      d="M6.5 9a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5M18 9a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5M2 13.334c0 .366.3.666.666.666h6.69a4 4 0 0 1-1.353-3q.002-.356.06-.697A3.3 3.3 0 0 0 6.669 10H5.334A3.336 3.336 0 0 0 2 13.334M14.666 14h6.668c.37 0 .666-.3.666-.666A3.336 3.336 0 0 0 18.666 10h-1.332c-.497 0-.968.11-1.393.303A4.001 4.001 0 0 1 14.647 14h.022zM12 9.5a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3m0 4.5a3 3 0 1 0 0-6 3 3 0 0 0 0 6m-1.834 2.5h3.668c1.244 0 2.288.85 2.582 2H7.584c.297-1.15 1.341-2 2.582-2m0-1.5A4.166 4.166 0 0 0 6 19.166c0 .459.372.834.834.834h10.332c.459 0 .834-.372.834-.834 0-2.3-1.866-4.166-4.166-4.166z"
    />
  </svg>
);
const ForwardRef = forwardRef(IconUsers);
export { ForwardRef as IconUsers };
