import React, { useMemo } from "react";

import { IconButton, styled } from "@mui/material";

import Tooltip from "#shared/components/ui/Tooltip";
import colors from "#shared/config/theme/colors";
import effects from "#shared/config/theme/effects";
import { IconCrystalBall } from "#shared/icons/CrystalBall";
import { IconFile } from "#shared/icons/File";
import { IconPlus } from "#shared/icons/Plus";
import { IconSparkles } from "#shared/icons/Sparkles";
import { toRem } from "#shared/utils";
import MenuDropdown from "#src/components/common/Dropdown/MenuDropdown";
import { MenuItemProps } from "#src/types/menu";
import useTranslation from "#src/utils/useTranslation";

interface Props {
  onClickSmartBrief?: () => void;
  onClickEmptyBrief: () => void;
  onClickResearch?: () => void;
  loading?: boolean;
}

export default function MagicButton({
  onClickSmartBrief,
  onClickEmptyBrief,
  onClickResearch,
  loading,
}: Props) {
  const { t } = useTranslation();

  const menu: MenuItemProps[] = useMemo(
    () => [
      {
        id: "smart_brief",
        label: t("ui.magic_button.smart_brief"),
        hidden: !onClickSmartBrief,
        Icon: IconSparkles,
        color: "gradient",
        onClick: () => onClickSmartBrief?.(),
      },
      {
        id: "empty_brief",
        label: t("ui.magic_button.empty_brief"),
        Icon: IconFile,
        onClick: () => onClickEmptyBrief?.(),
      },
      {
        id: "research",
        label: t("ui.magic_button.start_research"),
        hidden: !onClickResearch,
        Icon: IconCrystalBall,
        onClick: () => onClickResearch?.(),
      },
    ],
    [onClickEmptyBrief, onClickResearch, onClickSmartBrief]
  );

  const hasMultipleItems = menu.some((item) => !item.hidden && item.id !== "empty_brief");

  const content = () => {
    if (hasMultipleItems) {
      return (
        <MenuDropdown
          items={menu}
          AnchorElement={
            <StyledButton size="large" color="white" disabled={loading}>
              <IconPlus />
            </StyledButton>
          }
        />
      );
    }

    return (
      <StyledButton
        size="large"
        color="white"
        disabled={loading}
        onClick={onClickEmptyBrief}
      >
        <IconPlus />
      </StyledButton>
    );
  };

  return (
    <Tooltip
      title={t("ui.magic_button.tooltip")}
      hidden={menu.length > 1}
      placement="top-end"
    >
      {content()}
    </Tooltip>
  );
}

const StyledButton = styled(IconButton)`
  background: ${colors.gradient.ai.value},
    linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
  box-shadow: ${effects.shadow.lg};
  transition: opacity 0.3s ease-in-out;
  width: ${toRem(56)};
  height: ${toRem(56)};

  ${({ theme }) => theme.breakpoints.down("md")} {
    width: ${toRem(50)};
    height: ${toRem(50)};
  }

  &:disabled {
    opacity: 0.3;
    color: ${colors.base.white};
  }
`;
