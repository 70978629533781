import React, { useCallback, useEffect, useRef, useState } from "react";

import { Typography } from "@mui/material";

import colors from "#shared/config/theme/colors";
import EditorButton from "#src/components/common/Editor/components/EditorButton";
import { EditorListWrapper } from "#src/components/common/Editor/components/EditorMenuDropdown";
import {
  EditorCommand,
  EditorCommandListProps,
} from "#src/components/common/Editor/types";

export const EditorCommandMenu = React.forwardRef(
  (props: EditorCommandListProps, ref) => {
    const scrollContainer = useRef<HTMLDivElement>(null);
    const activeItem = useRef<HTMLButtonElement>(null);
    const [selectedGroupIndex, setSelectedGroupIndex] = useState(0);
    const [selectedCommandIndex, setSelectedCommandIndex] = useState(0);

    // Anytime the groups change, i.e. the user types to narrow it down, we want to
    // reset the current selection to the first menu item
    useEffect(() => {
      setSelectedGroupIndex(0);
      setSelectedCommandIndex(0);
    }, [props.items]);

    const selectItem = useCallback(
      (groupIndex: number, commandIndex: number) => {
        const command = props.items[groupIndex].commands[commandIndex];
        props.command(command);
      },
      [props]
    );

    React.useImperativeHandle(ref, () => ({
      onKeyDown: ({ event }: { event: React.KeyboardEvent }) => {
        if (event.key === "ArrowDown") {
          if (!props.items.length) {
            return false;
          }

          const commands = props.items[selectedGroupIndex].commands;

          let newCommandIndex = selectedCommandIndex + 1;
          let newGroupIndex = selectedGroupIndex;

          if (commands.length - 1 < newCommandIndex) {
            newCommandIndex = 0;
            newGroupIndex = selectedGroupIndex + 1;
          }

          if (props.items.length - 1 < newGroupIndex) {
            newGroupIndex = 0;
          }

          setSelectedCommandIndex(newCommandIndex);
          setSelectedGroupIndex(newGroupIndex);

          return true;
        }

        if (event.key === "ArrowUp") {
          if (!props.items.length) {
            return false;
          }

          let newCommandIndex = selectedCommandIndex - 1;
          let newGroupIndex = selectedGroupIndex;

          if (newCommandIndex < 0) {
            newGroupIndex = selectedGroupIndex - 1;
            newCommandIndex = props.items[newGroupIndex]?.commands.length - 1 || 0;
          }

          if (newGroupIndex < 0) {
            newGroupIndex = props.items.length - 1;
            newCommandIndex = props.items[newGroupIndex].commands.length - 1;
          }

          setSelectedCommandIndex(newCommandIndex);
          setSelectedGroupIndex(newGroupIndex);

          return true;
        }

        if (event.key === "Enter") {
          if (
            !props.items.length ||
            selectedGroupIndex === -1 ||
            selectedCommandIndex === -1
          ) {
            return false;
          }

          selectItem(selectedGroupIndex, selectedCommandIndex);

          return true;
        }

        return false;
      },
    }));

    useEffect(() => {
      if (activeItem.current && scrollContainer.current) {
        const offsetTop = activeItem.current.offsetTop;
        const offsetHeight = activeItem.current.offsetHeight;

        scrollContainer.current.scrollTop = offsetTop - offsetHeight;
      }
    }, [selectedCommandIndex, selectedGroupIndex]);

    const createCommandClickHandler = useCallback(
      (groupIndex: number, commandIndex: number) => {
        return () => {
          selectItem(groupIndex, commandIndex);
        };
      },
      [selectItem]
    );

    if (!props.items.length) {
      return null;
    }

    return (
      <EditorListWrapper ref={scrollContainer}>
        {props.items.map((group, groupIndex: number) => (
          <React.Fragment key={`${group.title}-wrapper`}>
            <Typography
              key={group.title}
              variant="label2"
              color={colors.text.tertiaryDark}
              px={2}
            >
              {group.title}
            </Typography>
            {group.commands.map((command: EditorCommand, commandIndex: number) => (
              <EditorButton
                key={`${command.label}`}
                selected={
                  selectedGroupIndex === groupIndex &&
                  selectedCommandIndex === commandIndex
                }
                isAIButton={command.isAICommand}
                startIcon={command.Icon && <command.Icon />}
                sx={{ justifyContent: "flex-start", px: 3 }}
                onClick={createCommandClickHandler(groupIndex, commandIndex)}
              >
                {command.label}
              </EditorButton>
            ))}
          </React.Fragment>
        ))}
      </EditorListWrapper>
    );
  }
);

EditorCommandMenu.displayName = "EditorCommandMenu";

export default EditorCommandMenu;
