import { Theme } from "@mui/material";

import colors from "../../../theme/colors";
import { ComponentOverrides } from "../../types";

const tabsOverrides = (theme: Theme): ComponentOverrides<"MuiTabs"> => ({
  styleOverrides: {
    root: {
      minHeight: "40px",
      position: "relative",

      "&:not(.button-tabs)": {
        "&::after": {
          content: '""',
          position: "absolute",
          bottom: 0,
          left: 0,
          right: 0,
          height: "1px",
          background: colors.base.outline1,
          zIndex: 0,
        },

        ".MuiTabs-indicator": {
          zIndex: 1,
        },
      },
    },
    indicator: {
      backgroundColor: theme.palette.primary.main,
      height: "1px",
      marginTop: "30px",
    },
  },
});

export default tabsOverrides;
