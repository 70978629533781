import * as React from "react";
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";

const IconTable = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    className="shared-icon"
    ref={ref}
    {...props}
  >
    <path
      fill="currentColor"
      d="M4.688 12.156V8.5h6.468v3.656zm0 1.688h6.468v4.219H5.25a.564.564 0 0 1-.562-.563zm8.156 4.219v-4.22h6.469V17.5c0 .31-.254.563-.563.563zm6.469-5.907h-6.47V8.5h6.47zM5.25 4A2.25 2.25 0 0 0 3 6.25V17.5a2.25 2.25 0 0 0 2.25 2.25h13.5A2.25 2.25 0 0 0 21 17.5V6.25A2.25 2.25 0 0 0 18.75 4z"
    />
  </svg>
);
const ForwardRef = forwardRef(IconTable);
export { ForwardRef as IconTable };
