import React from "react";

import { Box, styled } from "@mui/material";
import classNames from "classnames";

import AIChatWelcomeScreen from "#src/components/common/AIChat/components/AIChatWelcomeScreen";
import AIChatMessage from "#src/components/common/AIChat/components/message";
import { AIChatMessageProps, AIChatRole } from "#src/components/common/AIChat/types";

interface Props {
  messages: AIChatMessageProps[];
  loading?: boolean;
  standalone?: boolean;
  onSelectTemplate: (template: string) => void;
  onClose?: () => void;
}

export default function AIChatContent({
  messages,
  loading,
  standalone,
  onSelectTemplate,
  onClose,
}: Props) {
  const content = () => {
    if (!messages.length) {
      return <AIChatWelcomeScreen onSelectTemplate={onSelectTemplate} />;
    }

    return (
      <>
        {messages.map((message, index) => (
          <AIChatMessage
            key={index}
            {...message}
            onClose={onClose}
            isLastMessage={index === messages.length - 1}
          />
        ))}
        {loading && (
          <AIChatMessage
            role={AIChatRole.Assistant}
            content=""
            loading
            isLastMessage={false}
          />
        )}
      </>
    );
  };

  return (
    <ChatMessagesWrapper
      className={classNames("chat-messages", {
        empty: !messages.length,
        standalone,
      })}
    >
      {content()}
    </ChatMessagesWrapper>
  );
}

const ChatMessagesWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  max-width: var(--chat-max-width);
  height: 500px;
  justify-content: flex-end;
  flex: 1;

  &.empty {
    justify-content: center;
    align-items: center;
  }

  &.standalone {
    height: initial;
    margin: 0 auto;
    padding-block: 16px;
    transform: translateX(8px);

    ${({ theme }) => theme.breakpoints.down("sm")} {
      transform: translateX(0);
    }
  }
`;
