// modules
import React from "react";

import classNames from "classnames";

import HeaderIcons from "#src/components/common/layout/Header/HeaderIcons";
import MobileMainMenu from "#src/components/common/layout/Header/SideMenu/MobileMainMenu";
import { HeaderSpacer, HeaderWrapper } from "#src/components/common/layout/Header/styled";
import HeaderLogo from "./HeaderLogo";

type HeaderProps = {
  isProtectedPage?: boolean;
  onSelectLocale: (locale: string) => void;
  isSigningPage: boolean;
};

export default function Header({
  isProtectedPage = false,
  onSelectLocale,
  isSigningPage,
}: HeaderProps) {
  return (
    <>
      <HeaderWrapper
        className={classNames("headerWrapper", {
          border: isProtectedPage,
        })}
      >
        <div
          className={classNames("inner", {
            headerWithProfile: isProtectedPage,
          })}
        >
          {isProtectedPage && <MobileMainMenu />}
          <HeaderLogo />

          <HeaderIcons
            isSigningPage={isSigningPage}
            isProtectedPage={isProtectedPage}
            onSelectLocale={onSelectLocale}
          />
        </div>
      </HeaderWrapper>
      <HeaderSpacer />
    </>
  );
}
