import * as React from "react";
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";

const IconExit = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    className="shared-icon"
    ref={ref}
    {...props}
  >
    <path
      fill="currentColor"
      d="M21.725 12.413a.933.933 0 0 0 0-1.324l-5-5.003a.937.937 0 0 0-1.324 1.324l3.398 3.398-9.612.004a.935.935 0 0 0-.938.937c0 .52.418.938.938.938h9.612l-3.398 3.398a.937.937 0 0 0 1.324 1.324zM8.562 4.875c.52 0 .937-.418.937-.938A.935.935 0 0 0 8.562 3H5.437A3.437 3.437 0 0 0 2 6.437v10.624a3.437 3.437 0 0 0 3.437 3.437h3.125c.52 0 .937-.418.937-.937a.935.935 0 0 0-.937-.937H5.437c-.863 0-1.562-.7-1.562-1.563V6.437c0-.863.699-1.562 1.562-1.562z"
    />
  </svg>
);
const ForwardRef = forwardRef(IconExit);
export { ForwardRef as IconExit };
