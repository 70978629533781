import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";

import { Typography, styled } from "@mui/material";
import { EmojiItem } from "@tiptap-pro/extension-emoji";

import EditorButton from "#src/components/common/Editor/components/EditorButton";
import { EditorListWrapper } from "#src/components/common/Editor/components/EditorMenuDropdown";
import { EditorEmojiList } from "#src/components/common/Editor/types";

const EmojiList = forwardRef((props: EditorEmojiList, ref: any) => {
  const [selectedIndex, setSelectedIndex] = useState(0);

  useEffect(() => setSelectedIndex(0), [props.items]);

  const selectItem = useCallback(
    (index: number) => {
      const item = props.items[index];

      if (item) {
        props.command({ name: item.name });
      }
    },
    [props]
  );

  useImperativeHandle(
    ref,
    () => {
      const scrollIntoView = (index: number) => {
        const item = props.items[index];

        if (item) {
          const node = document.querySelector(`[data-emoji-name="${item.name}"]`);

          if (node) {
            node.scrollIntoView({ block: "nearest" });
          }
        }
      };

      const upHandler = () => {
        const newIndex = (selectedIndex + props.items.length - 1) % props.items.length;
        setSelectedIndex(newIndex);
        scrollIntoView(newIndex);
      };

      const downHandler = () => {
        const newIndex = (selectedIndex + 1) % props.items.length;
        setSelectedIndex(newIndex);
        scrollIntoView(newIndex);
      };

      const enterHandler = () => {
        selectItem(selectedIndex);
      };

      return {
        onKeyDown: ({ event }: { event: React.KeyboardEvent }) => {
          if (event.key === "ArrowUp") {
            upHandler();
            return true;
          }

          if (event.key === "ArrowDown") {
            downHandler();
            return true;
          }

          if (event.key === "Enter") {
            enterHandler();
            return true;
          }

          return false;
        },
      };
    },
    [props, selectedIndex, selectItem]
  );

  const createClickHandler = useCallback(
    (index: number) => () => selectItem(index),
    [selectItem]
  );

  if (!props.items || !props.items.length) {
    return null;
  }

  return (
    <EditorListWrapper>
      {props.items.map((item: EmojiItem, index: number) => (
        <EditorButton
          selected={index === selectedIndex}
          key={item.name}
          onClick={createClickHandler(index)}
          data-emoji-name={item.name}
          sx={{ justifyContent: "start" }}
        >
          {item.fallbackImage ? (
            <EmojiImage src={item.fallbackImage} alt="emoji" />
          ) : (
            item.emoji
          )}{" "}
          <EmojiName variant="inherit" sx={{ ml: 1 }}>
            :{item.name}:
          </EmojiName>
        </EditorButton>
      ))}
    </EditorListWrapper>
  );
});

const EmojiImage = styled("img")`
  width: 1rem;
  height: 1rem;
`;

const EmojiName = styled(Typography)`
  max-width: 120px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

EmojiList.displayName = "EmojiList";

export default EmojiList;
