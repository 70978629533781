import * as React from "react";
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";

const IconPaintbrushPencilSolid = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    className="shared-icon"
    ref={ref}
    {...props}
  >
    <path
      fill="currentColor"
      d="M21.43 6.32c.76-.76.76-1.99 0-2.75s-1.99-.76-2.75 0l-8.774 8.774 2.75 2.75zM9.118 13.132a3.887 3.887 0 0 0-4.875 4.16c.062.607-.354 1.264-.965 1.264H3.11a1.11 1.11 0 1 0 0 2.222h5a3.887 3.887 0 0 0 3.757-4.896l.003-.003-2.75-2.75zm3.243-4.816L7.718 3.671a2.22 2.22 0 0 0-3.142 0l-.816.812a2.22 2.22 0 0 0 0 3.143l4.264 4.263h.087q.324 0 .639.042l3.614-3.615zm.75 8.66 2.243 2.243c.232.232.524.403.84.493l3.61 1.031a.832.832 0 0 0 1.032-1.031L19.805 16.1a1.93 1.93 0 0 0-.493-.84l-2.625-2.625-3.618 3.614q.042.315.042.639z"
    />
  </svg>
);
const ForwardRef = forwardRef(IconPaintbrushPencilSolid);
export { ForwardRef as IconPaintbrushPencilSolid };
