import * as React from "react";
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";

const IconSettings = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    className="shared-icon"
    ref={ref}
    {...props}
  >
    <path
      fill="currentColor"
      d="M11.576 2c.664 0 1.313.066 1.945.188.31.058.852.238 1.149.785q.118.216.18.46l.363 1.505c.055.226.437.449.66.382l1.485-.437q.235-.07.476-.074c.629-.02 1.055.367 1.262.601a10 10 0 0 1 1.949 3.371c.101.297.219.852-.106 1.383-.085.14-.19.274-.312.39l-1.121 1.067c-.164.156-.164.606 0 .762l1.121 1.066c.121.117.227.25.313.39.32.532.203 1.087.105 1.384a10 10 0 0 1-1.95 3.37c-.206.235-.636.622-1.261.602a2 2 0 0 1-.476-.074l-1.485-.441c-.223-.067-.605.156-.66.383l-.363 1.503q-.061.248-.18.461c-.3.547-.844.723-1.149.785-.632.122-1.28.188-1.945.188-.664 0-1.312-.066-1.945-.187-.309-.06-.852-.239-1.149-.786a2 2 0 0 1-.18-.46l-.362-1.505c-.055-.226-.438-.449-.66-.382l-1.485.437q-.235.07-.477.074c-.628.02-1.054-.367-1.261-.601a10 10 0 0 1-1.95-3.371c-.101-.297-.218-.852.106-1.383a2 2 0 0 1 .312-.39l1.122-1.067c.164-.156.164-.606 0-.762l-1.125-1.066a2 2 0 0 1-.313-.39c-.32-.532-.203-1.087-.102-1.38a10 10 0 0 1 1.95-3.37c.207-.235.636-.622 1.261-.602q.24.006.477.074l1.484.437c.223.067.606-.156.66-.383l.364-1.503q.06-.248.18-.461c.3-.547.843-.723 1.148-.785A10.3 10.3 0 0 1 11.576 2m-1.48 2.008-.332 1.37c-.305 1.263-1.77 2.106-3.016 1.743L5.4 6.723a8.2 8.2 0 0 0-1.484 2.566l1.024.973c.937.89.937 2.586 0 3.476l-1.024.973c.332.937.84 1.812 1.484 2.566l1.352-.398c1.242-.367 2.71.48 3.016 1.742l.332 1.371a8.4 8.4 0 0 0 2.965 0l.332-1.37c.304-1.263 1.77-2.106 3.015-1.743l1.352.398a8.2 8.2 0 0 0 1.484-2.566l-1.023-.973c-.938-.89-.938-2.586 0-3.476l1.023-.973a8.2 8.2 0 0 0-1.484-2.566l-1.352.398c-1.242.367-2.71-.48-3.015-1.742l-.332-1.371a8.4 8.4 0 0 0-2.965 0zM9.7 12a1.875 1.875 0 1 0 3.75 0 1.875 1.875 0 0 0-3.75 0m1.875 3.75a3.75 3.75 0 1 1 0-7.5 3.75 3.75 0 0 1 0 7.5"
    />
  </svg>
);
const ForwardRef = forwardRef(IconSettings);
export { ForwardRef as IconSettings };
