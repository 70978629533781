import React from "react";

import { styled } from "@mui/material";
import { useIntl } from "react-intl";

// components
import Language from "#src/components/common/i18n/Language";
import QuestionMark from "#src/components/common/layout/Header/QuestionMark";
import { selectLocale } from "#src/features/locale/localeSlice";
// utils
import { useSelectorTyped } from "../../../../../utils";

const Wrapper = styled("div")`
  display: flex;
  gap: 16px;
`;

type LoginIconsProps = {
  shouldRenderLanguageMenu: boolean;
  onSelectLocale: (locale: string) => void;
};

export default function LoginIcons({
  onSelectLocale,
  shouldRenderLanguageMenu,
}: LoginIconsProps) {
  const locale = useSelectorTyped(selectLocale);
  const intl = useIntl();
  return (
    <Wrapper>
      {shouldRenderLanguageMenu && (
        <Language
          buttonLabel={intl.formatMessage({
            id: `locales.${locale}`,
          })}
          onSelectLocale={(locale) => onSelectLocale(locale)}
        />
      )}

      <QuestionMark background={"black"} />
    </Wrapper>
  );
}
