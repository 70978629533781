import React, { useEffect, useState } from "react";

import { Box, Divider, Drawer, IconButton, styled } from "@mui/material";
import { useLocation } from "react-router";

import { useMediaQueryProvider } from "#shared/context/MediaQueryProvider";
import { IconClose } from "#shared/icons/Close";
import { IconMenu } from "#shared/icons/Menu";
import { toRem } from "#shared/utils";
import MyChannels from "#src/components/pages/Feed/ChannelsSidebar/DesktopChannels/MyChannels";
import PrivateChannels from "#src/components/pages/Feed/ChannelsSidebar/DesktopChannels/PrivateChannels";
import { ButtonIDs } from "#src/constants/buttonIDs";
import Navigation from "#src/containers/layout/Navigation";
import { useIsMobileApp } from "#src/utils/useIsMobileApp";

export default function MobileMainMenu() {
  const { pathname } = useLocation();
  const { isDesktop } = useMediaQueryProvider();
  const { isMobileApp } = useIsMobileApp();

  const [open, setOpen] = useState(false);

  const closeDrawer = () => setOpen(false);
  const openDrawer = () => setOpen(true);

  useEffect(() => {
    closeDrawer();
  }, [pathname]);

  useEffect(() => {
    if (isDesktop) {
      closeDrawer();
    }
  }, [isDesktop]);

  return (
    <>
      {!isDesktop && (
        <IconButton onClick={openDrawer} id={ButtonIDs.header.closeButton}>
          {isMobileApp ? <IconClose style={{ fill: "#000" }} /> : <IconMenu />}
        </IconButton>
      )}

      <Drawer anchor="left" open={open} onClose={closeDrawer}>
        <Wrapper>
          <Navigation onNavigationItemClick={closeDrawer} />
          <Divider sx={{ marginBlock: "1.25rem" }} />
          <Box display="flex" flexDirection="column" gap={4}>
            <Box paddingInline={3}>
              <MyChannels onNavigationItemClick={closeDrawer} />
            </Box>
            <Box paddingInline={3}>
              <PrivateChannels onNavigationItemClick={closeDrawer} />
            </Box>
          </Box>
        </Wrapper>
      </Drawer>
    </>
  );
}

const Wrapper = styled("div")`
  padding: 1rem 1.25rem;
  width: min(${toRem(280)}, calc(70vw));
`;
