import React from "react";

import { FormattedMessage } from "react-intl";

import { LoadingButton } from "#shared/components/ui";
import Avatar from "#src/components/common/Avatar";
import SidebarCard from "#src/components/common/SidebarCard";
import useChannelActions from "#src/components/pages/ChannelDetails/ChannelHeader/useChannelActions";
import { getChannelUrl } from "#src/components/routing/utils";
import { ChannelListItem } from "#src/types/channel";
import { nFormatter } from "#src/utils/index";

interface Props {
  channel: ChannelListItem;
  width?: number | string;
  joined?: boolean;
  onClick: (channelRef: string) => void;
}

export default function ChannelBox({
  channel,
  width = "100%",
  joined = false,
  onClick,
}: Props) {
  const { channelRef, logo, name, description } = channel;

  const { joinChannel, isLoadingJoin } = useChannelActions();

  const handleJoinClick = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    channel: ChannelListItem
  ) => {
    event.stopPropagation();

    if (joined) {
      onClick(channel.channelRef);
      return;
    }

    joinChannel(channel);
  };

  return (
    <SidebarCard
      Avatar={<Avatar uri={logo} diameter={2} className="circle" />}
      title={name}
      subtitle={
        <>
          {nFormatter(channel.membersCount || 0)}{" "}
          <FormattedMessage
            id={`pages.feed.channel.member${channel.membersCount === 1 ? "" : "s"}`}
          />
        </>
      }
      description={description}
      href={getChannelUrl(channelRef)}
      Actions={
        <LoadingButton
          variant="cta"
          size="extraSmall"
          loading={isLoadingJoin}
          disabled={joined}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();

            handleJoinClick(e, channel);
          }}
          sx={{ textTransform: "uppercase", p: 0, height: "auto", minWidth: 0 }}
        >
          {joined ? (
            <FormattedMessage id="ui.channel_header.actions.joined" />
          ) : (
            <FormattedMessage id="ui.channel_header.actions.join" />
          )}
        </LoadingButton>
      }
      sx={{ width }}
    />
  );
}
