import React, { ReactNode } from "react";

import { Box, Button, ButtonProps, SxProps, Theme, styled } from "@mui/material";
import classNames from "classnames";

import button from "../../../config/theme/button";
import colors from "../../../config/theme/colors";
import { toRem } from "../../../utils";
import LoadingButton from "../Button/LoadingButton";

interface Props {
  confirmButtonText?: JSX.Element | string;
  confirmButtonProps?: Omit<
    ButtonProps,
    "variant" | "size" | "sx" | "children" | "onClick"
  > & { isLoading?: boolean };
  cancelButtonText?: JSX.Element | string;
  cancelButtonProps?: Omit<
    ButtonProps,
    "variant" | "size" | "sx" | "children" | "onClick"
  >;
  reverseMobile?: boolean;
  onConfirmButtonClick?: () => void;
  onCancelButtonClick?: () => void;
  sx?: SxProps<Theme>;
  children?: ReactNode;
  AdditionalElements?: ReactNode;
}

export default function ModalFooter({
  confirmButtonText,
  confirmButtonProps,
  cancelButtonText,
  cancelButtonProps,
  onConfirmButtonClick,
  onCancelButtonClick,
  reverseMobile,
  sx,
  children,
  AdditionalElements,
}: Props) {
  const { isLoading, ...rest } = confirmButtonProps || { isLoading: false };

  const hasCancelButton = !!cancelButtonText || !!onCancelButtonClick;
  const hasConfirmButton =
    !confirmButtonProps?.hidden && (confirmButtonText || onConfirmButtonClick);
  const hasActions = hasCancelButton || hasConfirmButton || children;

  return (
    <Wrapper
      className={classNames("modal-footer", reverseMobile && "-reverse-mobile")}
      sx={sx}
    >
      <AdditionalElementsWrapper
        className={classNames(
          "modal-footer-additional-elements",
          hasActions && "-has-actions",
        )}
      >
        {AdditionalElements}
      </AdditionalElementsWrapper>
      <ActionButtons className="modal-footer-action-buttons">
        {hasCancelButton && (
          <Button
            color="primary"
            variant="text"
            size="small"
            sx={{ minWidth: button.width.s }}
            {...cancelButtonProps}
            onClick={onCancelButtonClick}
          >
            {cancelButtonText || "Cancel"}
          </Button>
        )}
        {hasConfirmButton && (
          <LoadingButton
            color="primary"
            variant="contained"
            size="small"
            sx={{ minWidth: button.width.s }}
            loading={!!isLoading}
            onClick={onConfirmButtonClick}
            {...rest}
          >
            {confirmButtonText || "Confirm"}
          </LoadingButton>
        )}
        {children}
      </ActionButtons>
    </Wrapper>
  );
}

const Wrapper = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--modal-padding, ${toRem(20)});
  background: var(--modal-background, ${colors.base.white});
  border-top: 1px solid #dfdfdf;

  &:has(.modal-footer-action-buttons:empty) .modal-footer-additional-elements {
    width: 100%;
  }

  ${({ theme }) => theme.breakpoints.down("sm")} {
    &.-reverse-mobile {
      flex-direction: column-reverse;

      .modal-footer-additional-elements {
        margin-top: 0.75rem;
        margin-bottom: 0;
      }
    }
  }

  ${({ theme }) => theme.breakpoints.down("sm")} {
    flex-direction: column;
  }
`;

const AdditionalElementsWrapper = styled(Box)`
  ${({ theme }) => theme.breakpoints.down("sm")} {
    width: 100%;

    &:not(:empty).-has-actions {
      margin-bottom: 0.75rem;
    }
  }
`;

const ActionButtons = styled(Box)`
  display: flex;
  gap: 0.75rem;
  justify-content: space-between;
  align-items: center;

  & > button {
    min-width: ${button.width.s};
    height: 40px;
  }

  ${({ theme }) => theme.breakpoints.down("sm")} {
    width: 100%;
    flex-direction: column-reverse;

    & > button {
      width: 100%;
    }
  }
`;
