import * as React from "react";
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";

const IconSquarePollHorizontalSolid = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    className="shared-icon"
    ref={ref}
    {...props}
  >
    <path
      fill="currentColor"
      d="M22 4.857A2.86 2.86 0 0 0 19.143 2H4.857A2.86 2.86 0 0 0 2 4.857v14.286A2.86 2.86 0 0 0 4.857 22h14.286A2.86 2.86 0 0 0 22 19.143zm-8.571 2.857c0 .79-.639 1.429-1.429 1.429H7.714c-.79 0-1.428-.639-1.428-1.429s.638-1.428 1.428-1.428H12c.79 0 1.429.638 1.429 1.428m2.857 2.857c.79 0 1.428.639 1.428 1.429s-.638 1.429-1.428 1.429H7.714c-.79 0-1.428-.639-1.428-1.429s.638-1.429 1.428-1.429zm-5.715 5.715c0 .79-.638 1.428-1.428 1.428H7.714c-.79 0-1.428-.638-1.428-1.428s.638-1.429 1.428-1.429h1.429c.79 0 1.428.639 1.428 1.429"
    />
  </svg>
);
const ForwardRef = forwardRef(IconSquarePollHorizontalSolid);
export { ForwardRef as IconSquarePollHorizontalSolid };
