import { useMemo } from "react";

import { useGlobalAppProvider } from "#src/containers/Global/GlobalAppProvider";
import { OrganisationStatus, OrganisationPositionType } from "../../../types/types";

export default function useOrganisationStatus() {
  const { positions } = useGlobalAppProvider();

  const organisationStatus: OrganisationStatus | undefined = useMemo(() => {
    // Check if user is part of any organisation
    if (!positions?.length) return undefined;

    // check if user has any active organisation
    const hasActiveOrganisation = positions.some(
      (c) =>
        c.organisation.status === OrganisationStatus.VERIFIED &&
        [OrganisationPositionType.Owner, OrganisationPositionType.Admin].includes(c.id)
    );

    if (hasActiveOrganisation) {
      return OrganisationStatus.VERIFIED;
    }

    // Check if user is owner of any pending organisation
    const hasPendingOrganisation = positions.some(
      (c) =>
        c.organisation.status === OrganisationStatus.PENDING_VERIFICATION &&
        c.id === OrganisationPositionType.Owner
    );

    if (hasPendingOrganisation) {
      return OrganisationStatus.PENDING_VERIFICATION;
    }

    // Check if user is owner of any rejected organisation
    const hasRejectedOrganisation = positions.some(
      (c) =>
        c.organisation.status === OrganisationStatus.REJECTED &&
        c.id === OrganisationPositionType.Owner
    );

    if (hasRejectedOrganisation) {
      return OrganisationStatus.REJECTED;
    }

    return undefined;
  }, [positions]);

  const hasResearchOrganisation = useMemo(() => {
    if (!positions?.length) {
      return false;
    }

    return positions.some(
      (c) =>
        c.id === OrganisationPositionType.Researcher &&
        c.organisation.status === OrganisationStatus.VERIFIED
    );
  }, [positions]);

  const hasVerifiedOrganisation = organisationStatus === OrganisationStatus.VERIFIED;

  const hasPendingOrganisation =
    organisationStatus === OrganisationStatus.PENDING_VERIFICATION;

  return {
    organisationStatus,
    hasVerifiedOrganisation,
    hasPendingOrganisation,
    hasResearchOrganisation,
  };
}
