import * as React from "react";
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";

const IconLinkedinIn = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    className="shared-icon"
    ref={ref}
    {...props}
  >
    <path
      fill="currentColor"
      d="M6.477 22H2.33V8.647h4.147zM4.4 6.826C3.075 6.826 2 5.727 2 4.4a2.401 2.401 0 1 1 4.803 0c0 1.326-1.076 2.425-2.402 2.425M21.996 22h-4.138v-6.5c0-1.55-.03-3.536-2.155-3.536-2.156 0-2.487 1.683-2.487 3.424V22H9.074V8.647h3.977v1.822h.058c.554-1.05 1.906-2.157 3.923-2.157 4.197 0 4.968 2.764 4.968 6.353V22z"
    />
  </svg>
);
const ForwardRef = forwardRef(IconLinkedinIn);
export { ForwardRef as IconLinkedinIn };
