import { LinkProps } from "@mui/material";

import LinkBehavior from "../../../components/ui/Link/LinkBehavior";
import { ComponentOverrides } from "../types";

const linkOverrides = (): ComponentOverrides<"MuiLink"> => ({
  styleOverrides: {
    root: {
      position: "relative",

      "&.wrapper": {
        color: "inherit",
        textDecoration: "none",
      },
    },
  },
  defaultProps: {
    component: LinkBehavior,
  } as LinkProps,
});

export default linkOverrides;
