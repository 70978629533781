import React from "react";

import { Box, CircularProgress } from "@mui/material";

import { useMediaQueryProvider } from "#shared/context/MediaQueryProvider";
import { useBriefBuilder } from "#src/containers/BriefBuilder/BriefBuilderProvider";
import BuilderDesktopActions from "#src/containers/BriefBuilder/Header/Actions/BuilderDesktopActions";
import BuilderMobileActions from "#src/containers/BriefBuilder/Header/Actions/BuilderMobileActions";

interface Props {
  onDiscard: () => void;
}

export default function BuilderActions({ onDiscard }: Props) {
  const { isTabletOrMobile } = useMediaQueryProvider();
  const { isSavingMedia } = useBriefBuilder();

  const content = () => {
    if (isTabletOrMobile) {
      return <BuilderMobileActions />;
    }

    return <BuilderDesktopActions onDiscard={onDiscard} />;
  };

  return (
    <Box display="flex" alignItems="center" gap={[2, 4]}>
      {isSavingMedia && <CircularProgress size={20} sx={{ marginRight: 1 }} />}
      {content()}
    </Box>
  );
}
