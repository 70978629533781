import { useEffect, useState } from "react";

import dayjs from "dayjs";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";

import { HttpApiCallErrorType } from "#shared/components/api/errors";
import fetchProfile from "#src/apis/user/fetchProfile";
import verifyJWT from "#src/apis/user/verifyJWT";
import {
  buildURLFromRedirectQuery,
  getRedirectURL,
  isUserWithIncompleteProfileVisitingPrivateRoute,
} from "#src/components/routing/utils";
import { selectUser, setProfile } from "#src/features/user/userSlice";
import { UserState } from "#src/types/state";
import { getRoutePathname, isProtectedPage, useSelectorTyped } from "../../utils";

export default function useRedirect(): boolean {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const [checkingCredentials, setCheckingCredentials] = useState<boolean>(true);

  const currentUserState = useSelectorTyped(selectUser);
  const [searchParams] = useSearchParams();
  const strategy = searchParams.get("strategy");

  const getShouldUpdateUser = (userState: UserState) => {
    if (userState.isLoggedIn && userState.updatedAt) {
      // user is logged in, update user state if it has been updated more than 15 minutes ago
      return dayjs(userState.updatedAt).isBefore(dayjs().subtract(15, "minute"));
    }
    // we have no user state, update it
    return true;
  };

  useEffect(() => {
    const handleVerifyJWT = async (): Promise<{
      verified?: boolean;
      errorType?: string;
    }> => {
      try {
        const role = currentUserState.profile.role;

        const { data } = await verifyJWT({ role });
        return data;
      } catch (error) {
        console.error(error);

        if ((error as HttpApiCallErrorType)?.errorDetails) {
          const { code } = (error as HttpApiCallErrorType).errorDetails || {};
          if (code === 608) {
            return { errorType: "user_account_deactivated" };
          }
        }
        return {};
      }
    };

    const handleUpdateUser = async () => {
      const user = await fetchProfile();
      if (user) {
        dispatch(setProfile(user));
        return user;
      }
    };

    const getRedirectUrl = (errorType?: string) => {
      const url = `/signin${getRedirectURL(location)}`;
      if (errorType) {
        return url + `${url.includes("?") ? "&" : "?"}errorType=${errorType}`;
      }
      return url;
    };

    const handleVerifiedUser = async (routePath: string) => {
      const shouldUpdateUser = getShouldUpdateUser(currentUserState);

      const userData = shouldUpdateUser
        ? await handleUpdateUser()
        : currentUserState.profile;

      if (
        isUserWithIncompleteProfileVisitingPrivateRoute({
          routePath,
          profile: userData,
        })
      ) {
        navigate(`/signin/profile/?redirect=${routePath}`);
      } else if (location.pathname === "/signin") {
        navigate(buildURLFromRedirectQuery(searchParams.get("redirect")) || "/");
      }
    };

    const handleAnonymousUser = (routePath: string, errorType?: string) => {
      // anonymous user is trying to access a private route
      if (isProtectedPage(routePath) && strategy !== "guest") {
        const url = getRedirectUrl(errorType);

        //  redirect to signin if it's not authenticated and not guest either
        navigate(url, {
          state: {
            from: location,
          },
        });
      }
    };

    const handleAuthCheck = async () => {
      const { verified, errorType } = await handleVerifyJWT();
      const routePath = getRoutePathname();

      if (verified) {
        await handleVerifiedUser(routePath);
      } else {
        handleAnonymousUser(routePath, errorType);
      }

      setCheckingCredentials(false);
    };

    handleAuthCheck();
  }, [location.pathname]);

  return checkingCredentials;
}
