import { IntlShape } from "react-intl";

import { SelectOption } from "#shared/types";
import { Country } from "#src/types/db";

export const buildCountryItems = (
  countries: Country[],
  intl: IntlShape
): SelectOption[] =>
  countries
    .map<{
      value: string;
      label: string;
    }>(({ code, countryId }) => ({
      value: countryId.toString(),
      label: intl.formatMessage({
        id: `demographics.countries.${code === "SJ" ? code + "-" + countryId : code}`,
        defaultMessage: "",
      }),
    }))
    .sort((a, b) => a.label.localeCompare(b.label));
