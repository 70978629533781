import React from "react";

import { Box, styled } from "@mui/material";

import colors from "#shared/config/theme/colors";
import Avatar from "#src/components/common/Avatar";
import useGodView from "#src/containers/GodView/useGodView";
import { selectUser } from "#src/features/user/userSlice";
import { IMAGE_TYPES } from "../../../../../../types/types";
import { useSelectorTyped } from "../../../../../../utils";

interface Props {
  size?: number;
}

export default function ProfileImage({ size = 34 }: Props) {
  const user = useSelectorTyped(selectUser);
  const { organisation: godViewOrganisation } = useGodView();

  if (godViewOrganisation) {
    return (
      <ProfileAvatarWrapper>
        <Avatar
          uri={godViewOrganisation.logo || ""}
          type={IMAGE_TYPES.ORGANISATION_LOGO}
          diameter={size / 16}
          className="profile"
        />

        <Avatar
          uri={user.profile.avatar || ""}
          type={IMAGE_TYPES.USER_AVATAR}
          diameter={size / 36}
          className="small-profile"
        />
      </ProfileAvatarWrapper>
    );
  }

  return (
    <ProfileAvatarWrapper>
      <Avatar
        uri={user.profile.avatar || ""}
        type={IMAGE_TYPES.USER_AVATAR}
        diameter={size / 16}
        className="profile"
      />
    </ProfileAvatarWrapper>
  );
}

const ProfileAvatarWrapper = styled(Box)`
  position: relative;
  cursor: pointer;

  .small-profile {
    position: absolute;
    border: 1px solid ${colors.base.white};
    z-index: 2;
    bottom: 5%;
    right: -10%;
  }
`;
