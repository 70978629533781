import React from "react";

import SelectCard from "#shared/components/ui/SelectCard";
import Tooltip from "#shared/components/ui/Tooltip";
import useTooltipEllipsis from "#shared/hooks/useTooltipEllipsis";
import Avatar from "#src/components/common/Avatar";
import TextEllipsis from "#src/components/common/Text/TextEllipsis";
import { ChannelListItem } from "#src/types/channel";
import { IMAGE_TYPES } from "#src/types/types";

interface Props {
  channel: ChannelListItem;
  disabled: boolean;
  onClick: (channel: ChannelListItem) => void;
}

export default function AIChatChannelListItem({ channel, disabled, onClick }: Props) {
  const { ref, hidden } = useTooltipEllipsis({ hidden: disabled });

  return (
    <Tooltip
      key={channel.channelRef}
      title={channel.name}
      hidden={hidden}
      placement="top"
    >
      <SelectCard
        disabled={disabled}
        onClick={() => onClick(channel)}
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          px: 4,
          py: 2,
          gap: 2,
        }}
      >
        <Avatar uri={channel.logo} type={IMAGE_TYPES.CHANNEL_LOGO} diameter={1.5} />
        <TextEllipsis variant="label2" ref={ref} fontFamily="Avenir-heavy">
          {channel.name}
        </TextEllipsis>
      </SelectCard>
    </Tooltip>
  );
}
