import * as React from "react";
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";

const IconRocketLaunch = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    className="shared-icon"
    ref={ref}
    {...props}
  >
    <path
      fill="currentColor"
      d="M9.732 11.916c.504.25.965.578 1.368.984.402.407.734.868.984 1.367 4.132-1.171 6.12-3.105 7.09-4.921.976-1.828 1.066-3.812.859-5.379-1.567-.207-3.551-.117-5.379.86-1.816.968-3.75 2.96-4.922 7.09m7.27 2.273V17.4c0 .992-.524 1.914-1.38 2.418l-3.456 2.05a.937.937 0 0 1-1.414-.809v-4.48a3.33 3.33 0 0 0-3.332-3.33H2.94a.94.94 0 0 1-.816-.474.93.93 0 0 1 .007-.94l2.051-3.458A2.81 2.81 0 0 1 6.6 6.998h3.21c3.196-5.148 8.16-5.331 11.094-4.792.453.082.808.437.89.89.54 2.934.356 7.898-4.792 11.093M3.108 21.994a1.063 1.063 0 0 1-1.102-1.102c.036-1.472.297-4.062 1.637-5.398a3.437 3.437 0 0 1 4.863 0 3.437 3.437 0 0 1 0 4.863c-1.336 1.34-3.926 1.602-5.398 1.637m1.96-2.52c.5-.027 1.22-.144 1.614-.535a1.146 1.146 0 0 0 0-1.617 1.146 1.146 0 0 0-1.617 0c-.395.395-.508 1.113-.535 1.613-.02.313.23.559.543.543zm9.746-11.85a1.562 1.562 0 1 1 3.125 0 1.562 1.562 0 0 1-3.125 0"
    />
  </svg>
);
const ForwardRef = forwardRef(IconRocketLaunch);
export { ForwardRef as IconRocketLaunch };
