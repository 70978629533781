import React from "react";

import { Box, styled, useTheme } from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";

import { LoadingButton, TextField } from "#shared/components/ui";
import colors from "#shared/config/theme/colors";
import { FormErrors } from "#shared/types/form";
import { toRem } from "#shared/utils";
import DefaultChannelImage from "#src/assets/svgs/default_channel.svg";
import ChannelUploadImage from "#src/components/pages/ChannelDetails/ChannelHeader/components/ChannelUploadImage";
import {
  ChannelBannerWrapper,
  ChannelLogoWrapper,
  ChannelContentWrapper,
  ChannelActions,
} from "#src/components/pages/ChannelDetails/ChannelHeader/styled";
import {
  ChannelHeaderFormField,
  ChannelHeaderFormFields,
} from "#src/components/pages/ChannelDetails/ChannelHeader/useChannelHeaderForm";
import { IMAGE_TYPES } from "#src/types/types";
import getResourcesURL from "#src/utils/getResourcesURL";

interface Props {
  data: ChannelHeaderFormFields;
  errors?: FormErrors<ChannelHeaderFormFields>;
  isLoading?: boolean;
  disableAutoFocus?: boolean;
  onChange: (field: ChannelHeaderFormField, value: string) => void;
  onConfirm?: () => void;
}

export default function ChannelHeaderEdit({
  data,
  errors,
  isLoading,
  disableAutoFocus,
  onChange,
  onConfirm,
}: Props) {
  const intl = useIntl();
  const theme = useTheme();

  const logo = getResourcesURL(data.logo, IMAGE_TYPES.CHANNEL_LOGO);
  const banner = getResourcesURL(data.banner, IMAGE_TYPES.CHANNEL_BANNER);

  return (
    <Wrapper>
      <ChannelBannerWrapper placeholder={!data.banner}>
        <ChannelUploadImage
          type="banner"
          rounding="none"
          value={banner}
          onChange={(value) => onChange(ChannelHeaderFormField.banner, value)}
        />
      </ChannelBannerWrapper>

      <ChannelLogoWrapper placeholder={!data.logo}>
        <ChannelUploadImage
          type="logo"
          value={logo || DefaultChannelImage}
          onChange={(value) => onChange(ChannelHeaderFormField.logo, value)}
        />
      </ChannelLogoWrapper>
      <ChannelContentWrapper>
        {onConfirm && (
          <ChannelActions>
            <LoadingButton
              variant="cta"
              color="primary"
              loading={!!isLoading}
              onClick={onConfirm}
            >
              <FormattedMessage id="ui.buttons.done" />
            </LoadingButton>
          </ChannelActions>
        )}
        <Box display="flex" flexDirection="column" gap={1}>
          <TextField
            variant="standard"
            value={data.name}
            onChange={(e) => onChange(ChannelHeaderFormField.name, e.target.value)}
            placeholder={intl.formatMessage({
              id: "ui.modals.create_channel.step_two.fields.name.placeholder",
            })}
            sx={{
              input: {
                fontSize: theme.typography.h4.fontSize,
                color: theme.palette.common.black,
                fontWeight: 520,
                "::placeholder": {
                  color: errors?.name ? colors.error.default : colors.base.black,
                },
              },
            }}
            maxLength={50}
            autoFocus={!disableAutoFocus}
            autoComplete="off"
          />
          <TextField
            variant="standard"
            value={data.description}
            onChange={(e) => onChange(ChannelHeaderFormField.description, e.target.value)}
            placeholder={intl.formatMessage({
              id: "ui.modals.create_channel.step_two.fields.description.placeholder",
            })}
            sx={{
              input: {
                fontSize: theme.typography.h4.fontSize,
                fontWeight: 520,
              },
            }}
            maxLength={300}
            multiline
          />
        </Box>
      </ChannelContentWrapper>
    </Wrapper>
  );
}

const Wrapper = styled(Box)`
  --channel-header-height: ${toRem(140)};
`;
