import { IconAiImage } from "#shared/icons/AiImage";
import { IconAiWriting } from "#shared/icons/AiWriting";
import { IconBlockQuote } from "#shared/icons/BlockQuote";
import { IconHeading1 } from "#shared/icons/Heading1";
import { IconHeading2 } from "#shared/icons/Heading2";
import { IconHorizontalRule } from "#shared/icons/HorizontalRule";
import { IconImage } from "#shared/icons/Image";
import { IconListBullet } from "#shared/icons/ListBullet";
import { IconListNumber } from "#shared/icons/ListNumber";
import { IconParagraph } from "#shared/icons/Paragraph";
import { IconTable } from "#shared/icons/Table";
import { EditorCommandGroup } from "#src/components/common/Editor/types";

export const EDITOR_COMMANDS: EditorCommandGroup[] = [
  {
    name: "ai",
    title: "sections.bulbshare_ai",
    commands: [
      {
        name: "magicWriter",
        label: "commands.magic_write",
        Icon: IconAiWriting,
        isAICommand: true,
        description: "Generate text with AI",
        shouldBeHidden: (editor) => editor.isActive("columns"),
        action: (editor) => editor.chain().focus().setMagicWriter().blur().run(),
      },
      {
        name: "magicImage",
        label: "commands.magic_image",
        Icon: IconAiImage,
        isAICommand: true,
        description: "Generate an image from text",
        shouldBeHidden: (editor) => editor.isActive("columns"),
        action: (editor) => editor.chain().focus().setMagicImage().blur().run(),
      },
    ],
  },
  {
    name: "format",
    title: "sections.format",
    commands: [
      {
        name: "heading1",
        label: "commands.heading1",
        description: "High priority section title",
        aliases: ["h1"],
        Icon: IconHeading1,
        action: (editor) => {
          editor.chain().focus().setHeading({ level: 1 }).run();
        },
      },
      {
        name: "heading2",
        label: "commands.heading2",
        description: "Medium priority section title",
        aliases: ["h2"],
        Icon: IconHeading2,
        action: (editor) => {
          editor.chain().focus().setHeading({ level: 2 }).run();
        },
      },
      {
        name: "paragraph",
        label: "commands.paragraph",
        description: "Standard text section",
        aliases: ["p"],
        Icon: IconParagraph,
        action: (editor) => {
          editor.chain().focus().setParagraph().run();
        },
      },
      {
        name: "bulletList",
        label: "commands.bullet_list",
        description: "Unordered list of items",
        aliases: ["ul"],
        Icon: IconListBullet,
        action: (editor) => {
          editor.chain().focus().toggleBulletList().run();
        },
      },
      {
        name: "numberedList",
        label: "commands.numbered_list",
        description: "Ordered list of items",
        aliases: ["ol"],
        Icon: IconListNumber,
        action: (editor) => {
          editor.chain().focus().toggleOrderedList().run();
        },
      },
      {
        name: "blockquote",
        label: "commands.blockquote",
        description: "Element for quoting",
        Icon: IconBlockQuote,
        action: (editor) => {
          editor.chain().focus().setBlockquote().run();
        },
      },
    ],
  },
  {
    name: "insert",
    title: "sections.insert",
    commands: [
      {
        name: "table",
        label: "commands.table",
        description: "Insert a table",
        Icon: IconTable,
        shouldBeHidden: (editor) => editor.isActive("columns"),
        action: (editor) => {
          editor
            .chain()
            .focus()
            .insertTable({ rows: 3, cols: 3, withHeaderRow: false })
            .run();
        },
      },
      {
        name: "image",
        label: "commands.image",
        description: "Insert an image",
        aliases: ["img"],
        Icon: IconImage,
        action: (editor) => {
          editor.chain().focus().setImageUpload().blur().run();
        },
      },
      {
        name: "horizontalRule",
        label: "commands.horizontal_rule",
        description: "Insert a horizontal divider",
        aliases: ["hr"],
        Icon: IconHorizontalRule,
        action: (editor) => {
          editor.chain().focus().setHorizontalRule().run();
        },
      },
    ],
  },
];

export default EDITOR_COMMANDS;
