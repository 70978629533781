import * as React from "react";
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";

const IconFacebookMessengerSolid = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    className="shared-icon"
    ref={ref}
    {...props}
  >
    <path
      fill="currentColor"
      d="M12.023 2C6.376 2 2 6.127 2 11.7c0 2.916 1.198 5.436 3.148 7.176.337.303.268.479.325 2.348a.804.804 0 0 0 1.124.709c2.134-.94 2.161-1.014 2.523-.916C15.3 22.72 22 18.763 22 11.701 22.001 6.127 17.67 2 12.023 2m6.018 7.465-2.944 4.66a1.505 1.505 0 0 1-2.174.401l-2.342-1.753a.605.605 0 0 0-.726 0l-3.16 2.397c-.422.32-.974-.185-.69-.632l2.944-4.66a1.506 1.506 0 0 1 2.174-.4l2.34 1.752a.605.605 0 0 0 .727 0l3.162-2.395c.42-.321.973.183.689.63"
    />
  </svg>
);
const ForwardRef = forwardRef(IconFacebookMessengerSolid);
export { ForwardRef as IconFacebookMessengerSolid };
