import { useMemo } from "react";

import { isMobile } from "react-device-detect";

import { getAppLinkBriefUrl } from "#src/components/pages/Download/applink";
import env from "#src/env";
import { selectJoinBriefId } from "#src/features/joinBriefId/joinBriefIdSlice";
import useWlaOrganisation from "#src/utils/useWlaOrganisation";
import { ComposedHookDownload } from "../../types/hooks";
import { useSelectorTyped } from "../../utils";
import useFetchQRToken from "./useFetchQRToken";

export enum DownloadQrErrors {
  none = 0,
  qrApi,
  smartScript,
}

export default function useDownload(): ComposedHookDownload {
  const joinBriefId = useSelectorTyped(selectJoinBriefId);

  const QRToken = useFetchQRToken({ briefRef: joinBriefId });

  const { organisation } = useWlaOrganisation();

  /* wait for token if token is not yet set but is being loaded */
  const shouldWaitForToken = QRToken.willFetch && !QRToken.isError && !QRToken.token;

  /* QR code link */
  const link = useMemo<string | null>(() => {
    if (!joinBriefId && !organisation) {
      return null;
    }
    if (shouldWaitForToken) {
      return null;
    }

    const link = getAppLinkBriefUrl({
      briefRef: joinBriefId,
      token: QRToken.token,
      oneLinkURL: organisation?.deepLink || env.VITE_QR_URL_BASE,
    });
    if (link && isMobile) {
      window.location.href = link;
    }

    return link;
  }, [joinBriefId, QRToken.token, organisation?.deepLink]);

  const redirectUrlStore = link ?? env.VITE_ONE_LINK_URL;
  const redirectUrlQRCode = link ?? "https://onelink.to/ajgmts";

  return {
    joinBriefId,
    isLoading: shouldWaitForToken,
    link,
    redirectUrlStore,
    redirectUrlQRCode,
  };
}
