import * as React from "react";
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";

const IconPlus = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    className="shared-icon"
    ref={ref}
    {...props}
  >
    <path
      fill="currentColor"
      d="M13.322 3.984A.97.97 0 0 0 12.357 3a.97.97 0 0 0-.964.984v6.563H4.964A.97.97 0 0 0 4 11.53c0 .546.43.985.964.985h6.429v6.562c0 .546.43.985.964.985a.97.97 0 0 0 .965-.985v-6.562h6.428a.97.97 0 0 0 .964-.985.97.97 0 0 0-.964-.984h-6.428z"
    />
  </svg>
);
const ForwardRef = forwardRef(IconPlus);
export { ForwardRef as IconPlus };
