import React, { useState } from "react";

import {
  FormHelperText,
  MenuItem,
  Select,
  SelectProps,
  SwipeableDrawer,
  Typography,
  styled,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import { FormField } from "../../../components/ui";
import DrawerHeader from "../../../components/ui/Drawer/DrawerHeader";
import FormControl from "../../../components/ui/FormControl";
import colors from "../../../config/theme/colors";
import { IconClose } from "../../../icons/Close";
import { IconPlus } from "../../../icons/Plus";
import { SelectOptionValue, SelectOption } from "../../../types/form";

export interface CustomSelectProps extends Omit<SelectProps, "onChange" | "label"> {
  title?: string;
  label?: string;
  optional?: boolean;
  items: SelectOption[];
  value: SelectOptionValue | SelectOptionValue[] | undefined;
  error?: boolean;
  desktopOnly?: boolean;
  errorMessage?: string;
  addLabel?: string;
  onChange: (value: SelectOptionValue | SelectOptionValue[]) => void;
  onClear?: () => void;
  onAdd?: () => void;
}

export default function CustomSelect(props: CustomSelectProps) {
  const { label, optional, ...rest } = props;

  if (label) {
    return (
      <FormField label={label} name={props.name || props.id} optional={optional}>
        <FormControlWrapper error={!!props.errorMessage}>
          <SelectElement {...rest} />
        </FormControlWrapper>
      </FormField>
    );
  }

  return (
    <FormControlWrapper error={!!props.errorMessage}>
      <SelectElement {...rest} />
    </FormControlWrapper>
  );
}

const SelectElement = ({
  title,
  items,
  value,
  error,
  desktopOnly,
  disabled,
  errorMessage,
  children,
  addLabel,
  onChange,
  onClear,
  onAdd,
  ...props
}: CustomSelectProps) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectOpen, setSelectOpen] = useState(false);

  const getArrowColor = () => {
    if (disabled) return colors.input.disabled;
    if (props.color === "primary") return "primary.main";
    return colors.text.primaryDark;
  };

  const handleSelectOpen = () => {
    setSelectOpen(true);
  };

  const handleSelectClose = () => {
    setSelectOpen(false);
  };

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const handleSelectClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (disabled) return;

    const target = e.target as HTMLElement;
    if (target.closest(".clear-button")) return;

    if (!desktopOnly && !isDesktop) {
      handleDrawerOpen();
    }
  };

  const handleMenuItemClick = (itemValue: SelectOptionValue) => {
    const isArray = Array.isArray(value);

    if (isArray) {
      if (value.includes(itemValue)) {
        onChange(value.filter((v) => v !== itemValue));
        return;
      }

      onChange([...value, itemValue]);
      return;
    }

    onChange(itemValue);
    handleDrawerClose();
  };

  const addMenuItem = () => {
    if (addLabel && onAdd) {
      return (
        <MenuItem
          onClick={() => {
            onAdd();
            handleDrawerClose();
          }}
        >
          <Typography
            variant="label2"
            color="primary.main"
            display="flex"
            alignItems="center"
            gap={1.5}
          >
            <IconPlus width={16} height={16} />
            {addLabel}
          </Typography>
        </MenuItem>
      );
    }
  };

  return (
    <>
      <Select
        {...props}
        value={value}
        onChange={(event) => onChange(event.target.value as number)}
        open={(desktopOnly || isDesktop) && selectOpen}
        disabled={disabled}
        onOpen={handleSelectOpen}
        onClose={handleSelectClose}
        onMouseDown={handleSelectClick}
        displayEmpty
        endAdornment={
          onClear && value ? (
            <RemoveButton
              className="clear-button"
              onClick={(e) => {
                e.stopPropagation();
                onClear();
              }}
            >
              <IconClose />
            </RemoveButton>
          ) : undefined
        }
        renderValue={(selected) => {
          if (props.renderValue) return props.renderValue(selected);

          const selectedItem = items.find((item) => item.value === selected);
          return (
            selectedItem?.label ?? (
              <Typography
                variant="label2"
                color={colors.base.outline1}
                component="span"
                lineHeight="1.2rem"
              >
                {props.placeholder}
              </Typography>
            )
          );
        }}
        MenuProps={{
          disableScrollLock: true,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "center",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "center",
          },
          sx: {
            zIndex: 1600,
            ".MuiPaper-root": {
              marginTop: "1rem",
              border: `1px solid ${colors.base.outline1}`,

              ".MuiMenu-list": {
                maxHeight: "400px",
                border: "none",
              },
            },
          },
        }}
        sx={{
          color: getArrowColor(),
          ".MuiSelect-icon": {
            transform: drawerOpen ? "rotate(180deg)" : "rotate(0deg)",
            color: getArrowColor(),
          },
        }}
      >
        {items.map((item) => (
          <MenuItem
            key={item.value}
            value={item.value}
            sx={{
              "&.Mui-focusVisible": {
                backgroundColor: "transparent",
                "&:hover": {
                  backgroundColor: "rgba(0, 0, 0, 0.04)",
                },
              },
            }}
          >
            <Label>{item.label}</Label>
          </MenuItem>
        ))}
        {addMenuItem()}
        {children}
      </Select>

      {!desktopOnly && !isDesktop && (
        <SwipeableDrawer
          anchor="bottom"
          open={drawerOpen}
          onClose={handleDrawerClose}
          onOpen={handleDrawerOpen}
          sx={{ zIndex: 1600 }}
          PaperProps={{
            sx: {
              borderRadius: "var(--drawer-border-radius)",
              maxWidth: "100%",
              width: "100%",
              margin: "auto",
            },
          }}
        >
          <Wrapper>
            <DrawerHeader title={title ?? "Select"} onClose={handleDrawerClose} />
            <ItemsWrapper>
              {items.map((item) => (
                <MenuItem
                  key={item.value}
                  selected={item.value === value}
                  onClick={() => handleMenuItemClick(item.value)}
                >
                  <Label>{item.label}</Label>
                </MenuItem>
              ))}
              {addMenuItem()}
              {children}
            </ItemsWrapper>
          </Wrapper>
        </SwipeableDrawer>
      )}
      {error && errorMessage && (
        <FormHelperText error={true}>{errorMessage}</FormHelperText>
      )}
    </>
  );
};

const Wrapper = styled("div")`
  .MuiMenuItem-root {
    padding: 0.5rem 1.75rem;
  }
`;

const ItemsWrapper = styled("div")`
  ${({ theme }) => theme.breakpoints.down("md")} {
    max-height: 70vh;
    overflow-y: auto;
  }
`;

const FormControlWrapper = styled(FormControl)`
  width: auto;
`;

const Label = styled("span")`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
`;

const RemoveButton = styled("button")`
  position: absolute;
  right: 2.165rem;
  z-index: 2;
  top: 1.05rem;

  svg {
    pointer-events: none;
    width: 1.25rem;
    height: 1.25rem;
  }
`;
